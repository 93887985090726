import * as React from 'react';
import { IntrinsicChildrenWithClassNameProps } from '@src/types';
import { classNames } from '@helpers/classNames';

export interface IconProps extends IntrinsicChildrenWithClassNameProps {
  size?: 'x-small' | 'small' | 'medium' | 'large';
}

export const Icon: React.FC<IconProps> = ({ children, className, size = 'small', ...rest }) => {
  return (
    <span
      className={classNames(
        'tw-block',
        className ? className : '',
        size === 'large'
          ? 'tw-w-16 tw-h-16'
          : size === 'medium'
          ? 'tw-w-10 tw-h-10'
          : size === 'x-small'
          ? 'tw-w-4 tw-h-4'
          : 'tw-w-6 tw-h-6'
      )}
      {...rest}
    >
      {children}
    </span>
  );
};
