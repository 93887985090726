type ClassInput = string | Record<string, boolean> | undefined;

/**
 * @param {Array<string|Record<string, boolean>|undefined>} classInput - (string | Record<string, boolean>)[]
 * @returns {string}
 */

export const classNames = (...classInput: ClassInput[]): string => {
  const classes: string[] = classInput.reduce((classnames: string[], classInput: ClassInput) => {
    if (classInput && typeof classInput === 'string') {
      return [...classnames, classInput.trim()];
    }

    if (typeof classInput === 'object' && classInput !== null) {
      return [...classnames, ...flatClassNamesObject(classInput)];
    }

    return classnames;
  }, []);

  return classes.join(' ');
};

/**
 * @param {Record<string, boolean>} classNamesObject - Record<string, boolean>
 * @returns {string[]}
 */

export const flatClassNamesObject = (classNamesObject: Record<string, boolean>): string[] => {
  const classes: string[] = Object.keys(classNamesObject);

  return classes.reduce((classnames: string[], classname: string) => {
    if (classname && classNamesObject[classname] && typeof classname === 'string') {
      return [...classnames, classname.trim()];
    }

    return classnames;
  }, []);
};
