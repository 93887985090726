import { useEffect } from 'react';
import { useQuery, QueryResult } from '@apollo/client';
import { ORGANIZATION_ACTION_TYPES, useOrganizationContext } from '@contexts/OrganizationContext';
import { GET_ORGANIZATIONS } from '@data/organizations';
import { GetOrganizations } from '@gql';

type UseGetOrganizations<TResultData> = Pick<
  QueryResult<TResultData>,
  'data' | 'loading' | 'error'
>;

export const useGetOrganizations = (): UseGetOrganizations<any> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useOrganizationContext();
  const { data, loading, error } = useQuery<GetOrganizations>(GET_ORGANIZATIONS);

  useEffect(() => {
    if (data?.organizations) {
      dispatch({
        type: ORGANIZATION_ACTION_TYPES.SET_MEMBER_ORGANIZATIONS,
        payload: {
          memberOrganizations: data.organizations
        }
      });
    }
  }, [data, dispatch]);

  return {
    data,
    loading,
    error
  };
};
