import React from 'react';
import { Card } from './Card';
import { Spinner } from './Spinner';
import { useTranslation } from 'react-i18next';

export const Loading: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="tw-container tw-m-auto tw-my-4" data-testid="loadingCard">
      <Card>
        <h1 className="tw-text-center tw-align-middle">
          <div className="tw-m-4 tw-text-nrs-blue">
            <Spinner />
          </div>

          <div className="tw-text-gray-500">{t('common.loading')}</div>
        </h1>
      </Card>
    </div>
  );
};
