import React, { memo } from 'react';
import NRSLogoPng from './assets/NRS-Logo.png';

export const NRSLogoStacked = memo(() => {
  return (
    <div>
      <img src={NRSLogoPng} alt="New River Strategies - Login" />
    </div>
  );
});
