import { useEffect, useState } from 'react';
import { fetchAuthSession } from '@aws-amplify/core';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { isAuthenticated } from '@helpers/auth';

type Session = {
  token: SessionToken;
};

type SessionToken = string | null;

export const getSessionToken = async (): Promise<Session | null> => {
  try {
    const session = await fetchAuthSession();
    if (session.tokens?.accessToken) {
      return {
        token: session.tokens.accessToken.toString()
      };
    } else {
      return null;
    }
  } catch (err) {
    console.error('[Auth Event]: No active session:', err);
    return null;
  }
};

export const useSessionToken = (): Session => {
  const authState = useAuthenticator(context => [context.user, context.route]);
  const [session, setSession] = useState<Session>({
    token: null
  });

  useEffect(() => {
    const getSignInSessionToken = async () => {
      if (isAuthenticated(authState.route)) {
        const session = await fetchAuthSession();

        const token = session.tokens?.accessToken.toString();
        if (token) {
          setSession({ token });
          return token;
        }
      } else {
        console.error('[Auth Event]: No active session');
      }
    };

    getSignInSessionToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.route]);

  return session;
};
