import { gql } from '@apollo/client';
import { ORGANIZATION_BASE_FIELDS } from '../OrganizationFragments';

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    organizations {
      ...OrganizationBaseFields
    }
  }

  ${ORGANIZATION_BASE_FIELDS}
`;
