import { gql } from '@apollo/client';
import { USER_NAME_FRAGMENT } from './UserFragments';

export const CREATOR_FRAGMENT = gql`
  fragment Creator on User {
    id
    ...UserNameFragment
  }

  ${USER_NAME_FRAGMENT}
`;
