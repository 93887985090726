import { gql } from '@apollo/client';
import { PEOPLE_LIST_USER_PREFERENCES } from '../PeopleListFragments';

export const GET_PEOPLE_LIST_USER_PREFERENCES = gql`
  query GetPeopleListUserPreferences($id: ID!) {
    peopleList(id: $id) {
      id
      nodeUserPreferences {
        ...PeopleListUserPreferences
      }
    }
  }

  ${PEOPLE_LIST_USER_PREFERENCES}
`;
