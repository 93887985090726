import { Modal } from '@components/Modal';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CloneListModalContent } from './CloneListModalContent';

export type CloneListModalProps = {
  isOpen: boolean;
  onClose: () => void;
  targetListName: string;
  peopleListId: string;
  shouldRedirectAfterClone?: boolean;
};

export const CloneListModal: React.FC<CloneListModalProps> = ({
  isOpen,
  onClose,
  targetListName,
  peopleListId,
  shouldRedirectAfterClone: shouldRedirect
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t('lists.components.cloneListModal.clone')} '${targetListName}'`}
    >
      <CloneListModalContent
        onClose={onClose}
        targetListName={targetListName}
        peopleListId={peopleListId}
        shouldRedirectAfterClone={shouldRedirect}
      />
    </Modal>
  );
};
