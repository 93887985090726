import { gql } from '@apollo/client';
import { DOWNLOAD_URL_PROGRESS, DOWNLOAD_URL_DETAILS } from '../PeopleListFragments';

export const GET_PEOPLE_LIST_DOWNLOAD_URL = gql`
  query GetPeopleListDownloadUrl($id: ID!) {
    peopleList(id: $id) {
      id
      downloadUrl {
        progress {
          ...DownloadUrlProgress
        }
        urlDetails {
          ...DownloadUrlDetails
        }
      }
    }
  }

  ${DOWNLOAD_URL_PROGRESS}
  ${DOWNLOAD_URL_DETAILS}
`;
