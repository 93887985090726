import React from 'react';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { ShareModal } from './ShareModal';
import { BluebellShareLevel, FolderDetails } from '@gql';

export type ShareObject = {
  id: string;
  name: string;
  orgShareLevel: BluebellShareLevel;
  shareRules?: FolderDetails['shareRules'] | null;
};

type ShareProps = {
  shareObject: ShareObject;
  refetch: () => void;
};

export const ShareButton: React.FC<ShareProps> = ({ shareObject, refetch }) => {
  const [isOpen, setModalOpen] = React.useState(false);

  return (
    <div>
      <div
        className="tw-cursor-pointer tw-text-nrs-blue hover:tw-text-nrs-blue-dark tw-mb-[0.1rem]"
        onClick={() => setModalOpen(true)}
        data-testid="share:shareButton"
      >
        <ArrowUpOnSquareIcon className="tw-w-6 tw-h-6" />
      </div>

      {isOpen && (
        <ShareModal
          isOpen={isOpen}
          shareObject={shareObject}
          refetch={refetch}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  );
};
