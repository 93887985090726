import { gql } from '@apollo/client';
import { PAGE_INFO_FRAGMENT } from '../../fragments/PageInfoFragment';
import { CREATOR_FRAGMENT } from '@data/users/CreatorFragment';
import { FOLDER_DETAILS } from '../FolderFragments';

export const GET_FOLDERS = gql`
  query GetFolders($after: String, $before: String, $first: Int, $last: Int) {
    folders(after: $after, before: $before, first: $first, last: $last) {
      nodes {
        ...FolderDetails
      }

      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${PAGE_INFO_FRAGMENT}
  ${CREATOR_FRAGMENT}
  ${FOLDER_DETAILS}
`;
