import React, { ButtonHTMLAttributes } from 'react';
import { IntrinsicChildrenProps } from '@src/types';
import { classNames } from '@helpers/classNames';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  className?: string;
  kind?: ButtonKind;
}

export enum ButtonSize {
  'xs' = 'xs',
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg'
}

export enum ButtonKind {
  'info' = 'info',
  'warn' = 'warn',
  'error' = 'error',
  'success' = 'success',
  'default' = 'default',
  'link' = 'link'
}

const kindClasses = (kind?: ButtonKind) => {
  switch (kind) {
    case ButtonKind.info:
      return 'tw-bg-gray-100 tw-text-nrs-blue tw-border-gray-300 hover:tw-bg-gray-200';
    case ButtonKind.warn:
      return 'tw-bg-yellow-500 tw-text-white hover:tw-bg-yellow-600';
    case ButtonKind.error:
      return 'tw-bg-red-500 tw-text-white hover:tw-bg-red-700';
    case ButtonKind.success:
      return 'tw-bg-nrs-green tw-text-white hover:tw-bg-nrs-green-dark';
    case ButtonKind.link:
      return 'tw-bg-white tw-text-nrs-blue tw-rounded-sm hover:tw-bg-nrs-blue-light-alt hover:tw-text-nrs-blue-dark';
    default:
      return 'tw-bg-nrs-blue tw-text-white hover:tw-bg-nrs-blue-dark';
  }
};

const sizeClasses = (size: ButtonSize) => {
  switch (size) {
    case ButtonSize.sm:
      return 'tw-p-2';
    case ButtonSize.lg:
      return 'tw-px-10 tw-py-8';
    case ButtonSize.xs:
      return 'tw-px-2 tw-text-xs tw-h-5';
    default:
      return 'tw-px-6 tw-py-2';
  }
};

export const Button: React.FC<ButtonProps & IntrinsicChildrenProps> = ({
  children,
  className,
  size = ButtonSize.md,
  kind = ButtonKind.default,
  type,
  disabled,
  ...props
}) => {
  const classes = classNames(
    'tw-rounded-lg tw-duration-300 tw-transition-colors focus:tw-outline-nrs-indigo tw-h-10',
    className,
    disabled ? 'tw-bg-gray-400 tw-text-white tw-cursor-not-allowed' : kindClasses(kind),
    sizeClasses(size)
  );

  return (
    <button className={classes} type={type ?? 'button'} disabled={disabled} {...props}>
      {children}
    </button>
  );
};
