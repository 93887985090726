import React from 'react';
import { classNames } from '@helpers/classNames';
import { IntrinsicChildrenProps } from '@src/types';

interface Title3Props extends IntrinsicChildrenProps {
  className?: string;
}

export const Title3: React.FC<Title3Props> = ({ children, className }) => (
  <h3 className={classNames('tw-text-xl tw-text-nrs-blue-dark tw-mb-4', className ?? '')}>
    {children}
  </h3>
);
