import React from 'react';
import { PeopleListState } from '@gql';
import { Badge, BadgeKinds } from '@components/Badge';

type ListStatusBadgeProps = {
  status: keyof typeof PeopleListState;
};

export const ListStatusBadge: React.FC<ListStatusBadgeProps> = ({ status }) => {
  const badgeKindMap: { [index: string]: BadgeKinds } = {
    ready: BadgeKinds.success,
    pending: BadgeKinds.warning,
    archived: BadgeKinds.error,
    processing: BadgeKinds.info
  };

  const getBadgeKind = (status: string): BadgeKinds => {
    return badgeKindMap[status.toLowerCase()];
  };

  return <Badge kind={getBadgeKind(status)}>{status}</Badge>;
};
