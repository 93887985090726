import { gql } from '@apollo/client';

export const GET_PEOPLE_LIST_STATUS = gql`
  query GetPeopleListStatus($id: ID!) {
    peopleList(id: $id) {
      id
      state
    }
  }
`;
