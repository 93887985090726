import * as React from 'react';
import { classNames } from '@helpers/classNames';
import { IntrinsicChildrenProps } from '@src/types';

interface IconButtonProps extends IntrinsicChildrenProps {
  onClick: () => void;
  className?: string;
  'data-testid'?: string;
}

export const IconButton: React.FC<IconButtonProps> = ({
  children,
  onClick,
  className,
  ...props
}) => {
  return (
    <button
      data-testid={props['data-testid'] ?? 'iconButton'}
      type="button"
      onClick={onClick}
      className={classNames(
        `tw-cursor-pointer tw-w-12 tw-h-12 tw-text-nrs-blue tw-rounded-full tw-p-3 hover:tw-bg-slate-100 hover:tw-shadow-md tw-transition-all hover:tw-text-nrs-blue-dark`,
        className ? className : ''
      )}
    >
      {children}
    </button>
  );
};
