import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';

export const DELETE_FAVORITE = gql`
  mutation DeleteFavorite($input: DeleteFavoriteInput!) {
    deleteFavorite(input: $input) {
      errors {
        ...ErrorFragment
      }
      success
    }
  }

  ${ERROR_FRAGMENT}
`;
