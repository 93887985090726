import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import './styles/app.css';
import '@fontsource-variable/noto-sans';
import '@fontsource-variable/noto-sans/wght-italic.css';
import '@utils/i18n';

// const container = document.getElementById('app');
// // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
// const root = ReactDOM.createRoot(container!); // createRoot(container!) if you use TypeScript
// root.render(<App />);

const rootElement = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootElement!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
