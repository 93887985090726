import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@components/TextField';
import { CancelLink } from '@components/CancelLink';
import { Button } from '@components/Button';
import { Alert } from '@components/Alert';
import { useMutation } from '@apollo/client';
import { CLONE_LIST } from '@data/lists/mutations/CloneList';
import { ClonePeopleList, ClonePeopleListVariables } from '@gql';
import { useNavigate } from 'react-router';
import { ROOT } from '@config/routes';
import { CloneListModalProps } from './CloneListModal';
import { GET_PEOPLE_LISTS } from '@data/lists';

const { useState } = React;

export const CloneListModalContent: React.FC<Omit<CloneListModalProps, 'isOpen'>> = ({
  onClose,
  targetListName,
  peopleListId,
  shouldRedirectAfterClone
}) => {
  const { t } = useTranslation();
  const [newListName, setNewListName] = useState(`${targetListName} COPY`);
  const [fieldError, setFieldError] = useState<string | null>(null);
  const navigate = useNavigate();

  const [clonePeopleList, { loading }] = useMutation<ClonePeopleList, ClonePeopleListVariables>(
    CLONE_LIST
  );

  const handleChange = (value: string) => {
    const error =
      value.trim() === targetListName.trim()
        ? t('lists.components.cloneListModal.nameError')
        : null;
    setNewListName(value);

    setFieldError(error);
  };

  const onSubmit = (name: string) => {
    clonePeopleList({
      variables: {
        input: {
          name,
          peopleListId
        }
      },
      onCompleted: data => {
        if (data.clonePeopleList?.errors.length) {
          const errorMessage = data.clonePeopleList.errors[0].message;
          setFieldError(errorMessage);
          return;
        }

        if (shouldRedirectAfterClone && data.clonePeopleList?.peopleList?.id) {
          navigate(`${ROOT.LISTS.BASE}/${data.clonePeopleList?.peopleList?.id}`);
        }
        onClose();
      },
      onError: err => {
        setFieldError(t('lists.components.cloneListModal.unexpectedError'));
      },
      refetchQueries: [{ query: GET_PEOPLE_LISTS }]
    });
  };

  return (
    <>
      <p>{t('lists.components.cloneListModal.listName')}</p>
      <TextField
        data-testid="cloneListModal:input"
        value={newListName}
        onChange={e => handleChange(e.target.value)}
        className="tw-w-full tw-mt-3"
      />
      {!!fieldError && (
        <div className="tw-py-4" data-testid="cloneListModal:error">
          <Alert className="tw-my-2" type="error">
            <span data-testid="cloneListModal:errorText">{t(fieldError)}</span>
          </Alert>
        </div>
      )}
      <div className="tw-flex tw-justify-between tw-mt-6">
        <CancelLink onCancel={() => onClose()} data-testid="cloneListModal:cancel" />
        <Button
          className="tw-ml-2"
          onClick={() => onSubmit(newListName)}
          disabled={!!fieldError || loading}
          data-testid="cloneListModal:save"
        >
          {t('common.save')}
        </Button>
      </div>
    </>
  );
};
