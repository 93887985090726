import React, { useCallback } from 'react';
import { classNames } from '@helpers/classNames';
import { IntrinsicChildrenProps } from '@src/types';

export enum BadgeKinds {
  error = 'error',
  success = 'success',
  warning = 'warning',
  info = 'info',
  disabled = 'disabled',
  custom = 'custom'
}

interface BadgeProps extends IntrinsicChildrenProps {
  kind?: BadgeKinds;
  className?: string;
  onClick?: (...args: any) => void;
}

export const Badge: React.FC<BadgeProps> = ({
  kind = 'info',
  onClick,
  children,
  className,
  ...rest
}) => {
  const getKindColors = useCallback(() => {
    switch (kind) {
      case BadgeKinds.error:
        return `tw-bg-red-500`;
      case BadgeKinds.warning:
        return `tw-bg-yellow-500`;
      case BadgeKinds.success:
        return `tw-bg-green-500`;
      case BadgeKinds.disabled:
        return `tw-bg-gray-400`;
      case BadgeKinds.custom:
        return '';
      default:
        return `tw-bg-nrs-blue`;
    }
  }, [kind]);

  const backgroundColor = getKindColors();

  return (
    <div
      className={classNames(
        backgroundColor,
        className,
        `tw-py-1 tw-px-2 tw-rounded-l-full tw-rounded-r-full tw-text-white tw-text-center tw-text-xs tw-uppercase`
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  );
};
