// import { GetMyDetailsWithPrivileges_me } from '@data/me';
import { BluebellPrivilege, GetMyDetailsWithPrivileges } from '@gql';

export enum CURRENT_USER_ACTION_TYPES {
  'SET_CURRENT_USER',
  'CLEAR_CURRENT_USER',
  'SET_CURRENT_USER_LOADING',
  'SET_CURRENT_USER_HAS_FETCHED',
  'SET_CURRENT_USER_ERROR'
}

type CurrentUserActionPayloadUser = Pick<
  GetMyDetailsWithPrivileges['me'],
  'id' | 'firstName' | 'lastName' | 'superadmin' | 'userPrivileges' | 'email'
>;
export interface CurrentUserActionPayload {
  user?: CurrentUserActionPayloadUser;
  loading?: boolean;
  hasFetched?: boolean;
  error?: any;
}

export interface CurrentUserReducerAction {
  type: CURRENT_USER_ACTION_TYPES;
  payload: CurrentUserActionPayload;
}

export interface CurrentUserMeta {
  loading: boolean;
  hasFetched: boolean;
  error: Error | null;
}

export interface CurrentUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface CurrentUserReducerState {
  currentUser: CurrentUser | null;
  meta: CurrentUserMeta;
  userPrivileges: BluebellPrivilege[] | null;
  isSuperAdmin?: boolean;
}
