import { useCallback } from 'react';
import { useCurrentUserContext } from '@contexts/CurrentUser';
import { BluebellPrivilege } from '@gql';
import { SUPER_ADMIN } from '@config/privileges';

type UsePrivilegeMatch = {
  hasPermission: boolean;
  isLoading: boolean;
};

export const usePrivilegeMatch = (
  requiredPrivileges: Array<BluebellPrivilege | 'SUPER_ADMIN'>
): UsePrivilegeMatch => {
  const { isSuperAdmin, getUserPrivileges, meta } = useCurrentUserContext();

  const hasPermission = useCallback(() => {
    if (isSuperAdmin() || !requiredPrivileges.length) return true;

    if (requiredPrivileges.includes(SUPER_ADMIN) && !isSuperAdmin()) return false;

    const privileges = getUserPrivileges();
    if (!privileges?.length) return false;

    let matchingPermissions = 0;
    requiredPrivileges.forEach((privilege: BluebellPrivilege | 'SUPER_ADMIN') => {
      if (privilege !== SUPER_ADMIN) {
        if (privileges?.includes(privilege)) {
          matchingPermissions++;
        }
      }
    });

    return matchingPermissions > 0;
  }, [requiredPrivileges, isSuperAdmin, getUserPrivileges]);

  return {
    hasPermission: hasPermission(),
    isLoading: !!meta.loading
  };
};
