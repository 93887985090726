import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BreadCrumbDivider } from '@components/icons';

type FolderBreadcrumbsProps = {
  path: string;
};

export const FolderBreadcrumbs: React.FC<FolderBreadcrumbsProps> = ({ path }) => {
  const { t } = useTranslation();
  const pathList = path.split('/');

  return (
    <div className="tw-flex tw-p-4">
      <ol className="tw-flex tw-items-center tw-space-x-4">
        <li>
          <Link to={`/folders`}>
            <h1
              className="tw-cursor-pointer tw-text-nrs-blue hover:tw-text-nrs-blue-dark"
              data-testid="folderBreadcrumbs:home"
            >
              {t('folders.pages.route.breadCrumbHome')}
            </h1>
          </Link>
        </li>
        {pathList.map((path: string, idx: number) => {
          const navPathSlice = pathList.slice(0, idx + 1).join('/');
          const navPath = navPathSlice ? '/' + navPathSlice : '';

          return (
            <li key={path} data-testid="folderBreadcrumbs:link">
              <div className="tw-flex tw-items-center">
                <BreadCrumbDivider />
                <Link to={`/folders${navPath}`}>
                  <h1 className="tw-ml-4 tw-cursor-pointer tw-text-nrs-blue hover:tw-text-nrs-blue-dark">
                    {path}
                  </h1>
                </Link>
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};
