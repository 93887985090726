import React, { useState, createContext, useEffect, useCallback, useRef } from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { Transition } from '@headlessui/react';
import { IntrinsicChildrenProps } from '@src/types';
import { classNames } from '@helpers/classNames';

export type SnackbarType = 'info' | 'warning' | 'success' | 'error';
export interface OpenSnackbarArgs {
  text: React.ReactNode;
  type?: SnackbarType;
  showClose?: boolean;
  disableTimeout?: boolean;
  timeout?: number;
}

interface ISnackbarContext {
  openSnackbar: (args: OpenSnackbarArgs) => void;
  closeSnackbar: () => void;
}

const CONTAINER_CLASS_NAMES: { [index: string]: string } = {
  info: 'tw-bg-nrs-blue tw-text-white',
  warning: 'tw-bg-yellow-600 tw-text-white',
  success: 'tw-bg-green-500 tw-text-white',
  error: 'tw-bg-red-600 tw-text-white'
};
export const TIMEOUT_DURATION = 2500;

export const SnackbarContext = createContext<ISnackbarContext | undefined>(undefined);
export const Snackbar: React.FC<IntrinsicChildrenProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState<React.ReactNode>('');
  const [bgType, setBgType] = useState<SnackbarType>('info');
  const [showCloseIcon, setShowCloseIcon] = useState(true);
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const closeSnackbar = useCallback(() => {
    setIsOpen(false);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, []);

  const openSnackbar = useCallback(
    ({
      text,
      type,
      showClose,
      disableTimeout = false,
      timeout = TIMEOUT_DURATION
    }: OpenSnackbarArgs) => {
      setIsOpen(true);
      setText(text);
      if (type && type !== bgType) {
        setBgType(type);
      }

      if (typeof showClose === 'boolean' && showClose !== showCloseIcon) {
        setShowCloseIcon(showClose);
      }

      if (!disableTimeout) {
        timeoutId.current = setTimeout(() => {
          closeSnackbar();
        }, timeout);
      }
    },
    [bgType, showCloseIcon, closeSnackbar]
  );

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      <Transition as="div" show={isOpen} data-testid="snackbar:container">
        <div
          data-testid="snackbar:body"
          className={classNames(
            CONTAINER_CLASS_NAMES[bgType],
            'data-[closed]:tw-opacity-0',
            'tw-transition tw-ease-in-out',
            'data-[enter]:tw-duration-100 data-[enter]:data-[closed]:tw-opacity-100',
            'data-[leave]:tw-duration-300 data-[leave]:data-[closed]:tw-opacity-0',
            'tw-rounded tw-shadow-lg tw-px-8 tw-py-4 tw-w-80 tw-fixed tw-bottom-4 tw-z-50'
          )}
        >
          <div className="tw-flex tw-items-center tw-justify-between">
            <div>{text}</div>
            {showCloseIcon && (
              <div className="tw-ml-2">
                <button type="button" onClick={closeSnackbar} data-testid="snackbar:close">
                  <XCircleIcon className="tw-block tw-w-6 tw-h-6" />
                </button>
              </div>
            )}
          </div>
        </div>
      </Transition>
    </SnackbarContext.Provider>
  );
};
