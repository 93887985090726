import * as React from 'react';
import { useMutation, useQuery } from '@apollo/client/react';
import { Alert } from '@components/Alert';
import { Title3 } from '@components/Title3';
import { ACCEPT_ORG_INVITE } from '@data/invites/mutations/acceptOrgInvite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROOT } from '@config/routes';
import { Button, ButtonKind } from '@components/Button';
import { DECLINE_ORG_INVITE } from '@data/invites/mutations/declineOrgInvite';
import { GET_ORG_INVITE_BY_ID } from '@data/invites';
import {
  GetOrgInviteById,
  GetOrgInviteByIdVariables,
  DeclineOrgInvite,
  DeclineOrgInviteVariables,
  AcceptOrgInvite,
  AcceptOrgInviteVariables
} from '@gql';
import { ORGANIZATION_ACTION_TYPES, useOrganizationContext } from '@contexts/OrganizationContext';

type InviteAcceptProps = {
  inviteId: string;
};

const { useEffect, useState, useCallback } = React;

export const Invite: React.FC<InviteAcceptProps> = React.memo(({ inviteId }) => {
  const { t } = useTranslation();
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useOrganizationContext();
  const [acceptInvite, acceptInviteResult] = useMutation<AcceptOrgInvite, AcceptOrgInviteVariables>(
    ACCEPT_ORG_INVITE
  );

  const [declineInvite, declineInviteResult] = useMutation<
    DeclineOrgInvite,
    DeclineOrgInviteVariables
  >(DECLINE_ORG_INVITE);

  const { data } = useQuery<GetOrgInviteById, GetOrgInviteByIdVariables>(GET_ORG_INVITE_BY_ID, {
    variables: {
      id: inviteId
    }
  });

  const handleAcceptInvite = useCallback(
    (inviteId: string) => {
      acceptInvite({
        variables: {
          input: {
            organizationInviteId: inviteId
          }
        }
      });
    },
    [acceptInvite]
  );

  const handleDeclineInvite = useCallback(
    (inviteId: string) => {
      declineInvite({
        variables: {
          input: {
            organizationInviteId: inviteId
          }
        }
      });
    },
    [declineInvite]
  );

  useEffect(() => {
    if (acceptInviteResult.data?.acceptOrganizationInvite?.success) {
      if (data?.organizationInvite.organization) {
        dispatch({
          type: ORGANIZATION_ACTION_TYPES.SET_ORGANIZATION,
          payload: {
            organization: {
              id: data?.organizationInvite.organization.id,
              name: data?.organizationInvite.organization.name
            }
          }
        });
      }
      navigate(ROOT.LISTS.BASE);
    }
  }, [acceptInviteResult, data?.organizationInvite.organization, dispatch, navigate]);

  useEffect(() => {
    if (declineInviteResult.data?.declineOrganizationInvite?.success) {
      navigate(ROOT.LISTS.BASE);
    }
  }, [declineInviteResult, navigate]);

  useEffect(() => {
    if (acceptInviteResult.error) {
      setAlertMessage(acceptInviteResult.error.message);
    }

    if (declineInviteResult.error) {
      setAlertMessage(declineInviteResult.error.message);
    }

    if (acceptInviteResult.data?.acceptOrganizationInvite?.errors) {
      setAlertMessage(
        acceptInviteResult.data.acceptOrganizationInvite.errors.map(err => err.message).join('\n')
      );
    }

    if (declineInviteResult.data?.declineOrganizationInvite?.errors) {
      setAlertMessage(
        declineInviteResult.data.declineOrganizationInvite.errors.map(err => err.message).join('\n')
      );
    }
  }, [
    acceptInviteResult.error,
    acceptInviteResult.data,
    declineInviteResult.error,
    declineInviteResult.data
  ]);

  if (alertMessage !== null) {
    return (
      <Alert className="tw-mb-4" type="error">
        {alertMessage}
      </Alert>
    );
  }

  return (
    <div>
      <Title3>
        {t('invite.acceptInviteTo', {
          organiztionName: data?.organizationInvite.organization.name
        })}
      </Title3>
      <div className="tw-flex tw-gap-4">
        <Button kind={ButtonKind.success} onClick={() => handleAcceptInvite(inviteId)}>
          {t('organization.pages.select.acceptInviteBtn')}
        </Button>
        <Button kind={ButtonKind.error} onClick={() => handleDeclineInvite(inviteId)}>
          {t('organization.pages.select.declineInviteBtn')}
        </Button>
      </div>
    </div>
  );
});
