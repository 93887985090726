import React from 'react';

export const NRSLogo: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 158.31 79.45">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <polygon
          style={{ fill: '#006837' }}
          points="5.04 46.79 57.02 0 66.38 10.4 73.66 9.36 101.41 46.26 5.04 46.79"
        />
        <polygon
          style={{ fill: '#009245' }}
          points="5.04 46.79 57.02 0 66.38 10.4 73.66 9.36 95.49 38.24 62.74 23.39 48.19 29.63 50.27 16.12 5.04 46.79"
        />
        <path
          style={{ fill: '#66aad7' }}
          d="M53.06,41.89c-2.72.34-6.82.53-9.06,1.8a1.82,1.82,0,0,0-1.06,1.4c0,.74.77,1.38,1.62,1.82a27.8,27.8,0,0,0,5.81,1.85,1.26,1.26,0,0,1,.49.19c.29.21.19.59-.11.8-1.6,1.17-5.15,1.78-7.31,2.19-2.75.53-5.58.83-8.4,1.1-5.55.52-11.26.87-16.44,2.49-1.31.41-3.35.91-3.21,2.15.12,1,1.7,1.81,2.93,2.21A22.53,22.53,0,0,1,22,61.17c.62.35,1.17.86,1.1,1.42-.1.84-1.41,1.28-2.55,1.53C13.85,65.61,6.9,66.62,0,67.62L20.49,79.45c7-2.07,14-4.29,20.72-6.81,4-1.52,9.7-4.09,9.56-7.6-.11-2.76-4.2-3.83-7.24-4.66-.48-.13-1-.32-1.12-.68-.13-.55.85-.85,1.62-1,5.46-.73,10.78-1.79,16.08-3,5-1.11,10.12-2.06,14.71-3.88.73-.29,1.52-.7,1.55-1.29s-.39-.83-.86-1.11c-1.94-1.15-4.76-1.05-7.1-1.72a2.43,2.43,0,0,1-1.15-.6,1,1,0,0,1,.33-1.39,9.84,9.84,0,0,1,2.73-1.55c3.39-1.18,5-1.72,8.18-2.62,1.37-.39,3-1.5,4.35-1.94-6.55.53-13.14.79-19.7,1.27C59.77,41.18,56.4,41.47,53.06,41.89Z"
        />
        <polygon
          style={{ fill: '#3e8550' }}
          points="96.41 18.58 113.04 5.6 158.31 46.79 78.56 46.01 66.94 46.37 96.41 18.58"
        />
        <polygon
          style={{ fill: '#33a86a' }}
          points="94.39 20.31 113.11 5.76 157.3 45.87 121.94 25.25 118.31 16.16 108.95 27.59 105.83 17.71 94.39 20.31"
        />
      </g>
    </g>
  </svg>
);
