import { gql } from '@apollo/client';
import { FOLDER_DETAILS } from '../FolderFragments';

export const ASSIGN_TO_FOLDER = gql`
  mutation AssignToFolder($input: AssignNodeToFolderInput!) {
    assignNodeToFolder(input: $input) {
      folder {
        ...FolderDetails
      }
    }
  }

  ${FOLDER_DETAILS}
`;
