import { useLazyQuery } from '@apollo/client';
import { GET_FOLDERS, GET_FOLDER } from '@data/folders';

import { GetFolders, GetFoldersVariables, GetFolder, GetFolderVariables } from '@gql';

export const useGetFolders = (isRoot: boolean, path: string) => {
  const [getFolders, { data, loading, error, refetch }] = useLazyQuery<
    GetFolders,
    GetFoldersVariables
  >(GET_FOLDERS, {
    fetchPolicy: 'cache-and-network'
  });

  const useFolders = {
    query: getFolders,
    folderId: undefined,
    folderData: null,
    foldersData: data?.folders.nodes || [],
    loading,
    error,
    refetch
  };

  const [
    getFolder,
    { data: folderData, loading: folderLoading, error: folderError, refetch: folderRefetch }
  ] = useLazyQuery<GetFolder, GetFolderVariables>(GET_FOLDER, {
    variables: {
      name: path
    },
    fetchPolicy: 'cache-and-network'
  });

  const useFolder = {
    query: getFolder,
    folderId: folderData?.folder.id,
    folderData: folderData?.folder.contents || [],
    foldersData: null,
    loading: folderLoading,
    error: folderError,
    refetch: folderRefetch
  };

  return isRoot ? useFolders : useFolder;
};
