import { gql } from '@apollo/client';

export const GET_PENDING_ORG_INVITES = gql`
  query GetPendingOrgInvites($id: ID!) {
    organization(id: $id) {
      id
      organizationInvites {
        id
        creator {
          id
          firstName
          lastName
          email
        }
        role {
          id
          name
        }
        email
        state
      }
    }
  }
`;
