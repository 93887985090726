import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';

export const DELETE_ORGANIZATION_INVITE = gql`
  mutation DeleteOrganizationInvite($input: DeleteOrganizationInviteInput!) {
    deleteOrganizationInvite(input: $input) {
      errors {
        ...ErrorFragment
      }
      success
    }
  }

  ${ERROR_FRAGMENT}
`;
