import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { ContextMenu } from './ContextMenu';
import { BellIcon } from './icons';
import { NotificationsMenuItem } from './NotificationsMenuItem';
import { useOrganizationContext, ORGANIZATION_ACTION_TYPES } from '@contexts/OrganizationContext';
import { ACCEPT_ORG_INVITE, DECLINE_ORG_INVITE } from '@data/invites';

import {
  AcceptOrgInvite,
  AcceptOrgInviteVariables,
  DeclineOrgInvite,
  DeclineOrgInviteVariables
} from '@gql';

export const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const [showIndicator, setShowIndicator] = useState(false);
  const [state, dispatch] = useOrganizationContext();
  const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState(false);
  const [acceptInvite] = useMutation<AcceptOrgInvite, AcceptOrgInviteVariables>(ACCEPT_ORG_INVITE, {
    refetchQueries: ['GetMyDetails']
  });
  const [declineInvite] = useMutation<DeclineOrgInvite, DeclineOrgInviteVariables>(
    DECLINE_ORG_INVITE,
    {
      refetchQueries: ['GetMyDetails']
    }
  );

  const updateInviteList = useCallback(
    (id: string) => {
      const nextInvites = state.invites.filter(invite => invite.id !== id);
      dispatch({
        type: ORGANIZATION_ACTION_TYPES.SET_ORGANIZATION_INVITES,
        payload: {
          invites: nextInvites
        }
      });
    },
    [dispatch, state.invites]
  );

  const handleAcceptInvite = async (id: string) => {
    try {
      await acceptInvite({
        variables: {
          input: {
            organizationInviteId: id
          }
        }
      });

      updateInviteList(id);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeclineInvite = async (id: string) => {
    try {
      await declineInvite({
        variables: {
          input: {
            organizationInviteId: id
          }
        }
      });

      updateInviteList(id);
    } catch (err) {
      console.error(err);
    }
  };

  const notificationsMenuOptions = state.invites.map(invite => ({
    label: (
      <NotificationsMenuItem
        onAccept={() => handleAcceptInvite(invite.id)}
        onDecline={() => handleDeclineInvite(invite.id)}
        label={invite.organization.name || ''}
      />
    ),
    id: invite.id
  }));

  useEffect(() => {
    if (state.invites.length) {
      setShowIndicator(true);
    } else if (showIndicator) {
      setShowIndicator(false);
    }
  }, [showIndicator, state.invites]);

  return (
    <ContextMenu
      options={notificationsMenuOptions}
      open={isNotificationsMenuOpen}
      onClose={() => setIsNotificationsMenuOpen(false)}
      title={t('organization.pages.select.pendingInvitesContextMenuTitle')}
      noOptionsTitle={t('header.subNav.noNotifications')}
    >
      <button
        data-testid="notifications:button"
        className="tw-text-nrs-blue tw-px-3 tw-py-4 tw-outline-none focus:tw-outline-none hover:tw-text-nrs-blue-dark"
        title={'header.subNav.notifications'}
        onClick={() => setIsNotificationsMenuOpen(prevState => !prevState)}
      >
        <div className="tw-relative">
          {showIndicator && (
            <div
              className="tw-absolute tw-bg-red-500 tw-w-2 tw-h-2 tw-rounded-full tw-shadow tw-top-0 tw-right-1"
              data-testid="notifications:indicator"
            />
          )}
          <BellIcon />
        </div>
      </button>
    </ContextMenu>
  );
};
