import React, { memo, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IntrinsicChildrenProps } from '@src/types';
import { classNames } from '@helpers/classNames';
interface NavLinkProps extends IntrinsicChildrenProps {
  to: string;
}

export const NavLink: React.FC<NavLinkProps> = memo(({ children, to }) => {
  const location = useLocation();
  const isActive = useMemo(() => {
    const urlRegex = new RegExp(to);

    return urlRegex.test(location.pathname);
  }, [location.pathname, to]);

  return (
    <li>
      <Link
        className={classNames(
          'tw-px-3 tw-py-4 tw-text-nrs-blue hover:tw-text-nrs-blue-dark tw-font-semibold',
          isActive ? ' tw-text-nrs-blue-dark tw-font-semibold' : ''
        )}
        to={to}
      >
        {children}
      </Link>
    </li>
  );
});
