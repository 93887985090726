import { gql } from '@apollo/client';
import { USER_NAME_FRAGMENT } from '../UserFragments';

export const GET_USERS_WITH_ROLES = gql`
  query GetUsersWithRoles {
    users {
      ...UserNameFragment

      role {
        name
      }
    }
  }

  ${USER_NAME_FRAGMENT}
`;
