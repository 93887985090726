import React from 'react';
import { Alert } from '@components/Alert';
import { Button } from '@components/Button';
import { CancelLink } from '@components/CancelLink';
import { Dropdown, DropdownOption } from '@components/Dropdown';
import { TextField } from '@components/TextField';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GET_FOLDERS, CREATE_FOLDER, ASSIGN_TO_FOLDER } from '@data/folders';
import {
  GetFolders,
  GetFoldersVariables,
  CreateFolder,
  CreateFolderVariables,
  AssignToFolder,
  AssignToFolderVariables
} from '@gql';
import { TAssignFolderModal } from './AssignFolderModal';

export const AssignFolderModalContent: React.FC<Omit<TAssignFolderModal, 'isOpen'>> = ({
  listId,
  onClose
}) => {
  const { t } = useTranslation();
  const [newFolderName, setNewFolderName] = React.useState('');
  const [selectedFolder, setSelectedFolder] = React.useState<DropdownOption>();
  const [folderOptions, setFolderOptions] = React.useState<DropdownOption[]>([]);
  const [selectExisting, setSelectExisting] = React.useState(true);
  const [invalidFolderName, setInvalidFolderName] = React.useState(false);

  const [createFolder, { error: createError }] = useMutation<CreateFolder, CreateFolderVariables>(
    CREATE_FOLDER
  );

  const [assignNodeToFolder, { error: assignError }] = useMutation<
    AssignToFolder,
    AssignToFolderVariables
  >(ASSIGN_TO_FOLDER);

  const validateFolderName = (name: string) => {
    const isValid = /^[a-zA-Z0-9_-]+$/.test(name);
    setInvalidFolderName(!isValid);
  };

  const handleSetSelectExisting = () => {
    setInvalidFolderName(false);
    setSelectExisting(!selectExisting);
  };

  const getOrCreateFolderId = async () => {
    if (selectExisting) {
      return selectedFolder?.value;
    }

    let folderId;
    try {
      await createFolder({
        variables: {
          input: {
            name: newFolderName
          }
        }
      }).then(resp => {
        folderId = resp.data?.createFolder?.folder?.id;
      });
    } catch (err) {
      console.error(err);
    }

    return folderId;
  };

  const handleAssignNodeToFolder = async () => {
    const folderId = await getOrCreateFolderId();
    try {
      await assignNodeToFolder({
        variables: {
          input: {
            folderId: folderId || '',
            folderableId: listId,
            replace: true
          }
        }
      });
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const { data, error: queryError } = useQuery<GetFolders, GetFoldersVariables>(GET_FOLDERS, {
    variables: {
      first: 100 // the intent here is pull all folders so we can build a full list for assignment
    },
    fetchPolicy: 'cache-and-network'
  });

  React.useEffect(() => {
    if (data?.folders?.nodes?.length) {
      const selectOptions = data.folders.nodes.map((folder: any | null) => {
        const folderName = folder?.name || '';
        return { label: folderName, value: folder.id };
      });
      setFolderOptions(selectOptions);
    }
  }, [data]);

  const isDisabled =
    (!selectedFolder?.value && selectExisting) ||
    (!newFolderName && !selectExisting) ||
    invalidFolderName;

  return (
    <>
      <div className="tw-flex tw-flex-col tw-mb-4 tw-px-2 tw-min-h-[100px]">
        {selectExisting ? (
          <div className="tw-flex tw-flex-col tw-pb-2" data-testid="assignFolderModal:dropdown">
            <Dropdown
              name={'selectFolderAssignment'}
              value={selectedFolder?.value || ''}
              options={folderOptions}
              onChange={(optionValue: string) => {
                const selectedFolder = folderOptions.find(opt => optionValue === opt.value);
                setSelectedFolder(selectedFolder);
              }}
            />
          </div>
        ) : (
          <div className="tw-flex tw-flex-col tw-mb-2" data-testid="assignFolderModal:textInput">
            <TextField
              className="tw-w-full"
              value={newFolderName}
              onChange={e => {
                validateFolderName(e.target.value);
                setNewFolderName(e.target.value);
              }}
            />
          </div>
        )}

        {createError && (
          <Alert data-testid="assignFolderModal:createError" type="error">
            {createError.message}
          </Alert>
        )}

        {assignError && (
          <Alert data-testid="assignFolderModal:assignError" type="error">
            {assignError.message}
          </Alert>
        )}

        {queryError && (
          <Alert data-testid="assignFolderModal:queryError" type="error">
            {queryError.message}
          </Alert>
        )}

        {invalidFolderName && (
          <Alert type="error">{t('folders.pages.modal.invalidFolderName')}</Alert>
        )}

        <p
          className="tw-cursor-pointer tw-text-nrs-blue tw-pt-2"
          data-testid="assignFolderModal:selectExistingButton"
          onClick={() => handleSetSelectExisting()}
        >
          {selectExisting
            ? t('folders.pages.modal.createNew')
            : t('folders.pages.modal.selectExisting')}
        </p>
      </div>

      <div className="tw-flex tw-justify-between">
        <CancelLink data-testid="assignFolderModal:cancel" onCancel={() => onClose()} />
        <Button
          disabled={isDisabled}
          className="tw-ml-2"
          data-testid="assignFolderModal:addToFolder"
          onClick={() => handleAssignNodeToFolder()}
        >
          {t('folders.pages.modal.addToFolder')}
        </Button>
      </div>
    </>
  );
};
