import * as React from 'react';
import { ContainerLayout } from '@components/ContainerLayout';
import { useTranslation } from 'react-i18next';
import { Card } from '@components/Card';
import { Title4 } from '@components/Title4';
import { CardTitle } from '@components/CardTitle';
import { QuickLinks } from './components/QuickLinks';
import { InfoContainer } from './components/InfoContainer';
import { SubgroupsContainer } from './components/SubgroupsContainer';

export const AutoModelerRoute: React.FC = () => {
  const { t } = useTranslation();

  const handleQuickLinkClick = (route: string) => {
    console.log(route);
  };

  return (
    <ContainerLayout>
      <div className="tw-grid tw-grid-cols-4 tw-gap-x-4">
        <div className="tw-col-span-2 tw-flex tw-flex-col tw-h-44">
          <Card className="tw-h-full tw-relative">
            <div className="tw-absolute tw-top-4 tw-right-4">
              <InfoContainer yPos="top">
                {t('automodeler.pages.modeler.sampleText.someMoreInformation')}
              </InfoContainer>
            </div>
            <div>{t('automodeler.pages.modeler.sampleText.supportBiden')}</div>
          </Card>
        </div>
        <div className="tw-col-span-2 tw-flex tw-flex-col">
          <QuickLinks onClick={handleQuickLinkClick} />
        </div>
        <div className="tw-flex tw-flex-col tw-h-44">
          <Card className="tw-h-full">
            <Title4>
              {t('common.subgroup', {
                postProcess: 'inlineFormatter',
                inlineFormatter: 'sentence'
              })}
            </Title4>
          </Card>
        </div>
        <div className="tw-flex tw-flex-col tw-h-44">
          <Card className="tw-h-full">73,029</Card>
        </div>
        <div className="tw-flex tw-flex-col tw-h-44">
          <Card className="tw-h-full">
            <Title4>
              {t('common.subgroup', {
                postProcess: 'inlineFormatter',
                inlineFormatter: 'sentence'
              })}
            </Title4>
          </Card>
        </div>
        <div className="tw-flex tw-flex-col tw-h-44">
          <Card className="tw-h-full">73,029</Card>
        </div>

        <div className="tw-col-span-4">
          <Card>
            <CardTitle>
              {t('automodeler.pages.modeler.geoTitle', { metric: t('common.support') })}
            </CardTitle>
          </Card>
        </div>

        <div className="tw-col-span-4">
          <SubgroupsContainer />
        </div>

        <div className="tw-col-span-4">
          <Card>
            <CardTitle>{t('automodeler.pages.modeler.universeTitle')}</CardTitle>
          </Card>
        </div>

        <div className="tw-col-span-4">
          <Card>
            <CardTitle>{t('automodeler.pages.modeler.aboutModelTitle')}</CardTitle>
          </Card>
        </div>
      </div>
    </ContainerLayout>
  );
};
