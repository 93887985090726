import * as React from 'react';
import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { AssignFolderModalContent } from './AssignFolderModalContent';

export type TAssignFolderModal = {
  listId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const AssignFolderModal: React.FC<TAssignFolderModal> = ({ listId, isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} title={t('folders.pages.modal.modalTitle')} onClose={onClose}>
      <AssignFolderModalContent listId={listId} onClose={onClose} />
    </Modal>
  );
};
