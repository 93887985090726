import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: any; output: any };
  Email: { input: any; output: any };
  ISO8601DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  Url: { input: any; output: any };
};

/** Autogenerated input type of AcceptOrganizationInvite */
export type AcceptOrganizationInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  organizationInviteId: Scalars['ID']['input'];
};

/** Autogenerated return type of AcceptOrganizationInvite. */
export type AcceptOrganizationInvitePayload = {
  __typename: 'AcceptOrganizationInvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

export type AlchemerSurvey = {
  __typename: 'AlchemerSurvey';
  alchemerSurveyQuestions: Array<AlchemerSurveyQuestion>;
  alchemerUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type AlchemerSurveyOption = {
  __typename: 'AlchemerSurveyOption';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AlchemerSurveyQuestion = {
  __typename: 'AlchemerSurveyQuestion';
  alchemerSurveyOptions: Maybe<Array<AlchemerSurveyOption>>;
  id: Scalars['ID']['output'];
  questionType: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ApiKey = {
  __typename: 'ApiKey';
  exp: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  jti: Scalars['String']['output'];
  label: Scalars['String']['output'];
  organization: Maybe<Organization>;
};

/** Autogenerated input type of ApproveMfaReset */
export type ApproveMfaResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

/** Autogenerated return type of ApproveMfaReset. */
export type ApproveMfaResetPayload = {
  __typename: 'ApproveMfaResetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  /** The new password generated as part of the the MFA reset process. */
  password: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ApprovePeopleListHydratorRun */
export type ApprovePeopleListHydratorRunInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ApprovePeopleListHydratorRun. */
export type ApprovePeopleListHydratorRunPayload = {
  __typename: 'ApprovePeopleListHydratorRunPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ArchivePeopleList */
export type ArchivePeopleListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  peopleListId: Scalars['ID']['input'];
};

/** Autogenerated return type of ArchivePeopleList. */
export type ArchivePeopleListPayload = {
  __typename: 'ArchivePeopleListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  peopleList: Maybe<PeopleList>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of AssignNodeToFolder */
export type AssignNodeToFolderInput = {
  applyShareRules?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  folderId: Scalars['ID']['input'];
  folderableId: Scalars['ID']['input'];
  replace?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of AssignNodeToFolder. */
export type AssignNodeToFolderPayload = {
  __typename: 'AssignNodeToFolderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  folder: Maybe<Folder>;
};

export enum BluebellModuleName {
  /** Universal access to Bluebell. */
  ALL = 'ALL',
  /** Generate predictive models */
  AUTOMODELER = 'AUTOMODELER',
  /** Create lists of people. */
  LIST_CREATOR = 'LIST_CREATOR',
  /** Upload files to Bluebell. */
  UPLOADER = 'UPLOADER'
}

export enum BluebellPrivilege {
  /** Analyst access to Automodeler */
  AUTOMODELER_ANALYST = 'AUTOMODELER_ANALYST',
  /** Read-only access to Automodeler */
  AUTOMODELER_READ = 'AUTOMODELER_READ',
  /** Full access to Automodeler */
  AUTOMODELER_WRITE = 'AUTOMODELER_WRITE',
  /** PII access in the Create-A-List tool. */
  CREATE_A_LIST_PII = 'CREATE_A_LIST_PII',
  /** Read-only access to the Create-A-List tool. */
  CREATE_A_LIST_READ = 'CREATE_A_LIST_READ',
  /** Full access to the Create-A-List tool. */
  CREATE_A_LIST_WRITE = 'CREATE_A_LIST_WRITE',
  /** Grants the ability to manage an Organization. */
  ORG_MANAGER = 'ORG_MANAGER',
  /** Grants the ability to upload files and manage them. */
  UPLOADER = 'UPLOADER'
}

export enum BluebellShareLevel {
  /** Resources are private by default. */
  NONE = 'NONE',
  /** All privileges on resources are enabled by default. */
  OWNER = 'OWNER',
  /** Resources are listable and readable by default. */
  READ = 'READ',
  /** Resources are listable, readable, and editable by default. */
  WRITE = 'WRITE'
}

export enum BluebellStatistic {
  /** Count */
  count = 'count'
}

/** Autogenerated input type of ClonePeopleList */
export type ClonePeopleListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cloneShareRules?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  peopleListId: Scalars['ID']['input'];
};

/** Autogenerated return type of ClonePeopleList. */
export type ClonePeopleListPayload = {
  __typename: 'ClonePeopleListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  peopleList: Maybe<PeopleList>;
};

export type ComputedBreakdown = {
  __typename: 'ComputedBreakdown';
  dataObject: DataObject;
  dataObjectBreakdown: DataObjectBreakdown;
  pivotTable: PivotTable;
};

export type ComputedBreakdownPreview = {
  __typename: 'ComputedBreakdownPreview';
  pivotTable: PivotTable;
  unit: Scalars['String']['output'];
};

/** Autogenerated input type of CreateApiKey */
export type CreateApiKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  /** Time-to-live for this key, in seconds. */
  ttl?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of CreateApiKey. */
export type CreateApiKeyPayload = {
  __typename: 'CreateApiKeyPayload';
  apiKey: Maybe<ApiKey>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  /** The JWT bearer token to used in the 'Authorization' header.  This can only be retrieved once. */
  token: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateDataObjectBreakdown */
export type CreateDataObjectBreakdownInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  definition: DataObjectBreakdownDefinitionInput;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateDataObjectBreakdown. */
export type CreateDataObjectBreakdownPayload = {
  __typename: 'CreateDataObjectBreakdownPayload';
  breakdown: Maybe<DataObjectBreakdown>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
};

/** Autogenerated input type of CreateFavorite */
export type CreateFavoriteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the object to favorite. Supported objects: PeopleList, UploadedFile */
  favoriteableId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateFavorite. */
export type CreateFavoritePayload = {
  __typename: 'CreateFavoritePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateFolder */
export type CreateFolderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateFolder. */
export type CreateFolderPayload = {
  __typename: 'CreateFolderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  folder: Maybe<Folder>;
};

/** Autogenerated input type of CreateGlobalQuestion */
export type CreateGlobalQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<QuestionOptionsInput>>;
  piiType?: InputMaybe<QuestionPiiTypes>;
  questionTextEn: Scalars['String']['input'];
  questionType: QuestionTypes;
};

/** Autogenerated return type of CreateGlobalQuestion. */
export type CreateGlobalQuestionPayload = {
  __typename: 'CreateGlobalQuestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  globalQuestion: Maybe<GlobalQuestion>;
};

/** Autogenerated input type of CreatePeopleListDownloadUrl */
export type CreatePeopleListDownloadUrlInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  peopleListId: Scalars['ID']['input'];
  /** Time-to-live in seconds for the generated URL.  Defaults to 900 and can be between 1 and 86400. */
  ttl?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of CreatePeopleListDownloadUrl. */
export type CreatePeopleListDownloadUrlPayload = {
  __typename: 'CreatePeopleListDownloadUrlPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  progress: Maybe<ProgressBar>;
  urlDetails: Maybe<ExpiringUrl>;
};

/** Autogenerated input type of CreatePeopleListHydratorRun */
export type CreatePeopleListHydratorRunInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  peopleListId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePeopleListHydratorRun. */
export type CreatePeopleListHydratorRunPayload = {
  __typename: 'CreatePeopleListHydratorRunPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  peopleListHydratorRun: Maybe<PeopleListHydratorRun>;
};

/** Autogenerated input type of CreatePeopleList */
export type CreatePeopleListInput = {
  buildDefinition: PeopleListBuildDefinitionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreatePeopleList. */
export type CreatePeopleListPayload = {
  __typename: 'CreatePeopleListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  peopleList: Maybe<PeopleList>;
};

/** Autogenerated input type of CreateQuestionGroup */
export type CreateQuestionGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateQuestionGroup. */
export type CreateQuestionGroupPayload = {
  __typename: 'CreateQuestionGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  questionGroup: Maybe<QuestionGroup>;
};

/** Autogenerated input type of CreateRole */
export type CreateRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  settings: RoleSettingsInput;
};

/** Autogenerated return type of CreateRole. */
export type CreateRolePayload = {
  __typename: 'CreateRolePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  role: Maybe<Role>;
};

/** Autogenerated input type of CreateSurveyRun */
export type CreateSurveyRunInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateSurveyRun. */
export type CreateSurveyRunPayload = {
  __typename: 'CreateSurveyRunPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  surveyRun: Maybe<SurveyRun>;
};

/** Autogenerated input type of CreateSurveyRunQuestion */
export type CreateSurveyRunQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  globalQuestionId: Scalars['ID']['input'];
  surveyRunId: Scalars['ID']['input'];
  userQuestionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateSurveyRunQuestion. */
export type CreateSurveyRunQuestionPayload = {
  __typename: 'CreateSurveyRunQuestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  surveyRunQuestion: Maybe<SurveyRunQuestion>;
};

/** Autogenerated input type of CreateTag */
export type CreateTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  /** ID of the object to tag. Supported objects: PeopleList, UploadedFile */
  taggableId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

/** Autogenerated return type of CreateTag. */
export type CreateTagPayload = {
  __typename: 'CreateTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  tag: Maybe<Tag>;
};

/** Autogenerated input type of CreateUploadAuthorization */
export type CreateUploadAuthorizationInput = {
  byteSize: Scalars['BigInt']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contentEncoding?: InputMaybe<Scalars['String']['input']>;
  /** Base64 encoded MD5 of the file's contents */
  contentMd5?: InputMaybe<Scalars['String']['input']>;
  contentType: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};

/** Autogenerated return type of CreateUploadAuthorization. */
export type CreateUploadAuthorizationPayload = {
  __typename: 'CreateUploadAuthorizationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  fields: Maybe<Scalars['JSON']['output']>;
  headers: Maybe<Scalars['JSON']['output']>;
  httpMethod: Maybe<Scalars['String']['output']>;
  uploadedFileId: Maybe<Scalars['ID']['output']>;
  url: Maybe<Scalars['Url']['output']>;
};

/** Autogenerated input type of CreateUserQuestion */
export type CreateUserQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<QuestionOptionsInput>>;
  questionGroupId: Scalars['ID']['input'];
  questionTextEn: Scalars['String']['input'];
  questionType: QuestionTypes;
};

/** Autogenerated return type of CreateUserQuestion. */
export type CreateUserQuestionPayload = {
  __typename: 'CreateUserQuestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  userQuestion: Maybe<UserQuestion>;
};

export type DataObject = {
  __typename: 'DataObject';
  createdAt: Scalars['ISO8601DateTime']['output'];
  dataSource: DataSource;
  id: Scalars['ID']['output'];
  member: DataObjectMember;
  name: Scalars['String']['output'];
  schema: DataObjectSchema;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type DataObjectBreakdown = {
  __typename: 'DataObjectBreakdown';
  breakdownFields: Array<DataObjectBreakdownField>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dataObject: DataObject;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  schemaFields: Array<DataObjectSchemaField>;
  settings: DataObjectBreakdownSettings;
  statistics: Array<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Attributes describing a DataObjectBreakdown object */
export type DataObjectBreakdownDefinitionInput = {
  dataObjectId: Scalars['ID']['input'];
  pivotColumnIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  pivotRowIds: Array<Scalars['ID']['input']>;
  statistics?: InputMaybe<Array<BluebellStatistic>>;
};

export type DataObjectBreakdownField = {
  __typename: 'DataObjectBreakdownField';
  createdAt: Scalars['ISO8601DateTime']['output'];
  dataObjectBreakdown: DataObjectBreakdown;
  dataObjectSchemaField: DataObjectSchemaField;
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type DataObjectBreakdownSettings = {
  __typename: 'DataObjectBreakdownSettings';
  pivotColumnIds: Array<Scalars['ID']['output']>;
  pivotRowIds: Array<Scalars['ID']['output']>;
};

/** The connection type for DataObject. */
export type DataObjectConnection = {
  __typename: 'DataObjectConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DataObjectEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DataObject>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DataObjectEdge = {
  __typename: 'DataObjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DataObject>;
};

/** Various kinds of objects belonging to a Data Source */
export type DataObjectMember = DbTable | PeopleList;

export type DataObjectSchema = {
  __typename: 'DataObjectSchema';
  fields: Array<DataObjectSchemaField>;
};

export type DataObjectSchemafieldsArgs = {
  includeEmptyOperators?: Scalars['Boolean']['input'];
};

export type DataObjectSchemaField = {
  __typename: 'DataObjectSchemaField';
  category: DataObjectSchemaFieldCategory;
  contextMetadata: DataObjectSchemaFieldContextMetadata;
  dataObjectId: Scalars['ID']['output'];
  dataType: DataObjectSchemaFieldData;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  operators: Array<FieldComparisonOperator>;
  primaryKey: Scalars['Boolean']['output'];
  validValues: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum DataObjectSchemaFieldCategory {
  DEMOGRAPHIC = 'DEMOGRAPHIC',
  LOCATION = 'LOCATION',
  OTHER = 'OTHER',
  PII = 'PII',
  POLITICAL = 'POLITICAL',
  UID = 'UID'
}

export type DataObjectSchemaFieldContextMetadata = {
  __typename: 'DataObjectSchemaFieldContextMetadata';
  categories: Array<DataObjectSchemaFieldCategory>;
  required: DataObjectSchemaFieldContextRequired;
};

export enum DataObjectSchemaFieldContextRequired {
  ALWAYS = 'ALWAYS',
  NEVER = 'NEVER',
  OPTIONAL = 'OPTIONAL'
}

export enum DataObjectSchemaFieldData {
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  INTEGER = 'INTEGER',
  NUMERIC = 'NUMERIC',
  POINT = 'POINT',
  STRING = 'STRING'
}

export type DataObjectSchemaFieldValidValues = {
  __typename: 'DataObjectSchemaFieldValidValues';
  contextMetadata: DataObjectSchemaFieldContextMetadata;
  /** Some fields require context to compute their valid values, due to potentially very high cardinality. For example a 'County' field is typically not useful outside of the context of some initial geographic field such as 'State' or 'DMA'. */
  moreContextRequired: Scalars['Boolean']['output'];
  operators: Array<FieldComparisonOperator>;
  validValues: Maybe<Array<Scalars['String']['output']>>;
};

export type DataObjectSchemaFieldValue = {
  __typename: 'DataObjectSchemaFieldValue';
  field: DataObjectSchemaField;
  value: Maybe<Scalars['String']['output']>;
};

export type DataSource = {
  __typename: 'DataSource';
  backendDetails: DataSourceBackendDetails;
  backendType: DataSourceBackend;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creator: User;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  status: Scalars['String']['output'];
  statusDetails: Scalars['JSON']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum DataSourceBackend {
  POSTGRES = 'POSTGRES',
  S3 = 'S3',
  SYSTEM = 'SYSTEM'
}

/** Details describing how Bluebell can connect to a DataSource */
export type DataSourceBackendDetails =
  | DataSourcePostgresBackendDetails
  | DataSourceS3BackendDetails
  | DataSourceSystemBackendDetails;

/** The connection type for DataSource. */
export type DataSourceConnection = {
  __typename: 'DataSourceConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DataSourceEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DataSource>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DataSourceEdge = {
  __typename: 'DataSourceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DataSource>;
};

export type DataSourcePostgresBackendDetails = {
  __typename: 'DataSourcePostgresBackendDetails';
  backendType: DataSourceBackend;
  /** Name of the PostgreSQL database */
  dbname: Scalars['String']['output'];
  /** Hostname of the PostgreSQL server */
  host: Scalars['String']['output'];
  port: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type DataSourceS3BackendDetails = {
  __typename: 'DataSourceS3BackendDetails';
  backendType: DataSourceBackend;
  bucket: Scalars['String']['output'];
  /** The prefix or foldername to use for partial access to the S3 bucket */
  prefix: Maybe<Scalars['String']['output']>;
  /** The AWS region of the S3 bucket */
  region: Scalars['String']['output'];
};

export type DataSourceSystemBackendDetails = {
  __typename: 'DataSourceSystemBackendDetails';
  backendType: DataSourceBackend;
};

export type DbTable = {
  __typename: 'DbTable';
  createdAt: Scalars['ISO8601DateTime']['output'];
  creator: User;
  dataSource: DataSource;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  recordCount: Scalars['Int']['output'];
  schema: DataObjectSchema;
  tableName: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of DeclineOrganizationInvite */
export type DeclineOrganizationInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  organizationInviteId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeclineOrganizationInvite. */
export type DeclineOrganizationInvitePayload = {
  __typename: 'DeclineOrganizationInvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteApiKey */
export type DeleteApiKeyInput = {
  apiKeyId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  jti?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteApiKey. */
export type DeleteApiKeyPayload = {
  __typename: 'DeleteApiKeyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteDataObjectBreakdown */
export type DeleteDataObjectBreakdownInput = {
  breakdownId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteDataObjectBreakdown. */
export type DeleteDataObjectBreakdownPayload = {
  __typename: 'DeleteDataObjectBreakdownPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteFavorite */
export type DeleteFavoriteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the object to favorite. Supported objects: PeopleList, UploadedFile */
  favoriteableId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteFavorite. */
export type DeleteFavoritePayload = {
  __typename: 'DeleteFavoritePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteFolder */
export type DeleteFolderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteFolder. */
export type DeleteFolderPayload = {
  __typename: 'DeleteFolderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteGlobalQuestion */
export type DeleteGlobalQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  globalQuestionId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteGlobalQuestion. */
export type DeleteGlobalQuestionPayload = {
  __typename: 'DeleteGlobalQuestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteOrganizationInvite */
export type DeleteOrganizationInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  organizationInviteId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteOrganizationInvite. */
export type DeleteOrganizationInvitePayload = {
  __typename: 'DeleteOrganizationInvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePeopleListHydratorRun */
export type DeletePeopleListHydratorRunInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  peopleListHydratorRunId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePeopleListHydratorRun. */
export type DeletePeopleListHydratorRunPayload = {
  __typename: 'DeletePeopleListHydratorRunPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteQuestionGroup */
export type DeleteQuestionGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  questionGroupId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteQuestionGroup. */
export type DeleteQuestionGroupPayload = {
  __typename: 'DeleteQuestionGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteRole */
export type DeleteRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roleId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteRole. */
export type DeleteRolePayload = {
  __typename: 'DeleteRolePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteTag */
export type DeleteTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  tagId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteTag. */
export type DeleteTagPayload = {
  __typename: 'DeleteTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteUserFromOrganization */
export type DeleteUserFromOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteUserFromOrganization. */
export type DeleteUserFromOrganizationPayload = {
  __typename: 'DeleteUserFromOrganizationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteUserQuestion */
export type DeleteUserQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userQuestionId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteUserQuestion. */
export type DeleteUserQuestionPayload = {
  __typename: 'DeleteUserQuestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

export type DistanceSearch = {
  __typename: 'DistanceSearch';
  /** The search distance in miles. */
  distance: Scalars['Float']['output'];
  googlePlaceId: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  long: Scalars['Float']['output'];
};

/** Attributes for searching by distance from a geographic location. */
export type DistanceSearchInput = {
  /** The search distance in miles. */
  distance: Scalars['Float']['input'];
  googlePlaceId: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
};

export type ExpiringUrl = {
  __typename: 'ExpiringUrl';
  expiration: Maybe<Scalars['ISO8601DateTime']['output']>;
  ttl: Maybe<Scalars['Int']['output']>;
  url: Maybe<Scalars['Url']['output']>;
};

export enum FieldCombineOperator {
  AND = 'AND',
  OR = 'OR'
}

export enum FieldComparisonOperator {
  BETWEEN = 'BETWEEN',
  GTE_DISTANCE = 'GTE_DISTANCE',
  IN = 'IN',
  IS_NULL = 'IS_NULL',
  LTE_DISTANCE = 'LTE_DISTANCE',
  NOT_IN = 'NOT_IN'
}

/** Autogenerated input type of FinalizeUpload */
export type FinalizeUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uploadedFileId: Scalars['ID']['input'];
};

/** Autogenerated return type of FinalizeUpload. */
export type FinalizeUploadPayload = {
  __typename: 'FinalizeUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
  uploadedFile: Maybe<UploadedFile>;
};

export type Folder = ShareSettings & {
  __typename: 'Folder';
  contents: Array<FolderContent>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgShareLevel: BluebellShareLevel;
  shareRules: Maybe<Array<ShareRule>>;
};

/** The connection type for Folder. */
export type FolderConnection = {
  __typename: 'FolderConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<FolderEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Folder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FolderContent = {
  __typename: 'FolderContent';
  folderable: Folderable;
  id: Scalars['ID']['output'];
};

/** An edge in a connection. */
export type FolderEdge = {
  __typename: 'FolderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Folder>;
};

/** Objects which can be added to a folder. */
export type Folderable = PeopleList;

export type GeneratedUrl = {
  __typename: 'GeneratedUrl';
  progress: ProgressBar;
  urlDetails: Maybe<ExpiringUrl>;
};

/** This type associates a count or score with a given geographic locality. */
export type GeoScore = {
  __typename: 'GeoScore';
  /** ID field of the locality, typically a FIPS code or DMA name */
  id: Scalars['String']['output'];
  /** Human-friendly name of this locality. */
  label: Scalars['String']['output'];
  /** The numeric count or score for the specified locality. */
  value: Scalars['Float']['output'];
};

/** A container for GeoScoreType data along with various other pieces of metadata related to the scores. */
export type GeoScoreMetadata = {
  __typename: 'GeoScoreMetadata';
  localityType: Scalars['String']['output'];
  scores: Array<GeoScore>;
  unit: Scalars['String']['output'];
};

export type GlobalQuestion = {
  __typename: 'GlobalQuestion';
  id: Scalars['ID']['output'];
  options: Maybe<Array<QuestionOptions>>;
  questionTextEn: Scalars['String']['output'];
  questionType: Scalars['String']['output'];
};

/** The connection type for GlobalQuestion. */
export type GlobalQuestionConnection = {
  __typename: 'GlobalQuestionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<GlobalQuestionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<GlobalQuestion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type GlobalQuestionEdge = {
  __typename: 'GlobalQuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<GlobalQuestion>;
};

export type IdLabel = {
  __typename: 'IdLabel';
  /** ID used for machine references to this pair. */
  id: Scalars['String']['output'];
  /** Human-friendly label for this pair. */
  label: Scalars['String']['output'];
};

/** Autogenerated input type of InviteUserToOrganization */
export type InviteUserToOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  roleId: Scalars['ID']['input'];
};

/** Autogenerated return type of InviteUserToOrganization. */
export type InviteUserToOrganizationPayload = {
  __typename: 'InviteUserToOrganizationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  organizationInvite: Maybe<OrganizationInvite>;
};

export type Mutation = {
  __typename: 'Mutation';
  acceptOrganizationInvite: Maybe<AcceptOrganizationInvitePayload>;
  approveMfaReset: Maybe<ApproveMfaResetPayload>;
  approvePeopleListHydratorRun: Maybe<ApprovePeopleListHydratorRunPayload>;
  archivePeopleList: Maybe<ArchivePeopleListPayload>;
  assignNodeToFolder: Maybe<AssignNodeToFolderPayload>;
  clonePeopleList: Maybe<ClonePeopleListPayload>;
  createApiKey: Maybe<CreateApiKeyPayload>;
  createDataObjectBreakdown: Maybe<CreateDataObjectBreakdownPayload>;
  createFavorite: Maybe<CreateFavoritePayload>;
  createFolder: Maybe<CreateFolderPayload>;
  createGlobalQuestion: Maybe<CreateGlobalQuestionPayload>;
  createPeopleList: Maybe<CreatePeopleListPayload>;
  createPeopleListDownloadUrl: Maybe<CreatePeopleListDownloadUrlPayload>;
  createPeopleListHydratorRun: Maybe<CreatePeopleListHydratorRunPayload>;
  createQuestionGroup: Maybe<CreateQuestionGroupPayload>;
  createRole: Maybe<CreateRolePayload>;
  createSurveyRun: Maybe<CreateSurveyRunPayload>;
  createSurveyRunQuestion: Maybe<CreateSurveyRunQuestionPayload>;
  createTag: Maybe<CreateTagPayload>;
  /**
   *         CreateUploadAuthorization is the first step in a 3-step process to upload a file.  The process is broadly:
   *
   *           * Perform the CreateUploadAuthorization mutation.
   *           * Perform a HTTP POST or PUT to the URL specified in the response.
   *           * Perform the FinalizeUpload mutation.
   *
   *         CreateUploadAuthorization's response specifies all the required parameters for the POST/PUT to be generated correctly.
   *         The POST/PUT should be a multipart/form-data request. The `httpMethod` return value indicates whether to use a POST or PUT.
   *         The `fields` return value should passed as form data, with the user's file appended as binary data in the `file` form key.
   *         The `headers` return value should be passed as request headers.
   *
   *         The POST method should return a HTTP 204.  A PUT should return HTTP 200.
   *
   */
  createUploadAuthorization: Maybe<CreateUploadAuthorizationPayload>;
  createUserQuestion: Maybe<CreateUserQuestionPayload>;
  declineOrganizationInvite: Maybe<DeclineOrganizationInvitePayload>;
  deleteApiKey: Maybe<DeleteApiKeyPayload>;
  deleteDataObjectBreakdown: Maybe<DeleteDataObjectBreakdownPayload>;
  deleteFavorite: Maybe<DeleteFavoritePayload>;
  deleteFolder: Maybe<DeleteFolderPayload>;
  deleteGlobalQuestion: Maybe<DeleteGlobalQuestionPayload>;
  deleteOrganizationInvite: Maybe<DeleteOrganizationInvitePayload>;
  /** Deletes a PeopleListHydratorRun object.  Only runs in the 'pending' state can be deleted. */
  deletePeopleListHydratorRun: Maybe<DeletePeopleListHydratorRunPayload>;
  deleteQuestionGroup: Maybe<DeleteQuestionGroupPayload>;
  deleteRole: Maybe<DeleteRolePayload>;
  deleteTag: Maybe<DeleteTagPayload>;
  deleteUserFromOrganization: Maybe<DeleteUserFromOrganizationPayload>;
  deleteUserQuestion: Maybe<DeleteUserQuestionPayload>;
  /**
   *         FinalizeUpload is the last step in the process to upload a file.
   *         See CreateUploadAuthorization for details.
   *
   */
  finalizeUpload: Maybe<FinalizeUploadPayload>;
  inviteUserToOrganization: Maybe<InviteUserToOrganizationPayload>;
  rejectMfaReset: Maybe<RejectMfaResetPayload>;
  removeNodeFromFolder: Maybe<RemoveNodeFromFolderPayload>;
  requestMfaReset: Maybe<RequestMfaResetPayload>;
  setPeopleListMutability: Maybe<SetPeopleListMutabilityPayload>;
  startSurveyRun: Maybe<StartSurveyRunPayload>;
  syncSurveyRun: Maybe<SyncSurveyRunPayload>;
  unarchivePeopleList: Maybe<UnarchivePeopleListPayload>;
  updateDataObjectBreakdown: Maybe<UpdateDataObjectBreakdownPayload>;
  updateGlobalQuestion: Maybe<UpdateGlobalQuestionPayload>;
  updateNodeShareSettings: Maybe<UpdateNodeShareSettingsPayload>;
  updateOrganizationSettings: Maybe<UpdateOrganizationSettingsPayload>;
  updatePeopleList: Maybe<UpdatePeopleListPayload>;
  updateQuestionGroup: Maybe<UpdateQuestionGroupPayload>;
  updateRole: Maybe<UpdateRolePayload>;
  updateSurveyRun: Maybe<UpdateSurveyRunPayload>;
  updateTag: Maybe<UpdateTagPayload>;
  updateUserProfile: Maybe<UpdateUserProfilePayload>;
  updateUserQuestion: Maybe<UpdateUserQuestionPayload>;
  updateUserRole: Maybe<UpdateUserRolePayload>;
};

export type MutationacceptOrganizationInviteArgs = {
  input: AcceptOrganizationInviteInput;
};

export type MutationapproveMfaResetArgs = {
  input: ApproveMfaResetInput;
};

export type MutationapprovePeopleListHydratorRunArgs = {
  input: ApprovePeopleListHydratorRunInput;
};

export type MutationarchivePeopleListArgs = {
  input: ArchivePeopleListInput;
};

export type MutationassignNodeToFolderArgs = {
  input: AssignNodeToFolderInput;
};

export type MutationclonePeopleListArgs = {
  input: ClonePeopleListInput;
};

export type MutationcreateApiKeyArgs = {
  input: CreateApiKeyInput;
};

export type MutationcreateDataObjectBreakdownArgs = {
  input: CreateDataObjectBreakdownInput;
};

export type MutationcreateFavoriteArgs = {
  input: CreateFavoriteInput;
};

export type MutationcreateFolderArgs = {
  input: CreateFolderInput;
};

export type MutationcreateGlobalQuestionArgs = {
  input: CreateGlobalQuestionInput;
};

export type MutationcreatePeopleListArgs = {
  input: CreatePeopleListInput;
};

export type MutationcreatePeopleListDownloadUrlArgs = {
  input: CreatePeopleListDownloadUrlInput;
};

export type MutationcreatePeopleListHydratorRunArgs = {
  input: CreatePeopleListHydratorRunInput;
};

export type MutationcreateQuestionGroupArgs = {
  input: CreateQuestionGroupInput;
};

export type MutationcreateRoleArgs = {
  input: CreateRoleInput;
};

export type MutationcreateSurveyRunArgs = {
  input: CreateSurveyRunInput;
};

export type MutationcreateSurveyRunQuestionArgs = {
  input: CreateSurveyRunQuestionInput;
};

export type MutationcreateTagArgs = {
  input: CreateTagInput;
};

export type MutationcreateUploadAuthorizationArgs = {
  input: CreateUploadAuthorizationInput;
};

export type MutationcreateUserQuestionArgs = {
  input: CreateUserQuestionInput;
};

export type MutationdeclineOrganizationInviteArgs = {
  input: DeclineOrganizationInviteInput;
};

export type MutationdeleteApiKeyArgs = {
  input: DeleteApiKeyInput;
};

export type MutationdeleteDataObjectBreakdownArgs = {
  input: DeleteDataObjectBreakdownInput;
};

export type MutationdeleteFavoriteArgs = {
  input: DeleteFavoriteInput;
};

export type MutationdeleteFolderArgs = {
  input: DeleteFolderInput;
};

export type MutationdeleteGlobalQuestionArgs = {
  input: DeleteGlobalQuestionInput;
};

export type MutationdeleteOrganizationInviteArgs = {
  input: DeleteOrganizationInviteInput;
};

export type MutationdeletePeopleListHydratorRunArgs = {
  input: DeletePeopleListHydratorRunInput;
};

export type MutationdeleteQuestionGroupArgs = {
  input: DeleteQuestionGroupInput;
};

export type MutationdeleteRoleArgs = {
  input: DeleteRoleInput;
};

export type MutationdeleteTagArgs = {
  input: DeleteTagInput;
};

export type MutationdeleteUserFromOrganizationArgs = {
  input: DeleteUserFromOrganizationInput;
};

export type MutationdeleteUserQuestionArgs = {
  input: DeleteUserQuestionInput;
};

export type MutationfinalizeUploadArgs = {
  input: FinalizeUploadInput;
};

export type MutationinviteUserToOrganizationArgs = {
  input: InviteUserToOrganizationInput;
};

export type MutationrejectMfaResetArgs = {
  input: RejectMfaResetInput;
};

export type MutationremoveNodeFromFolderArgs = {
  input: RemoveNodeFromFolderInput;
};

export type MutationrequestMfaResetArgs = {
  input: RequestMfaResetInput;
};

export type MutationsetPeopleListMutabilityArgs = {
  input: SetPeopleListMutabilityInput;
};

export type MutationstartSurveyRunArgs = {
  input: StartSurveyRunInput;
};

export type MutationsyncSurveyRunArgs = {
  input: SyncSurveyRunInput;
};

export type MutationunarchivePeopleListArgs = {
  input: UnarchivePeopleListInput;
};

export type MutationupdateDataObjectBreakdownArgs = {
  input: UpdateDataObjectBreakdownInput;
};

export type MutationupdateGlobalQuestionArgs = {
  input: UpdateGlobalQuestionInput;
};

export type MutationupdateNodeShareSettingsArgs = {
  input: UpdateNodeShareSettingsInput;
};

export type MutationupdateOrganizationSettingsArgs = {
  input: UpdateOrganizationSettingsInput;
};

export type MutationupdatePeopleListArgs = {
  input: UpdatePeopleListInput;
};

export type MutationupdateQuestionGroupArgs = {
  input: UpdateQuestionGroupInput;
};

export type MutationupdateRoleArgs = {
  input: UpdateRoleInput;
};

export type MutationupdateSurveyRunArgs = {
  input: UpdateSurveyRunInput;
};

export type MutationupdateTagArgs = {
  input: UpdateTagInput;
};

export type MutationupdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

export type MutationupdateUserQuestionArgs = {
  input: UpdateUserQuestionInput;
};

export type MutationupdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};

export type MutationError = {
  __typename: 'MutationError';
  /** Human readable error message */
  message: Scalars['String']['output'];
  /** The graphql argument corresponding to the error */
  path: Array<Scalars['String']['output']>;
};

export type NodeUserPreferences = {
  __typename: 'NodeUserPreferences';
  favorite: Scalars['Boolean']['output'];
};

export type Organization = {
  __typename: 'Organization';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationInvites: Array<OrganizationInvite>;
  roles: Array<Role>;
  settings: OrganizationSettings;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userRoles: Array<UserRole>;
};

export type OrganizationInvite = {
  __typename: 'OrganizationInvite';
  creator: User;
  email: Scalars['Email']['output'];
  id: Scalars['ID']['output'];
  organization: Organization;
  role: Role;
  state: OrganizationInviteState;
};

export enum OrganizationInviteState {
  accepted = 'accepted',
  declined = 'declined',
  notified = 'notified',
  pending = 'pending',
  signup_initiated = 'signup_initiated',
  timed_out = 'timed_out'
}

export type OrganizationSettings = {
  __typename: 'OrganizationSettings';
  defaultShareLevel: BluebellShareLevel;
  hydratorColumns: Array<Scalars['String']['output']>;
  modules: Array<BluebellModuleName>;
};

/** Attributes for the 'settings' field on Organization objects */
export type OrganizationSettingsInput = {
  /** Default access level granted to organization members on various objects */
  defaultShareLevel: BluebellShareLevel;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

export type PeopleList = ShareSettings & {
  __typename: 'PeopleList';
  availableCountUnits: Array<IdLabel>;
  availableGeoLocalities: Array<IdLabel>;
  breakdowns: Array<Maybe<ComputedBreakdown>>;
  buildDefinition: PeopleListBuildDefinition;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creator: User;
  /** ID of the DataObject associated with this PeopleList */
  dataObjectId: Scalars['ID']['output'];
  dataSources: Array<DataSource>;
  downloadUrl: Maybe<GeneratedUrl>;
  id: Scalars['ID']['output'];
  /** This field controls whether the list may be refreshed either manually, automatically, or not at all. */
  mutability: PeopleListMutability;
  name: Scalars['String']['output'];
  nodeUserPreferences: Maybe<NodeUserPreferences>;
  orgShareLevel: BluebellShareLevel;
  peopleListHydratorRuns: Maybe<PeopleListHydratorRunConnection>;
  shareRules: Maybe<Array<ShareRule>>;
  state: PeopleListState;
  summaryStats: Array<Maybe<PeopleListSummaryStats>>;
  tags: Array<Maybe<Tag>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type PeopleListdownloadUrlArgs = {
  ttl?: InputMaybe<Scalars['Int']['input']>;
};

export type PeopleListpeopleListHydratorRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PeopleListBuildDefinition = {
  __typename: 'PeopleListBuildDefinition';
  steps: Array<PeopleListBuildStep>;
};

/** Attributes describing the build steps for a PeopleList */
export type PeopleListBuildDefinitionInput = {
  steps: Array<PeopleListBuildStepInput>;
};

export type PeopleListBuildStep = {
  __typename: 'PeopleListBuildStep';
  action: PeopleListBuildStepAction;
  createdAt: Scalars['ISO8601DateTime']['output'];
  fieldCombineOperator: FieldCombineOperator;
  fields: Array<PeopleListBuildStepField>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum PeopleListBuildStepAction {
  ADD = 'ADD',
  NARROW = 'NARROW',
  REMOVE = 'REMOVE'
}

export type PeopleListBuildStepField = {
  __typename: 'PeopleListBuildStepField';
  comparisonOperator: FieldComparisonOperator;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dataObject: DataObject;
  dataObjectSchemaField: DataObjectSchemaField;
  dataObjectSchemaFieldId: Scalars['ID']['output'];
  dataSource: DataSource;
  distance: Maybe<DistanceSearch>;
  id: Scalars['ID']['output'];
  inputValue: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  peopleListBuildStepId: Scalars['ID']['output'];
  range: Maybe<Range>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Attributes for a single field's selection criteria */
export type PeopleListBuildStepFieldInput = {
  comparisonOperator: FieldComparisonOperator;
  dataObjectSchemaFieldId: Scalars['ID']['input'];
  /** Parameters for a geo-distance search. */
  distance?: InputMaybe<DistanceSearchInput>;
  /**
   *       The `inputValue` field is intended for the user-specified value selected for the referenced field.
   *       The specific intepretation depends on the `comparisonOperator` selected.
   *       For the `IN` / `NOT_IN` operators, `inputValue` can be an array of arbitrary length.
   *       For other operators it should be an array with a single element.
   *
   */
  inputValue?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Parameters for a range search using the BETWEEN operator. */
  range?: InputMaybe<RangeInput>;
};

/** Attributes for the individual 'steps' field on PeopleList objects */
export type PeopleListBuildStepInput = {
  action: PeopleListBuildStepAction;
  fieldCombineOperator?: FieldCombineOperator;
  fields: Array<PeopleListBuildStepFieldInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PeopleListBuildStepInvalidValue = {
  __typename: 'PeopleListBuildStepInvalidValue';
  dataObjectSchemaFieldId: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type PeopleListBuildStepValidator = {
  __typename: 'PeopleListBuildStepValidator';
  invalidValues: Array<PeopleListBuildStepInvalidValue>;
  valid: Scalars['Boolean']['output'];
};

/** The connection type for PeopleList. */
export type PeopleListConnection = {
  __typename: 'PeopleListConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PeopleListEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PeopleList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PeopleListEdge = {
  __typename: 'PeopleListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<PeopleList>;
};

/** Filters for a collection of peeople list objects */
export type PeopleListFilterInput = {
  favorite?: InputMaybe<Scalars['Boolean']['input']>;
  sharedByMe?: InputMaybe<Scalars['Boolean']['input']>;
  sharedWithMe?: InputMaybe<Scalars['Boolean']['input']>;
  states?: InputMaybe<Array<PeopleListState>>;
  tagKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  tagValues?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PeopleListHydratorRun = {
  __typename: 'PeopleListHydratorRun';
  columns: Array<Scalars['String']['output']>;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creator: User;
  downloadUrl: Maybe<ExpiringUrl>;
  estimatedCost: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  matchedCount: Maybe<Scalars['Int']['output']>;
  state: PeopleListHydratorRunState;
  submittedAt: Scalars['ISO8601DateTime']['output'];
  submittedCount: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type PeopleListHydratorRundownloadUrlArgs = {
  ttl?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for PeopleListHydratorRun. */
export type PeopleListHydratorRunConnection = {
  __typename: 'PeopleListHydratorRunConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PeopleListHydratorRunEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PeopleListHydratorRun>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PeopleListHydratorRunEdge = {
  __typename: 'PeopleListHydratorRunEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<PeopleListHydratorRun>;
};

export enum PeopleListHydratorRunState {
  completed = 'completed',
  failed = 'failed',
  processing = 'processing'
}

export enum PeopleListMutability {
  AUTOREFRESH = 'AUTOREFRESH',
  LOCKED = 'LOCKED',
  REFRESHABLE = 'REFRESHABLE'
}

export enum PeopleListState {
  archived = 'archived',
  failed = 'failed',
  matching_pii = 'matching_pii',
  pending = 'pending',
  processing = 'processing',
  ready = 'ready'
}

export type PeopleListSummaryStats = {
  __typename: 'PeopleListSummaryStats';
  description: Scalars['String']['output'];
  label: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type PivotTable = {
  __typename: 'PivotTable';
  columnLabels: Array<Scalars['String']['output']>;
  columns: Array<PivotTableColumn>;
  rows: Array<PivotTableRow>;
};

export type PivotTableColumn = {
  __typename: 'PivotTableColumn';
  aggregates: Array<Maybe<StatisticValue>>;
  label: Scalars['String']['output'];
};

export type PivotTablePoint = {
  __typename: 'PivotTablePoint';
  fieldValues: Array<DataObjectSchemaFieldValue>;
  statisticValues: Array<StatisticValue>;
};

export type PivotTableRow = {
  __typename: 'PivotTableRow';
  aggregates: Maybe<Array<StatisticValue>>;
  labels: Array<Scalars['String']['output']>;
  points: Array<PivotTablePoint>;
  rowLabels: Array<Scalars['String']['output']>;
};

export type ProgressBar = {
  __typename: 'ProgressBar';
  complete: Scalars['Boolean']['output'];
  currentStepIndex: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  steps: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Query = {
  __typename: 'Query';
  /** Calculates the values of a breakdown or breakdown definition */
  computeDataObjectBreakdownPreview: ComputedBreakdownPreview;
  /** Retrieves one Data Object */
  dataObject: DataObject;
  /** Retrieves one DataObjectBreakdown object */
  dataObjectBreakdown: DataObjectBreakdown;
  /** Retrieves one DataObjectField */
  dataObjectField: DataObjectSchemaField;
  /** Data object field categories */
  dataObjectFieldCategories: Array<DataObjectSchemaFieldCategory>;
  /** Valid values for a data object in a given context. */
  dataObjectFieldValidValues: DataObjectSchemaFieldValidValues;
  /** Returns a list of Data Objects */
  dataObjects: DataObjectConnection;
  /** Retrieves a list of Data Objects suitable for the given breakdown context */
  dataObjectsForBreakdowns: DataObjectConnection;
  /** Retrieves one Data Source */
  dataSource: DataSource;
  /** Returns a list of Data Sources */
  dataSources: DataSourceConnection;
  /** Folder by name */
  folder: Folder;
  /** Folders visible to the current user */
  folders: FolderConnection;
  /** Retrieves count data broken down by locality as well as additional metadata. */
  geoCounts: GeoScoreMetadata;
  /** GlobalQuestion records */
  globalQuestions: GlobalQuestionConnection;
  /** Returns the current user */
  me: User;
  /** Retrieves one organization */
  organization: Organization;
  /** Retrieves one Organization Invite */
  organizationInvite: OrganizationInvite;
  /** Returns a list of Organizations */
  organizations: Array<Organization>;
  /** Retrieves one PeopleList record */
  peopleList: PeopleList;
  /** Checks the specified build step for valid contextual inputs */
  peopleListBuildStepValidator: PeopleListBuildStepValidator;
  /** Retrieves one PeopleListHydratorRun record */
  peopleListHydratorRun: PeopleListHydratorRun;
  /** PeopleLists visible to the current user */
  peopleLists: PeopleListConnection;
  /** QuestionGroup records */
  questionGroups: QuestionGroupConnection;
  /** Retrieves one role */
  role: Role;
  /** Roles associated with the current organization */
  roles: Array<Role>;
  /** Retrieves one SurveyRun record */
  surveyRun: SurveyRun;
  /** Retrieves one SurveyRunQuestion record */
  surveyRunQuestion: SurveyRunQuestion;
  /** SurveyRun records */
  surveyRuns: SurveyRunConnection;
  /** Tags visible to the current user */
  tags: TagConnection;
  /** Retrieves one UploadedFile record */
  uploadedFile: UploadedFile;
  /** Uploaded files visible to the current user */
  uploadedFiles: UploadedFileConnection;
  /** Users in the current org */
  users: Array<User>;
  /** Version */
  version: Scalars['String']['output'];
};

export type QuerycomputeDataObjectBreakdownPreviewArgs = {
  breakdownDefinition?: InputMaybe<DataObjectBreakdownDefinitionInput>;
  breakdownId?: InputMaybe<Scalars['ID']['input']>;
  peopleListId: Scalars['ID']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type QuerydataObjectArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydataObjectBreakdownArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydataObjectFieldArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydataObjectFieldValidValuesArgs = {
  fieldCombineOperator?: FieldCombineOperator;
  id: Scalars['ID']['input'];
  maxCardinality?: InputMaybe<Scalars['Int']['input']>;
  relatedFields?: InputMaybe<Array<PeopleListBuildStepFieldInput>>;
};

export type QuerydataObjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  dataSourceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerydataObjectsForBreakdownsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  peopleListId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerydataSourceArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydataSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryfolderArgs = {
  name: Scalars['String']['input'];
};

export type QueryfoldersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygeoCountsArgs = {
  localityType?: InputMaybe<Scalars['String']['input']>;
  peopleListId: Scalars['ID']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type QueryglobalQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryorganizationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryorganizationInviteArgs = {
  id: Scalars['ID']['input'];
};

export type QuerypeopleListArgs = {
  id: Scalars['ID']['input'];
};

export type QuerypeopleListBuildStepValidatorArgs = {
  definition?: InputMaybe<PeopleListBuildStepInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerypeopleListHydratorRunArgs = {
  id: Scalars['ID']['input'];
};

export type QuerypeopleListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PeopleListFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryquestionGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryroleArgs = {
  id: Scalars['ID']['input'];
};

export type QuerysurveyRunArgs = {
  id: Scalars['ID']['input'];
};

export type QuerysurveyRunQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type QuerysurveyRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerytagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryuploadedFileArgs = {
  id: Scalars['ID']['input'];
};

export type QueryuploadedFilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QuestionGroup = {
  __typename: 'QuestionGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  userQuestions: Array<UserQuestion>;
};

/** The connection type for QuestionGroup. */
export type QuestionGroupConnection = {
  __typename: 'QuestionGroupConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<QuestionGroupEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<QuestionGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type QuestionGroupEdge = {
  __typename: 'QuestionGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<QuestionGroup>;
};

export type QuestionOptions = {
  __typename: 'QuestionOptions';
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Multiple choice options for Automodeler questions */
export type QuestionOptionsInput = {
  title: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum QuestionPiiTypes {
  age = 'age',
  first_name = 'first_name',
  last_name = 'last_name',
  state = 'state',
  zip = 'zip'
}

export enum QuestionTypes {
  pii = 'pii',
  radio = 'radio',
  text = 'text'
}

export type Range = {
  __typename: 'Range';
  max: Maybe<Scalars['String']['output']>;
  min: Maybe<Scalars['String']['output']>;
};

/** A range of values of various scalar types, encoded as a string.  At least one argument must be specified. */
export type RangeInput = {
  /** Maximum value of the range */
  max?: InputMaybe<Scalars['String']['input']>;
  /** Minimum value of the range */
  min?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RejectMfaReset */
export type RejectMfaResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

/** Autogenerated return type of RejectMfaReset. */
export type RejectMfaResetPayload = {
  __typename: 'RejectMfaResetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RemoveNodeFromFolder */
export type RemoveNodeFromFolderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  folderId: Scalars['ID']['input'];
  folderableId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveNodeFromFolder. */
export type RemoveNodeFromFolderPayload = {
  __typename: 'RemoveNodeFromFolderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RequestMfaReset */
export type RequestMfaResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of RequestMfaReset. */
export type RequestMfaResetPayload = {
  __typename: 'RequestMfaResetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

export type Role = {
  __typename: 'Role';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  resolvedPrivileges: Array<BluebellPrivilege>;
  settings: RoleSettings;
};

export type RoleSettings = {
  __typename: 'RoleSettings';
  privileges: Array<BluebellPrivilege>;
};

/** Attributes for the 'settings' field on Role objects */
export type RoleSettingsInput = {
  /** List of privileges granted to this role */
  privileges: Array<BluebellPrivilege>;
};

/** Autogenerated input type of SetPeopleListMutability */
export type SetPeopleListMutabilityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mutability: PeopleListMutability;
  peopleListId: Scalars['ID']['input'];
};

/** Autogenerated return type of SetPeopleListMutability. */
export type SetPeopleListMutabilityPayload = {
  __typename: 'SetPeopleListMutabilityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  peopleList: Maybe<PeopleList>;
  success: Scalars['Boolean']['output'];
};

export type ShareRule = {
  __typename: 'ShareRule';
  id: Scalars['ID']['output'];
  permission: BluebellShareLevel;
  user: User;
  userId: Scalars['ID']['output'];
};

/** Attributes for creating or updating Share Rule objects */
export type ShareRuleInput = {
  /** The permission to grant to the target user */
  permission: BluebellShareLevel;
  /** The ID of the user to receive the grant */
  userId: Scalars['ID']['input'];
};

export type ShareSettings = {
  orgShareLevel: BluebellShareLevel;
  shareRules: Maybe<Array<ShareRule>>;
};

/** Autogenerated input type of StartSurveyRun */
export type StartSurveyRunInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SurveyRun ID to be fielded by a vendor, this is a long running proccess which will be polled until completion */
  surveyRunId: Scalars['ID']['input'];
};

/** Autogenerated return type of StartSurveyRun. */
export type StartSurveyRunPayload = {
  __typename: 'StartSurveyRunPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

export type StatisticValue = {
  __typename: 'StatisticValue';
  statistic: Scalars['String']['output'];
  value: Maybe<Scalars['String']['output']>;
};

export type SurveyRun = {
  __typename: 'SurveyRun';
  currentCompletes: Scalars['Int']['output'];
  endedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastPolledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  name: Scalars['String']['output'];
  startedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  state: SurveyRunState;
  surveyRunQuestions: Array<SurveyRunQuestion>;
  targetCompletes: Scalars['Int']['output'];
  vendorSurvey: Maybe<AlchemerSurvey>;
};

/** The connection type for SurveyRun. */
export type SurveyRunConnection = {
  __typename: 'SurveyRunConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SurveyRunEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<SurveyRun>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SurveyRunEdge = {
  __typename: 'SurveyRunEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<SurveyRun>;
};

export type SurveyRunQuestion = {
  __typename: 'SurveyRunQuestion';
  filterFields: SurveyRunQuestionFilterFields;
  geoScores: Array<GeoScore>;
  globalQuestion: GlobalQuestion;
  id: Scalars['ID']['output'];
  subgroupScores: Array<SurveyRunQuestionSubgroupScore>;
  universeScores: Array<SurveyRunQuestionUniverseScore>;
  userQuestion: UserQuestion;
  vendorQuestion: Maybe<AlchemerSurveyQuestion>;
};

export type SurveyRunQuestiongeoScoresArgs = {
  filter?: InputMaybe<SurveyRunQuestionGeoScoreInput>;
};

export type SurveyRunQuestionsubgroupScoresArgs = {
  filter?: InputMaybe<SurveyRunQuestionSubgroupScoreInput>;
};

export type SurveyRunQuestionuniverseScoresArgs = {
  filter?: InputMaybe<SurveyRunQuestionUniverseScoreInput>;
};

export type SurveyRunQuestionFilter = {
  __typename: 'SurveyRunQuestionFilter';
  label: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  value: Maybe<Scalars['String']['output']>;
};

export type SurveyRunQuestionFilterFields = {
  __typename: 'SurveyRunQuestionFilterFields';
  filters: Array<SurveyRunQuestionFilter>;
  localities: Array<SurveyRunQuestionFilter>;
};

export type SurveyRunQuestionGeoScoreInput = {
  filterType?: InputMaybe<Scalars['String']['input']>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
  localityType?: InputMaybe<Scalars['String']['input']>;
};

export type SurveyRunQuestionSubgroupScore = {
  __typename: 'SurveyRunQuestionSubgroupScore';
  modelScore: Scalars['Float']['output'];
  xFilterValue: Scalars['String']['output'];
  yFilterValue: Maybe<Scalars['String']['output']>;
};

export type SurveyRunQuestionSubgroupScoreInput = {
  localityType?: InputMaybe<Scalars['String']['input']>;
  localityValue?: InputMaybe<Scalars['String']['input']>;
  xFilterType?: InputMaybe<Scalars['String']['input']>;
  yFilterType?: InputMaybe<Scalars['String']['input']>;
};

export type SurveyRunQuestionUniverseScore = {
  __typename: 'SurveyRunQuestionUniverseScore';
  decileSum: Scalars['Int']['output'];
  filterType: Scalars['String']['output'];
  filterValue: Scalars['String']['output'];
};

export enum SurveyRunQuestionUniverseScoreDecile {
  /** enum representing decile range score_decile_0_1 */
  SCORE_DECILE_0_1 = 'SCORE_DECILE_0_1',
  /** enum representing decile range score_decile_1_2 */
  SCORE_DECILE_1_2 = 'SCORE_DECILE_1_2',
  /** enum representing decile range score_decile_2_3 */
  SCORE_DECILE_2_3 = 'SCORE_DECILE_2_3',
  /** enum representing decile range score_decile_3_4 */
  SCORE_DECILE_3_4 = 'SCORE_DECILE_3_4',
  /** enum representing decile range score_decile_4_5 */
  SCORE_DECILE_4_5 = 'SCORE_DECILE_4_5',
  /** enum representing decile range score_decile_5_6 */
  SCORE_DECILE_5_6 = 'SCORE_DECILE_5_6',
  /** enum representing decile range score_decile_6_7 */
  SCORE_DECILE_6_7 = 'SCORE_DECILE_6_7',
  /** enum representing decile range score_decile_7_8 */
  SCORE_DECILE_7_8 = 'SCORE_DECILE_7_8',
  /** enum representing decile range score_decile_8_9 */
  SCORE_DECILE_8_9 = 'SCORE_DECILE_8_9',
  /** enum representing decile range score_decile_9_10 */
  SCORE_DECILE_9_10 = 'SCORE_DECILE_9_10'
}

export type SurveyRunQuestionUniverseScoreInput = {
  deciles?: InputMaybe<Array<SurveyRunQuestionUniverseScoreDecile>>;
  filterTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  localityType?: InputMaybe<Scalars['String']['input']>;
  localityValue?: InputMaybe<Scalars['String']['input']>;
};

export enum SurveyRunState {
  complete = 'complete',
  creating_geo = 'creating_geo',
  creating_subgroup = 'creating_subgroup',
  creating_universe = 'creating_universe',
  enriching = 'enriching',
  failed = 'failed',
  fielding = 'fielding',
  matching = 'matching',
  modeling = 'modeling',
  pending = 'pending'
}

/** Autogenerated input type of SyncSurveyRun */
export type SyncSurveyRunInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SurveyRun ID used to generate a vendor survey and vendor survey questions */
  surveyRunId: Scalars['ID']['input'];
};

/** Autogenerated return type of SyncSurveyRun. */
export type SyncSurveyRunPayload = {
  __typename: 'SyncSurveyRunPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  success: Scalars['Boolean']['output'];
};

export type Tag = {
  __typename: 'Tag';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  taggable: Taggable;
  value: Scalars['String']['output'];
};

/** The connection type for Tag. */
export type TagConnection = {
  __typename: 'TagConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<TagEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Tag>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TagEdge = {
  __typename: 'TagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Tag>;
};

/** Objects which can be tagged. */
export type Taggable = PeopleList;

/** Autogenerated input type of UnarchivePeopleList */
export type UnarchivePeopleListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  peopleListId: Scalars['ID']['input'];
};

/** Autogenerated return type of UnarchivePeopleList. */
export type UnarchivePeopleListPayload = {
  __typename: 'UnarchivePeopleListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  peopleList: Maybe<PeopleList>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateDataObjectBreakdown */
export type UpdateDataObjectBreakdownInput = {
  /** ID of the Breakdown object to be updated */
  breakdownId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pivotColumnIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  pivotRowIds: Array<Scalars['ID']['input']>;
  statistics?: InputMaybe<Array<BluebellStatistic>>;
};

/** Autogenerated return type of UpdateDataObjectBreakdown. */
export type UpdateDataObjectBreakdownPayload = {
  __typename: 'UpdateDataObjectBreakdownPayload';
  breakdown: Maybe<DataObjectBreakdown>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
};

/** Autogenerated input type of UpdateGlobalQuestion */
export type UpdateGlobalQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  globalQuestionId: Scalars['ID']['input'];
  options?: InputMaybe<Array<QuestionOptionsInput>>;
  questionTextEn: Scalars['String']['input'];
  questionType: QuestionTypes;
};

/** Autogenerated return type of UpdateGlobalQuestion. */
export type UpdateGlobalQuestionPayload = {
  __typename: 'UpdateGlobalQuestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  globalQuestion: Maybe<GlobalQuestion>;
};

/** Autogenerated input type of UpdateNodeShareSettings */
export type UpdateNodeShareSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orgShareLevel: BluebellShareLevel;
  recursive?: InputMaybe<Scalars['Boolean']['input']>;
  shareRules?: InputMaybe<Array<ShareRuleInput>>;
  shareableId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateNodeShareSettings. */
export type UpdateNodeShareSettingsPayload = {
  __typename: 'UpdateNodeShareSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  orgShareLevel: Maybe<BluebellShareLevel>;
  shareRules: Maybe<Array<ShareRule>>;
};

/** Autogenerated input type of UpdateOrganizationSettings */
export type UpdateOrganizationSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  settings: OrganizationSettingsInput;
};

/** Autogenerated return type of UpdateOrganizationSettings. */
export type UpdateOrganizationSettingsPayload = {
  __typename: 'UpdateOrganizationSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  settings: Maybe<OrganizationSettings>;
};

/** Autogenerated input type of UpdatePeopleList */
export type UpdatePeopleListInput = {
  buildDefinition: PeopleListBuildDefinitionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  peopleListId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePeopleList. */
export type UpdatePeopleListPayload = {
  __typename: 'UpdatePeopleListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  peopleList: Maybe<PeopleList>;
};

/** Autogenerated input type of UpdateQuestionGroup */
export type UpdateQuestionGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  questionGroupId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateQuestionGroup. */
export type UpdateQuestionGroupPayload = {
  __typename: 'UpdateQuestionGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  questionGroup: Maybe<QuestionGroup>;
};

/** Autogenerated input type of UpdateRole */
export type UpdateRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  roleId: Scalars['ID']['input'];
  settings: RoleSettingsInput;
};

/** Autogenerated return type of UpdateRole. */
export type UpdateRolePayload = {
  __typename: 'UpdateRolePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  role: Maybe<Role>;
};

/** Autogenerated input type of UpdateSurveyRun */
export type UpdateSurveyRunInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  surveyRunId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateSurveyRun. */
export type UpdateSurveyRunPayload = {
  __typename: 'UpdateSurveyRunPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  surveyRun: Maybe<SurveyRun>;
};

/** Autogenerated input type of UpdateTag */
export type UpdateTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  tagId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateTag. */
export type UpdateTagPayload = {
  __typename: 'UpdateTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  tag: Maybe<Tag>;
};

/** Autogenerated input type of UpdateUserProfile */
export type UpdateUserProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateUserProfile. */
export type UpdateUserProfilePayload = {
  __typename: 'UpdateUserProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  user: Maybe<User>;
};

/** Autogenerated input type of UpdateUserQuestion */
export type UpdateUserQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<QuestionOptionsInput>>;
  questionTextEn: Scalars['String']['input'];
  questionType: QuestionTypes;
  userQuestionId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateUserQuestion. */
export type UpdateUserQuestionPayload = {
  __typename: 'UpdateUserQuestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  userQuestion: Maybe<UserQuestion>;
};

/** Autogenerated input type of UpdateUserRole */
export type UpdateUserRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateUserRole. */
export type UpdateUserRolePayload = {
  __typename: 'UpdateUserRolePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<MutationError>;
  userRole: Maybe<UserRole>;
};

export type UploadedFile = ShareSettings & {
  __typename: 'UploadedFile';
  contentMeta: Scalars['JSON']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  creator: User;
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgShareLevel: BluebellShareLevel;
  originalFilename: Scalars['String']['output'];
  shareRules: Maybe<Array<ShareRule>>;
  size: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for UploadedFile. */
export type UploadedFileConnection = {
  __typename: 'UploadedFileConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UploadedFileEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UploadedFile>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UploadedFileEdge = {
  __typename: 'UploadedFileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UploadedFile>;
};

export type User = {
  __typename: 'User';
  apiKeys: Array<ApiKey>;
  email: Scalars['Email']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  organizationInvites: Array<OrganizationInvite>;
  role: Maybe<Role>;
  superadmin: Scalars['Boolean']['output'];
  /** The collection of privileges active for the user in the current organization context. */
  userPrivileges: Maybe<Array<BluebellPrivilege>>;
};

export type UserQuestion = {
  __typename: 'UserQuestion';
  creator: User;
  globalQuestion: Maybe<GlobalQuestion>;
  id: Scalars['ID']['output'];
  options: Maybe<Array<QuestionOptions>>;
  questionTextEn: Scalars['String']['output'];
  questionType: Scalars['String']['output'];
};

export type UserRole = {
  __typename: 'UserRole';
  id: Scalars['ID']['output'];
  role: Role;
  roleId: Scalars['ID']['output'];
  user: User;
  userId: Scalars['ID']['output'];
};

export type ApproveMfaResetVariables = Exact<{
  input: ApproveMfaResetInput;
}>;

export type ApproveMfaReset = {
  approveMfaReset:
    | ({
        success: boolean;
        password: string | null;
        errors: Array<ErrorFragment & { __typename: 'MutationError' }>;
      } & { __typename: 'ApproveMfaResetPayload' })
    | null;
};

export type RejectMfaResetVariables = Exact<{
  input: RejectMfaResetInput;
}>;

export type RejectMfaReset = {
  rejectMfaReset:
    | ({ success: boolean; errors: Array<ErrorFragment & { __typename: 'MutationError' }> } & {
        __typename: 'RejectMfaResetPayload';
      })
    | null;
};

export type RequestMfaResetVariables = Exact<{
  input: RequestMfaResetInput;
}>;

export type RequestMfaReset = {
  requestMfaReset:
    | ({ success: boolean; errors: Array<ErrorFragment & { __typename: 'MutationError' }> } & {
        __typename: 'RequestMfaResetPayload';
      })
    | null;
};

export type DataObjectFieldContextMetaData = {
  categories: Array<DataObjectSchemaFieldCategory>;
  required: DataObjectSchemaFieldContextRequired;
} & { __typename: 'DataObjectSchemaFieldContextMetadata' };

export type DataObjectSchemaFieldMeta = {
  id: string;
  name: string;
  category: DataObjectSchemaFieldCategory;
  description: string;
  dataType: DataObjectSchemaFieldData;
} & { __typename: 'DataObjectSchemaField' };

export type DataObjectFieldValidValues = {
  moreContextRequired: boolean;
  validValues: Array<string> | null;
  operators: Array<FieldComparisonOperator>;
  contextMetadata: DataObjectFieldContextMetaData & {
    __typename: 'DataObjectSchemaFieldContextMetadata';
  };
} & { __typename: 'DataObjectSchemaFieldValidValues' };

export type GetDataObjectByDataSourceIdsVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  dataSourceIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type GetDataObjectByDataSourceIds = {
  dataObjects: {
    edges: Array<
      | ({
          cursor: string;
          node:
            | ({
                id: string;
                name: string;
                createdAt: any;
                updatedAt: any;
                dataSource: { id: string; name: string } & { __typename: 'DataSource' };
                schema: {
                  fields: Array<
                    DataObjectSchemaFieldMeta & { __typename: 'DataObjectSchemaField' }
                  >;
                } & { __typename: 'DataObjectSchema' };
              } & { __typename: 'DataObject' })
            | null;
        } & { __typename: 'DataObjectEdge' })
      | null
    > | null;
    pageInfo: { hasNextPage: boolean } & { __typename: 'PageInfo' };
  } & { __typename: 'DataObjectConnection' };
};

export type GetDataObjectByDataSourceIdsBaseDataVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  dataSourceIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type GetDataObjectByDataSourceIdsBaseData = {
  dataObjects: {
    nodes: Array<
      | ({
          id: string;
          name: string;
          dataSource: { id: string; name: string } & { __typename: 'DataSource' };
          schema: {
            fields: Array<DataObjectSchemaFieldMeta & { __typename: 'DataObjectSchemaField' }>;
          } & { __typename: 'DataObjectSchema' };
        } & { __typename: 'DataObject' })
      | null
    > | null;
  } & { __typename: 'DataObjectConnection' };
};

export type GetDataObjectFieldCategoriesVariables = Exact<{ [key: string]: never }>;

export type GetDataObjectFieldCategories = {
  dataObjectFieldCategories: Array<DataObjectSchemaFieldCategory>;
};

export type GetValidValuesVariables = Exact<{
  id: Scalars['ID']['input'];
  relatedFields?: InputMaybe<Array<PeopleListBuildStepFieldInput> | PeopleListBuildStepFieldInput>;
  fieldCombineOperator: FieldCombineOperator;
  maxCardinality?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetValidValues = {
  dataObjectFieldValidValues: DataObjectFieldValidValues & {
    __typename: 'DataObjectSchemaFieldValidValues';
  };
};

export type DataSourceMetaFragment = {
  id: string;
  name: string;
  createdAt: any;
  status: string;
  statusDetails: any;
  updatedAt: any;
  creator: { id: string } & UserNameFragment & { __typename: 'User' };
} & { __typename: 'DataSource' };

export type DataSourceFragment = {
  backendType: DataSourceBackend;
  backendDetails:
    | ({
        backendType: DataSourceBackend;
        host: string;
        port: number;
        dbname: string;
        username: string;
      } & { __typename: 'DataSourcePostgresBackendDetails' })
    | ({ backendType: DataSourceBackend; bucket: string; region: string; prefix: string | null } & {
        __typename: 'DataSourceS3BackendDetails';
      })
    | { __typename: 'DataSourceSystemBackendDetails' };
} & DataSourceMetaFragment & { __typename: 'DataSource' };

export type GetDataSourceByIdVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDataSourceById = { dataSource: DataSourceFragment & { __typename: 'DataSource' } };

export type GetDataSourcesVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetDataSources = {
  dataSources: {
    edges: Array<
      | ({
          cursor: string;
          node: (DataSourceMetaFragment & { __typename: 'DataSource' }) | null;
        } & { __typename: 'DataSourceEdge' })
      | null
    > | null;
    pageInfo: PageInfoFragment & { __typename: 'PageInfo' };
  } & { __typename: 'DataSourceConnection' };
};

export type GetDataSourcesBaseDataVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetDataSourcesBaseData = {
  dataSources: {
    nodes: Array<({ id: string; name: string } & { __typename: 'DataSource' }) | null> | null;
  } & { __typename: 'DataSourceConnection' };
};

export type FolderDetails = {
  id: string;
  name: string;
  orgShareLevel: BluebellShareLevel;
  shareRules: Array<
    { permission: BluebellShareLevel; user: Creator & { __typename: 'User' } } & {
      __typename: 'ShareRule';
    }
  > | null;
  contents: Array<
    {
      id: string;
      folderable: {
        id: string;
        name: string;
        state: PeopleListState;
        mutability: PeopleListMutability;
        orgShareLevel: BluebellShareLevel;
        createdAt: any;
        updatedAt: any;
        creator: Creator & { __typename: 'User' };
        nodeUserPreferences: ({ favorite: boolean } & { __typename: 'NodeUserPreferences' }) | null;
      } & ShareRules & { __typename: 'PeopleList' };
    } & { __typename: 'FolderContent' }
  >;
} & { __typename: 'Folder' };

export type AssignToFolderVariables = Exact<{
  input: AssignNodeToFolderInput;
}>;

export type AssignToFolder = {
  assignNodeToFolder:
    | ({ folder: (FolderDetails & { __typename: 'Folder' }) | null } & {
        __typename: 'AssignNodeToFolderPayload';
      })
    | null;
};

export type CreateFolderVariables = Exact<{
  input: CreateFolderInput;
}>;

export type CreateFolder = {
  createFolder:
    | ({
        folder: (FolderDetails & { __typename: 'Folder' }) | null;
        errors: Array<ErrorFragment & { __typename: 'MutationError' }>;
      } & { __typename: 'CreateFolderPayload' })
    | null;
};

export type RemoveFromFolderVariables = Exact<{
  input: RemoveNodeFromFolderInput;
}>;

export type RemoveFromFolder = {
  removeNodeFromFolder:
    | ({ success: boolean; errors: Array<ErrorFragment & { __typename: 'MutationError' }> } & {
        __typename: 'RemoveNodeFromFolderPayload';
      })
    | null;
};

export type GetFolderVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type GetFolder = { folder: FolderDetails & { __typename: 'Folder' } };

export type GetFoldersVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetFolders = {
  folders: {
    nodes: Array<(FolderDetails & { __typename: 'Folder' }) | null> | null;
    pageInfo: PageInfoFragment & { __typename: 'PageInfo' };
  } & { __typename: 'FolderConnection' };
};

export type BreakdownsFieldFragment = {
  id: string;
  createdAt: any;
  updatedAt: any;
  position: number;
  dataObjectBreakdown: {
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    statistics: Array<string>;
    schemaFields: Array<DataObjectSchemaFieldMeta & { __typename: 'DataObjectSchemaField' }>;
    settings: { pivotColumnIds: Array<string>; pivotRowIds: Array<string> } & {
      __typename: 'DataObjectBreakdownSettings';
    };
  } & { __typename: 'DataObjectBreakdown' };
  dataObjectSchemaField: DataObjectSchemaFieldMeta & { __typename: 'DataObjectSchemaField' };
} & { __typename: 'DataObjectBreakdownField' };

export type BreakdownFragment = {
  id: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  statistics: Array<string>;
  breakdownFields: Array<BreakdownsFieldFragment & { __typename: 'DataObjectBreakdownField' }>;
  schemaFields: Array<DataObjectSchemaFieldMeta & { __typename: 'DataObjectSchemaField' }>;
  settings: { pivotColumnIds: Array<string>; pivotRowIds: Array<string> } & {
    __typename: 'DataObjectBreakdownSettings';
  };
} & { __typename: 'DataObjectBreakdown' };

export type ErrorFragment = { path: Array<string>; message: string } & {
  __typename: 'MutationError';
};

export type PageInfoFragment = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
} & { __typename: 'PageInfo' };

export type PeopleListStateFragment = { id: string; state: PeopleListState } & {
  __typename: 'PeopleList';
};

export type AcceptOrgInviteVariables = Exact<{
  input: AcceptOrganizationInviteInput;
}>;

export type AcceptOrgInvite = {
  acceptOrganizationInvite:
    | ({ success: boolean; errors: Array<ErrorFragment & { __typename: 'MutationError' }> } & {
        __typename: 'AcceptOrganizationInvitePayload';
      })
    | null;
};

export type DeclineOrgInviteVariables = Exact<{
  input: DeclineOrganizationInviteInput;
}>;

export type DeclineOrgInvite = {
  declineOrganizationInvite:
    | ({ success: boolean; errors: Array<ErrorFragment & { __typename: 'MutationError' }> } & {
        __typename: 'DeclineOrganizationInvitePayload';
      })
    | null;
};

export type DeleteOrganizationInviteVariables = Exact<{
  input: DeleteOrganizationInviteInput;
}>;

export type DeleteOrganizationInvite = {
  deleteOrganizationInvite:
    | ({ success: boolean; errors: Array<ErrorFragment & { __typename: 'MutationError' }> } & {
        __typename: 'DeleteOrganizationInvitePayload';
      })
    | null;
};

export type GetOrgInviteByIdVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOrgInviteById = {
  organizationInvite: {
    id: string;
    organization: { id: string; name: string } & { __typename: 'Organization' };
  } & { __typename: 'OrganizationInvite' };
};

export type PeopleListMeta = {
  id: string;
  name: string;
  state: PeopleListState;
  mutability: PeopleListMutability;
  createdAt: any;
  updatedAt: any;
  orgShareLevel: BluebellShareLevel;
} & { __typename: 'PeopleList' };

export type BuildDefinitionStepMeta = {
  id: string;
  name: string;
  action: PeopleListBuildStepAction;
  fieldCombineOperator: FieldCombineOperator;
} & { __typename: 'PeopleListBuildStep' };

export type RangeFragment = { min: string | null; max: string | null } & { __typename: 'Range' };

export type FieldMeta = {
  id: string;
  comparisonOperator: FieldComparisonOperator;
  inputValue: Array<string | null> | null;
  peopleListBuildStepId: string;
  dataObjectSchemaField: DataObjectSchemaFieldMeta & { __typename: 'DataObjectSchemaField' };
  distance:
    | ({ googlePlaceId: string; lat: number; long: number; distance: number } & {
        __typename: 'DistanceSearch';
      })
    | null;
  range: (RangeFragment & { __typename: 'Range' }) | null;
} & { __typename: 'PeopleListBuildStepField' };

export type DownloadUrlProgress = {
  complete: boolean;
  currentStepIndex: number;
  message: string;
  steps: Array<string>;
  success: boolean;
} & { __typename: 'ProgressBar' };

export type DownloadUrlDetails = { expiration: any | null; ttl: number | null; url: any | null } & {
  __typename: 'ExpiringUrl';
};

export type BreakdownTable = {
  columnLabels: Array<string>;
  columns: Array<
    {
      label: string;
      aggregates: Array<
        ({ statistic: string; value: string | null } & { __typename: 'StatisticValue' }) | null
      >;
    } & { __typename: 'PivotTableColumn' }
  >;
  rows: Array<
    {
      rowLabels: Array<string>;
      points: Array<
        {
          fieldValues: Array<
            {
              value: string | null;
              field: { id: string; name: string } & { __typename: 'DataObjectSchemaField' };
            } & { __typename: 'DataObjectSchemaFieldValue' }
          >;
          statisticValues: Array<
            { statistic: string; value: string | null } & { __typename: 'StatisticValue' }
          >;
        } & { __typename: 'PivotTablePoint' }
      >;
      aggregates: Array<
        { statistic: string; value: string | null } & { __typename: 'StatisticValue' }
      > | null;
    } & { __typename: 'PivotTableRow' }
  >;
} & { __typename: 'PivotTable' };

export type ShareRules = {
  shareRules: Array<
    { permission: BluebellShareLevel; user: Creator & { __typename: 'User' } } & {
      __typename: 'ShareRule';
    }
  > | null;
} & { __typename: 'PeopleList' };

export type PeopleListUserPreferences = { favorite: boolean } & {
  __typename: 'NodeUserPreferences';
};

export type PeopleListSummaryStatsFragment = {
  summaryStats: Array<
    | ({ description: string; label: string; value: number } & {
        __typename: 'PeopleListSummaryStats';
      })
    | null
  >;
} & { __typename: 'PeopleList' };

export type PeopleListHydratorRunDetails = {
  id: string;
  columns: Array<string>;
  completedAt: any | null;
  estimatedCost: number;
  state: PeopleListHydratorRunState;
  matchedCount: number | null;
  creator: Creator & { __typename: 'User' };
  downloadUrl: ({ url: any | null } & { __typename: 'ExpiringUrl' }) | null;
} & { __typename: 'PeopleListHydratorRun' };

export type ApproveHydratorRunVariables = Exact<{
  input: ApprovePeopleListHydratorRunInput;
}>;

export type ApproveHydratorRun = {
  approvePeopleListHydratorRun:
    | ({ success: boolean; errors: Array<ErrorFragment & { __typename: 'MutationError' }> } & {
        __typename: 'ApprovePeopleListHydratorRunPayload';
      })
    | null;
};

export type ArchivePeopleListVariables = Exact<{
  input: ArchivePeopleListInput;
}>;

export type ArchivePeopleList = {
  archivePeopleList:
    | ({
        success: boolean;
        errors: Array<ErrorFragment & { __typename: 'MutationError' }>;
        peopleList: (PeopleListStateFragment & { __typename: 'PeopleList' }) | null;
      } & { __typename: 'ArchivePeopleListPayload' })
    | null;
};

export type ClonePeopleListVariables = Exact<{
  input: ClonePeopleListInput;
}>;

export type ClonePeopleList = {
  clonePeopleList:
    | ({
        peopleList: ({ id: string } & { __typename: 'PeopleList' }) | null;
        errors: Array<ErrorFragment & { __typename: 'MutationError' }>;
      } & { __typename: 'ClonePeopleListPayload' })
    | null;
};

export type CreateDataObjectBreakdownVariables = Exact<{
  input: CreateDataObjectBreakdownInput;
}>;

export type CreateDataObjectBreakdown = {
  createDataObjectBreakdown:
    | ({
        breakdown: (BreakdownFragment & { __typename: 'DataObjectBreakdown' }) | null;
        errors: Array<ErrorFragment & { __typename: 'MutationError' }>;
      } & { __typename: 'CreateDataObjectBreakdownPayload' })
    | null;
};

export type CreateFavoriteVariables = Exact<{
  input: CreateFavoriteInput;
}>;

export type CreateFavorite = {
  createFavorite:
    | ({ success: boolean; errors: Array<ErrorFragment & { __typename: 'MutationError' }> } & {
        __typename: 'CreateFavoritePayload';
      })
    | null;
};

export type CreateHydratorRunVariables = Exact<{
  input: CreatePeopleListHydratorRunInput;
}>;

export type CreateHydratorRun = {
  createPeopleListHydratorRun:
    | ({
        peopleListHydratorRun:
          | (PeopleListHydratorRunDetails & { __typename: 'PeopleListHydratorRun' })
          | null;
        errors: Array<ErrorFragment & { __typename: 'MutationError' }>;
      } & { __typename: 'CreatePeopleListHydratorRunPayload' })
    | null;
};

export type CreatePeopleListVariables = Exact<{
  input: CreatePeopleListInput;
}>;

export type CreatePeopleList = {
  createPeopleList:
    | ({
        peopleList: ({ id: string } & { __typename: 'PeopleList' }) | null;
        errors: Array<{ message: string; path: Array<string> } & { __typename: 'MutationError' }>;
      } & { __typename: 'CreatePeopleListPayload' })
    | null;
};

export type CreatePeopleListDownloadUrlVariables = Exact<{
  input: CreatePeopleListDownloadUrlInput;
}>;

export type CreatePeopleListDownloadUrl = {
  createPeopleListDownloadUrl:
    | ({
        urlDetails: (DownloadUrlDetails & { __typename: 'ExpiringUrl' }) | null;
        progress: (DownloadUrlProgress & { __typename: 'ProgressBar' }) | null;
        errors: Array<ErrorFragment & { __typename: 'MutationError' }>;
      } & { __typename: 'CreatePeopleListDownloadUrlPayload' })
    | null;
};

export type DeleteFavoriteVariables = Exact<{
  input: DeleteFavoriteInput;
}>;

export type DeleteFavorite = {
  deleteFavorite:
    | ({ success: boolean; errors: Array<ErrorFragment & { __typename: 'MutationError' }> } & {
        __typename: 'DeleteFavoritePayload';
      })
    | null;
};

export type DeleteHydratorRunVariables = Exact<{
  input: DeletePeopleListHydratorRunInput;
}>;

export type DeleteHydratorRun = {
  deletePeopleListHydratorRun:
    | ({ success: boolean; errors: Array<ErrorFragment & { __typename: 'MutationError' }> } & {
        __typename: 'DeletePeopleListHydratorRunPayload';
      })
    | null;
};

export type UnarchivePeopleListVariables = Exact<{
  input: UnarchivePeopleListInput;
}>;

export type UnarchivePeopleList = {
  unarchivePeopleList:
    | ({
        success: boolean;
        errors: Array<ErrorFragment & { __typename: 'MutationError' }>;
        peopleList: (PeopleListStateFragment & { __typename: 'PeopleList' }) | null;
      } & { __typename: 'UnarchivePeopleListPayload' })
    | null;
};

export type UpdatePeopleListVariables = Exact<{
  input: UpdatePeopleListInput;
}>;

export type UpdatePeopleList = {
  updatePeopleList:
    | ({
        peopleList:
          | ({
              buildDefinition: {
                steps: Array<
                  {
                    fields: Array<FieldMeta & { __typename: 'PeopleListBuildStepField' }>;
                  } & BuildDefinitionStepMeta & { __typename: 'PeopleListBuildStep' }
                >;
              } & { __typename: 'PeopleListBuildDefinition' };
            } & PeopleListMeta & { __typename: 'PeopleList' })
          | null;
        errors: Array<{ message: string; path: Array<string> } & { __typename: 'MutationError' }>;
      } & { __typename: 'UpdatePeopleListPayload' })
    | null;
};

export type GetDataObjectBreakdownComputedPreviewVariables = Exact<{
  peopleListId: Scalars['ID']['input'];
  breakdownId?: InputMaybe<Scalars['ID']['input']>;
  breakdownDefinition?: InputMaybe<DataObjectBreakdownDefinitionInput>;
}>;

export type GetDataObjectBreakdownComputedPreview = {
  computeDataObjectBreakdownPreview: {
    pivotTable: BreakdownTable & { __typename: 'PivotTable' };
  } & { __typename: 'ComputedBreakdownPreview' };
};

export type GetDataObjectsForBreakdownsVariables = Exact<{
  peopleListId: Scalars['ID']['input'];
}>;

export type GetDataObjectsForBreakdowns = {
  dataObjectsForBreakdowns: {
    edges: Array<
      | ({
          cursor: string;
          node:
            | ({ id: string; dataSource: { name: string } & { __typename: 'DataSource' } } & {
                __typename: 'DataObject';
              })
            | null;
        } & { __typename: 'DataObjectEdge' })
      | null
    > | null;
    nodes: Array<
      | ({
          id: string;
          name: string;
          dataSource: { id: string; name: string } & { __typename: 'DataSource' };
          schema: {
            fields: Array<DataObjectSchemaFieldMeta & { __typename: 'DataObjectSchemaField' }>;
          } & { __typename: 'DataObjectSchema' };
        } & { __typename: 'DataObject' })
      | null
    > | null;
  } & { __typename: 'DataObjectConnection' };
};

export type GetPeopleListBreakdownsByIdVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPeopleListBreakdownsById = {
  peopleList: {
    id: string;
    breakdowns: Array<
      | ({
          pivotTable: BreakdownTable & { __typename: 'PivotTable' };
          dataObjectBreakdown: BreakdownFragment & { __typename: 'DataObjectBreakdown' };
        } & { __typename: 'ComputedBreakdown' })
      | null
    >;
  } & { __typename: 'PeopleList' };
};

export type GetPeopleListByIdVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPeopleListById = {
  peopleList: {
    state: PeopleListState;
    dataObjectId: string;
    creator: Creator & { __typename: 'User' };
    nodeUserPreferences: ({ favorite: boolean } & { __typename: 'NodeUserPreferences' }) | null;
    buildDefinition: {
      steps: Array<
        {
          fields: Array<FieldMeta & { __typename: 'PeopleListBuildStepField' }>;
        } & BuildDefinitionStepMeta & { __typename: 'PeopleListBuildStep' }
      >;
    } & { __typename: 'PeopleListBuildDefinition' };
    downloadUrl:
      | ({
          progress: DownloadUrlProgress & { __typename: 'ProgressBar' };
          urlDetails: (DownloadUrlDetails & { __typename: 'ExpiringUrl' }) | null;
        } & { __typename: 'GeneratedUrl' })
      | null;
    dataSources: Array<{ id: string } & { __typename: 'DataSource' }>;
  } & PeopleListMeta &
    ShareRules & { __typename: 'PeopleList' };
};

export type GetPeopleListDownloadUrlVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPeopleListDownloadUrl = {
  peopleList: {
    id: string;
    downloadUrl:
      | ({
          progress: DownloadUrlProgress & { __typename: 'ProgressBar' };
          urlDetails: (DownloadUrlDetails & { __typename: 'ExpiringUrl' }) | null;
        } & { __typename: 'GeneratedUrl' })
      | null;
  } & { __typename: 'PeopleList' };
};

export type GetPeopleListHydrationRunsVariables = Exact<{
  id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPeopleListHydrationRuns = {
  peopleList: {
    id: string;
    name: string;
    peopleListHydratorRuns:
      | ({
          edges: Array<
            | ({
                cursor: string;
                node:
                  | (PeopleListHydratorRunDetails & { __typename: 'PeopleListHydratorRun' })
                  | null;
              } & { __typename: 'PeopleListHydratorRunEdge' })
            | null
          > | null;
          pageInfo: PageInfoFragment & { __typename: 'PageInfo' };
        } & { __typename: 'PeopleListHydratorRunConnection' })
      | null;
  } & { __typename: 'PeopleList' };
};

export type GetPeopleListHydratorRunByIdVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPeopleListHydratorRunById = {
  peopleListHydratorRun: {
    id: string;
    columns: Array<string>;
    completedAt: any | null;
    createdAt: any;
    estimatedCost: number;
    matchedCount: number | null;
    state: PeopleListHydratorRunState;
    creator: { email: any; firstName: string; id: string; lastName: string } & {
      __typename: 'User';
    };
    downloadUrl:
      | ({ expiration: any | null; ttl: number | null; url: any | null } & {
          __typename: 'ExpiringUrl';
        })
      | null;
  } & { __typename: 'PeopleListHydratorRun' };
};

export type GetPeopleListStatusVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPeopleListStatus = {
  peopleList: { id: string; state: PeopleListState } & { __typename: 'PeopleList' };
};

export type GetPeopleListSummaryStatsVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPeopleListSummaryStats = {
  peopleList: { id: string } & PeopleListSummaryStatsFragment & { __typename: 'PeopleList' };
};

export type GetPeopleListUserPreferencesVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPeopleListUserPreferences = {
  peopleList: {
    id: string;
    nodeUserPreferences: (PeopleListUserPreferences & { __typename: 'NodeUserPreferences' }) | null;
  } & { __typename: 'PeopleList' };
};

export type GetPeopleListsVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filters: PeopleListFilterInput;
}>;

export type GetPeopleLists = {
  peopleLists: {
    edges: Array<
      | ({
          cursor: string;
          node:
            | ({
                creator: Creator & { __typename: 'User' };
                nodeUserPreferences:
                  | ({ favorite: boolean } & { __typename: 'NodeUserPreferences' })
                  | null;
              } & PeopleListMeta &
                ShareRules & { __typename: 'PeopleList' })
            | null;
        } & { __typename: 'PeopleListEdge' })
      | null
    > | null;
    pageInfo: PageInfoFragment & { __typename: 'PageInfo' };
  } & { __typename: 'PeopleListConnection' };
};

export type ValidateListStepVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  definition?: InputMaybe<PeopleListBuildStepInput>;
}>;

export type ValidateListStep = {
  peopleListBuildStepValidator: {
    valid: boolean;
    invalidValues: Array<
      { dataObjectSchemaFieldId: string; message: string; values: Array<string> } & {
        __typename: 'PeopleListBuildStepInvalidValue';
      }
    >;
  } & { __typename: 'PeopleListBuildStepValidator' };
};

export type GetGeoCountsByPeopleListIdVariables = Exact<{
  peopleListId: Scalars['ID']['input'];
  localityType?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetGeoCountsByPeopleListId = {
  geoCounts: {
    localityType: string;
    unit: string;
    scores: Array<{ id: string; label: string; value: number } & { __typename: 'GeoScore' }>;
  } & { __typename: 'GeoScoreMetadata' };
};

export type UpdateUserProfileVariables = Exact<{
  input: UpdateUserProfileInput;
}>;

export type UpdateUserProfile = {
  updateUserProfile:
    | ({
        user: ({ id: string; firstName: string; lastName: string } & { __typename: 'User' }) | null;
      } & { __typename: 'UpdateUserProfilePayload' })
    | null;
};

export type GetMyDetailsVariables = Exact<{ [key: string]: never }>;

export type GetMyDetails = {
  me: {
    id: string;
    superadmin: boolean;
    apiKeys: Array<ApiKeyFragment & { __typename: 'ApiKey' }>;
    organizationInvites: Array<
      {
        id: string;
        state: OrganizationInviteState;
        organization: { id: string; name: string } & { __typename: 'Organization' };
        role: { id: string; name: string } & { __typename: 'Role' };
      } & { __typename: 'OrganizationInvite' }
    >;
  } & UserNameFragment & { __typename: 'User' };
  organizations: Array<OrganizationBaseFields & { __typename: 'Organization' }>;
};

export type GetMyDetailsWithRoleVariables = Exact<{ [key: string]: never }>;

export type GetMyDetailsWithRole = {
  me: {
    id: string;
    superadmin: boolean;
    apiKeys: Array<ApiKeyFragment & { __typename: 'ApiKey' }>;
    organizationInvites: Array<
      {
        id: string;
        state: OrganizationInviteState;
        organization: { id: string; name: string } & { __typename: 'Organization' };
        role: { id: string; name: string } & { __typename: 'Role' };
      } & { __typename: 'OrganizationInvite' }
    >;
    role: (RoleFragment & { __typename: 'Role' }) | null;
  } & UserNameFragment & { __typename: 'User' };
  organizations: Array<OrganizationBaseFields & { __typename: 'Organization' }>;
};

export type GetMyDetailsWithPrivilegesVariables = Exact<{ [key: string]: never }>;

export type GetMyDetailsWithPrivileges = {
  me: {
    id: string;
    email: any;
    superadmin: boolean;
    userPrivileges: Array<BluebellPrivilege> | null;
    apiKeys: Array<ApiKeyFragment & { __typename: 'ApiKey' }>;
    organizationInvites: Array<
      {
        id: string;
        state: OrganizationInviteState;
        organization: { id: string; name: string } & { __typename: 'Organization' };
        role: { id: string; name: string } & { __typename: 'Role' };
      } & { __typename: 'OrganizationInvite' }
    >;
    role: (RoleFragment & { __typename: 'Role' }) | null;
  } & UserNameFragment & { __typename: 'User' };
  organizations: Array<OrganizationBaseFields & { __typename: 'Organization' }>;
};

export type OrganizationBaseFields = {
  id: string;
  name: string;
  settings: {
    modules: Array<BluebellModuleName>;
    defaultShareLevel: BluebellShareLevel;
    hydratorColumns: Array<string>;
  } & { __typename: 'OrganizationSettings' };
} & { __typename: 'Organization' };

export type ApiKeyFragment = {
  id: string;
  exp: any;
  label: string;
  jti: string;
  organization: (OrganizationBaseFields & { __typename: 'Organization' }) | null;
} & { __typename: 'ApiKey' };

export type UpdateOrganizationSettingsVariables = Exact<{
  input: UpdateOrganizationSettingsInput;
}>;

export type UpdateOrganizationSettings = {
  updateOrganizationSettings:
    | ({
        errors: Array<ErrorFragment & { __typename: 'MutationError' }>;
        settings:
          | ({ modules: Array<BluebellModuleName>; defaultShareLevel: BluebellShareLevel } & {
              __typename: 'OrganizationSettings';
            })
          | null;
      } & { __typename: 'UpdateOrganizationSettingsPayload' })
    | null;
};

export type CreateApiKeyVariables = Exact<{
  input: CreateApiKeyInput;
}>;

export type CreateApiKey = {
  createApiKey:
    | ({
        token: string | null;
        apiKey: (ApiKeyFragment & { __typename: 'ApiKey' }) | null;
        errors: Array<ErrorFragment & { __typename: 'MutationError' }>;
      } & { __typename: 'CreateApiKeyPayload' })
    | null;
};

export type DeleteApiKeyVariables = Exact<{
  input: DeleteApiKeyInput;
}>;

export type DeleteApiKey = {
  deleteApiKey:
    | ({ success: boolean; errors: Array<ErrorFragment & { __typename: 'MutationError' }> } & {
        __typename: 'DeleteApiKeyPayload';
      })
    | null;
};

export type DeleteUserFromOrganizationVariables = Exact<{
  input: DeleteUserFromOrganizationInput;
}>;

export type DeleteUserFromOrganization = {
  deleteUserFromOrganization:
    | ({ success: boolean; errors: Array<ErrorFragment & { __typename: 'MutationError' }> } & {
        __typename: 'DeleteUserFromOrganizationPayload';
      })
    | null;
};

export type GetOrganizationUsersVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOrganizationUsers = {
  organization: {
    id: string;
    userRoles: Array<
      {
        id: string;
        role: { id: string; name: string } & { __typename: 'Role' };
        user: {
          id: string;
          apiKeys: Array<ApiKeyFragment & { __typename: 'ApiKey' }>;
        } & UserNameFragment & { __typename: 'User' };
      } & { __typename: 'UserRole' }
    >;
  } & { __typename: 'Organization' };
};

export type GetOrganizationsVariables = Exact<{ [key: string]: never }>;

export type GetOrganizations = {
  organizations: Array<OrganizationBaseFields & { __typename: 'Organization' }>;
};

export type GetPendingOrgInvitesVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPendingOrgInvites = {
  organization: {
    id: string;
    organizationInvites: Array<
      {
        id: string;
        email: any;
        state: OrganizationInviteState;
        creator: { id: string; firstName: string; lastName: string; email: any } & {
          __typename: 'User';
        };
        role: { id: string; name: string } & { __typename: 'Role' };
      } & { __typename: 'OrganizationInvite' }
    >;
  } & { __typename: 'Organization' };
};

export type RoleSettingsFragment = { privileges: Array<BluebellPrivilege> } & {
  __typename: 'RoleSettings';
};

export type RoleFragment = {
  id: string;
  name: string;
  settings: RoleSettingsFragment & { __typename: 'RoleSettings' };
} & { __typename: 'Role' };

export type CreateRoleVariables = Exact<{
  input: CreateRoleInput;
}>;

export type CreateRole = {
  createRole:
    | ({ role: (RoleFragment & { __typename: 'Role' }) | null } & {
        __typename: 'CreateRolePayload';
      })
    | null;
};

export type DeleteRoleVariables = Exact<{
  input: DeleteRoleInput;
}>;

export type DeleteRole = {
  deleteRole:
    | ({
        success: boolean;
        errors: Array<{ message: string; path: Array<string> } & { __typename: 'MutationError' }>;
      } & { __typename: 'DeleteRolePayload' })
    | null;
};

export type UpdateRoleVariables = Exact<{
  input: UpdateRoleInput;
}>;

export type UpdateRole = {
  updateRole:
    | ({
        errors: Array<{ message: string; path: Array<string> } & { __typename: 'MutationError' }>;
        role: (RoleFragment & { __typename: 'Role' }) | null;
      } & { __typename: 'UpdateRolePayload' })
    | null;
};

export type GetRolesVariables = Exact<{ [key: string]: never }>;

export type GetRoles = {
  roles: Array<
    {
      id: string;
      name: string;
      organizationId: string;
      settings: RoleSettingsFragment & { __typename: 'RoleSettings' };
    } & { __typename: 'Role' }
  >;
};

export type UpdateShareSettingsVariables = Exact<{
  input: UpdateNodeShareSettingsInput;
}>;

export type UpdateShareSettings = {
  updateNodeShareSettings:
    | ({
        orgShareLevel: BluebellShareLevel | null;
        shareRules: Array<
          { permission: BluebellShareLevel; user: UserNameFragment & { __typename: 'User' } } & {
            __typename: 'ShareRule';
          }
        > | null;
        errors: Array<ErrorFragment & { __typename: 'MutationError' }>;
      } & { __typename: 'UpdateNodeShareSettingsPayload' })
    | null;
};

export type CreateUploadAuthorizationVariables = Exact<{
  input: CreateUploadAuthorizationInput;
}>;

export type CreateUploadAuthorization = {
  createUploadAuthorization:
    | ({
        fields: any | null;
        headers: any | null;
        httpMethod: string | null;
        uploadedFileId: string | null;
        url: any | null;
      } & { __typename: 'CreateUploadAuthorizationPayload' })
    | null;
};

export type FinalizeUploadVariables = Exact<{
  input: FinalizeUploadInput;
}>;

export type FinalizeUpload = {
  finalizeUpload:
    | ({
        success: boolean;
        errors: Array<{ message: string; path: Array<string> } & { __typename: 'MutationError' }>;
        uploadedFile:
          | ({
              id: string;
              name: string;
              orgShareLevel: BluebellShareLevel;
              createdAt: any;
              updatedAt: any;
              creator: { id: string } & UserNameFragment & { __typename: 'User' };
            } & { __typename: 'UploadedFile' })
          | null;
      } & { __typename: 'FinalizeUploadPayload' })
    | null;
};

export type Creator = { id: string } & UserNameFragment & { __typename: 'User' };

export type UserNameFragment = { id: string; firstName: string; lastName: string; email: any } & {
  __typename: 'User';
};

export type InviteUserToOrganizationVariables = Exact<{
  input: InviteUserToOrganizationInput;
}>;

export type InviteUserToOrganization = {
  inviteUserToOrganization:
    | ({
        errors: Array<ErrorFragment & { __typename: 'MutationError' }>;
        organizationInvite:
          | ({
              id: string;
              email: any;
              state: OrganizationInviteState;
              creator: { firstName: string; lastName: string } & { __typename: 'User' };
              role: RoleFragment & { __typename: 'Role' };
            } & { __typename: 'OrganizationInvite' })
          | null;
      } & { __typename: 'InviteUserToOrganizationPayload' })
    | null;
};

export type UpdateUserRoleVariables = Exact<{
  input: UpdateUserRoleInput;
}>;

export type UpdateUserRole = {
  updateUserRole:
    | ({
        errors: Array<{ message: string; path: Array<string> } & { __typename: 'MutationError' }>;
        userRole:
          | ({
              id: string;
              role: { id: string; name: string } & { __typename: 'Role' };
              user: { id: string; email: any; firstName: string; lastName: string } & {
                __typename: 'User';
              };
            } & { __typename: 'UserRole' })
          | null;
      } & { __typename: 'UpdateUserRolePayload' })
    | null;
};

export type GetUsersWithRolesVariables = Exact<{ [key: string]: never }>;

export type GetUsersWithRoles = {
  users: Array<
    { role: ({ name: string } & { __typename: 'Role' }) | null } & UserNameFragment & {
        __typename: 'User';
      }
  >;
};

export const DataObjectFieldContextMetaData = gql`
  fragment DataObjectFieldContextMetaData on DataObjectSchemaFieldContextMetadata {
    categories
    required
  }
`;
export const DataObjectFieldValidValues = gql`
  fragment DataObjectFieldValidValues on DataObjectSchemaFieldValidValues {
    contextMetadata {
      ...DataObjectFieldContextMetaData
    }
    moreContextRequired
    validValues
    operators
  }
`;
export const UserNameFragment = gql`
  fragment UserNameFragment on User {
    id
    firstName
    lastName
    email
  }
`;
export const DataSourceMetaFragment = gql`
  fragment DataSourceMetaFragment on DataSource {
    id
    name
    createdAt
    creator {
      id
      ...UserNameFragment
    }
    status
    statusDetails
    updatedAt
  }
`;
export const DataSourceFragment = gql`
  fragment DataSourceFragment on DataSource {
    ...DataSourceMetaFragment
    backendDetails {
      ... on DataSourceS3BackendDetails {
        backendType
        bucket
        region
        prefix
      }
      ... on DataSourcePostgresBackendDetails {
        backendType
        host
        port
        dbname
        username
      }
    }
    backendType
  }
`;
export const Creator = gql`
  fragment Creator on User {
    id
    ...UserNameFragment
  }
`;
export const ShareRules = gql`
  fragment ShareRules on PeopleList {
    shareRules {
      user {
        ...Creator
      }
      permission
    }
  }
`;
export const FolderDetails = gql`
  fragment FolderDetails on Folder {
    id
    name
    orgShareLevel
    shareRules {
      user {
        ...Creator
      }
      permission
    }
    contents {
      id
      folderable {
        ... on PeopleList {
          id
          name
          creator {
            ...Creator
          }
          state
          mutability
          orgShareLevel
          ...ShareRules
          createdAt
          updatedAt
          nodeUserPreferences {
            favorite
          }
        }
      }
    }
  }
`;
export const DataObjectSchemaFieldMeta = gql`
  fragment DataObjectSchemaFieldMeta on DataObjectSchemaField {
    id
    name
    category
    description
    dataType
  }
`;
export const BreakdownsFieldFragment = gql`
  fragment BreakdownsFieldFragment on DataObjectBreakdownField {
    id
    createdAt
    updatedAt
    position
    dataObjectBreakdown {
      id
      createdAt
      updatedAt
      name
      statistics
      schemaFields {
        ...DataObjectSchemaFieldMeta
      }
      settings {
        pivotColumnIds
        pivotRowIds
      }
    }
    dataObjectSchemaField {
      ...DataObjectSchemaFieldMeta
    }
  }
`;
export const BreakdownFragment = gql`
  fragment BreakdownFragment on DataObjectBreakdown {
    id
    createdAt
    updatedAt
    name
    statistics
    breakdownFields {
      ...BreakdownsFieldFragment
    }
    schemaFields {
      ...DataObjectSchemaFieldMeta
    }
    settings {
      pivotColumnIds
      pivotRowIds
    }
  }
`;
export const ErrorFragment = gql`
  fragment ErrorFragment on MutationError {
    path
    message
  }
`;
export const PageInfoFragment = gql`
  fragment PageInfoFragment on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
export const PeopleListStateFragment = gql`
  fragment PeopleListStateFragment on PeopleList {
    id
    state
  }
`;
export const PeopleListMeta = gql`
  fragment PeopleListMeta on PeopleList {
    id
    name
    state
    mutability
    createdAt
    updatedAt
    orgShareLevel
  }
`;
export const BuildDefinitionStepMeta = gql`
  fragment BuildDefinitionStepMeta on PeopleListBuildStep {
    id
    name
    action
    fieldCombineOperator
  }
`;
export const RangeFragment = gql`
  fragment RangeFragment on Range {
    min
    max
  }
`;
export const FieldMeta = gql`
  fragment FieldMeta on PeopleListBuildStepField {
    id
    comparisonOperator
    dataObjectSchemaField {
      ...DataObjectSchemaFieldMeta
    }
    inputValue
    peopleListBuildStepId
    distance {
      googlePlaceId
      lat
      long
      distance
    }
    range {
      ...RangeFragment
    }
  }
`;
export const DownloadUrlProgress = gql`
  fragment DownloadUrlProgress on ProgressBar {
    complete
    currentStepIndex
    message
    steps
    success
  }
`;
export const DownloadUrlDetails = gql`
  fragment DownloadUrlDetails on ExpiringUrl {
    expiration
    ttl
    url
  }
`;
export const BreakdownTable = gql`
  fragment BreakdownTable on PivotTable {
    columnLabels
    columns {
      aggregates {
        statistic
        value
      }
      label
    }
    rows {
      points {
        fieldValues {
          field {
            id
            name
          }
          value
        }
        statisticValues {
          statistic
          value
        }
      }
      aggregates {
        statistic
        value
      }
      rowLabels
    }
  }
`;
export const PeopleListUserPreferences = gql`
  fragment PeopleListUserPreferences on NodeUserPreferences {
    favorite
  }
`;
export const PeopleListSummaryStatsFragment = gql`
  fragment PeopleListSummaryStatsFragment on PeopleList {
    summaryStats {
      description
      label
      value
    }
  }
`;
export const PeopleListHydratorRunDetails = gql`
  fragment PeopleListHydratorRunDetails on PeopleListHydratorRun {
    id
    columns
    completedAt
    creator {
      ...Creator
    }
    downloadUrl {
      url
    }
    estimatedCost
    state
    matchedCount
  }
`;
export const OrganizationBaseFields = gql`
  fragment OrganizationBaseFields on Organization {
    id
    name
    settings {
      modules
      defaultShareLevel
      hydratorColumns
    }
  }
`;
export const ApiKeyFragment = gql`
  fragment ApiKeyFragment on ApiKey {
    id
    exp
    label
    jti
    organization {
      ...OrganizationBaseFields
    }
  }
`;
export const RoleSettingsFragment = gql`
  fragment RoleSettingsFragment on RoleSettings {
    privileges
  }
`;
export const RoleFragment = gql`
  fragment RoleFragment on Role {
    id
    name
    settings {
      ...RoleSettingsFragment
    }
  }
`;
export const ApproveMfaResetDocument = gql`
  mutation ApproveMfaReset($input: ApproveMfaResetInput!) {
    approveMfaReset(input: $input) {
      success
      password
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;
export type ApproveMfaResetMutationFn = Apollo.MutationFunction<
  ApproveMfaReset,
  ApproveMfaResetVariables
>;

/**
 * __useApproveMfaReset__
 *
 * To run a mutation, you first call `useApproveMfaReset` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveMfaReset` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveMfaReset, { data, loading, error }] = useApproveMfaReset({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveMfaReset(
  baseOptions?: Apollo.MutationHookOptions<ApproveMfaReset, ApproveMfaResetVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApproveMfaReset, ApproveMfaResetVariables>(
    ApproveMfaResetDocument,
    options
  );
}
export type ApproveMfaResetHookResult = ReturnType<typeof useApproveMfaReset>;
export type ApproveMfaResetMutationResult = Apollo.MutationResult<ApproveMfaReset>;
export type ApproveMfaResetMutationOptions = Apollo.BaseMutationOptions<
  ApproveMfaReset,
  ApproveMfaResetVariables
>;
export const RejectMfaResetDocument = gql`
  mutation RejectMfaReset($input: RejectMfaResetInput!) {
    rejectMfaReset(input: $input) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;
export type RejectMfaResetMutationFn = Apollo.MutationFunction<
  RejectMfaReset,
  RejectMfaResetVariables
>;

/**
 * __useRejectMfaReset__
 *
 * To run a mutation, you first call `useRejectMfaReset` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectMfaReset` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectMfaReset, { data, loading, error }] = useRejectMfaReset({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectMfaReset(
  baseOptions?: Apollo.MutationHookOptions<RejectMfaReset, RejectMfaResetVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RejectMfaReset, RejectMfaResetVariables>(
    RejectMfaResetDocument,
    options
  );
}
export type RejectMfaResetHookResult = ReturnType<typeof useRejectMfaReset>;
export type RejectMfaResetMutationResult = Apollo.MutationResult<RejectMfaReset>;
export type RejectMfaResetMutationOptions = Apollo.BaseMutationOptions<
  RejectMfaReset,
  RejectMfaResetVariables
>;
export const RequestMfaResetDocument = gql`
  mutation RequestMfaReset($input: RequestMfaResetInput!) {
    requestMfaReset(input: $input) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;
export type RequestMfaResetMutationFn = Apollo.MutationFunction<
  RequestMfaReset,
  RequestMfaResetVariables
>;

/**
 * __useRequestMfaReset__
 *
 * To run a mutation, you first call `useRequestMfaReset` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMfaReset` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMfaReset, { data, loading, error }] = useRequestMfaReset({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestMfaReset(
  baseOptions?: Apollo.MutationHookOptions<RequestMfaReset, RequestMfaResetVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestMfaReset, RequestMfaResetVariables>(
    RequestMfaResetDocument,
    options
  );
}
export type RequestMfaResetHookResult = ReturnType<typeof useRequestMfaReset>;
export type RequestMfaResetMutationResult = Apollo.MutationResult<RequestMfaReset>;
export type RequestMfaResetMutationOptions = Apollo.BaseMutationOptions<
  RequestMfaReset,
  RequestMfaResetVariables
>;
export const GetDataObjectByDataSourceIdsDocument = gql`
  query GetDataObjectByDataSourceIds($first: Int, $dataSourceIds: [ID!]) {
    dataObjects(first: $first, dataSourceIds: $dataSourceIds) {
      edges {
        cursor
        node {
          id
          name
          createdAt
          updatedAt
          dataSource {
            id
            name
          }
          schema {
            fields {
              ...DataObjectSchemaFieldMeta
            }
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
  ${DataObjectSchemaFieldMeta}
`;

/**
 * __useGetDataObjectByDataSourceIds__
 *
 * To run a query within a React component, call `useGetDataObjectByDataSourceIds` and pass it any options that fit your needs.
 * When your component renders, `useGetDataObjectByDataSourceIds` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataObjectByDataSourceIds({
 *   variables: {
 *      first: // value for 'first'
 *      dataSourceIds: // value for 'dataSourceIds'
 *   },
 * });
 */
export function useGetDataObjectByDataSourceIds(
  baseOptions?: Apollo.QueryHookOptions<
    GetDataObjectByDataSourceIds,
    GetDataObjectByDataSourceIdsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDataObjectByDataSourceIds, GetDataObjectByDataSourceIdsVariables>(
    GetDataObjectByDataSourceIdsDocument,
    options
  );
}
export function useGetDataObjectByDataSourceIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataObjectByDataSourceIds,
    GetDataObjectByDataSourceIdsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDataObjectByDataSourceIds, GetDataObjectByDataSourceIdsVariables>(
    GetDataObjectByDataSourceIdsDocument,
    options
  );
}
export function useGetDataObjectByDataSourceIdsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDataObjectByDataSourceIds,
    GetDataObjectByDataSourceIdsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDataObjectByDataSourceIds,
    GetDataObjectByDataSourceIdsVariables
  >(GetDataObjectByDataSourceIdsDocument, options);
}
export type GetDataObjectByDataSourceIdsHookResult = ReturnType<
  typeof useGetDataObjectByDataSourceIds
>;
export type GetDataObjectByDataSourceIdsLazyQueryHookResult = ReturnType<
  typeof useGetDataObjectByDataSourceIdsLazyQuery
>;
export type GetDataObjectByDataSourceIdsSuspenseQueryHookResult = ReturnType<
  typeof useGetDataObjectByDataSourceIdsSuspenseQuery
>;
export type GetDataObjectByDataSourceIdsQueryResult = Apollo.QueryResult<
  GetDataObjectByDataSourceIds,
  GetDataObjectByDataSourceIdsVariables
>;
export const GetDataObjectByDataSourceIdsBaseDataDocument = gql`
  query GetDataObjectByDataSourceIdsBaseData($first: Int, $dataSourceIds: [ID!]) {
    dataObjects(first: $first, dataSourceIds: $dataSourceIds) {
      nodes {
        id
        name
        dataSource {
          id
          name
        }
        schema {
          fields {
            ...DataObjectSchemaFieldMeta
          }
        }
      }
    }
  }
  ${DataObjectSchemaFieldMeta}
`;

/**
 * __useGetDataObjectByDataSourceIdsBaseData__
 *
 * To run a query within a React component, call `useGetDataObjectByDataSourceIdsBaseData` and pass it any options that fit your needs.
 * When your component renders, `useGetDataObjectByDataSourceIdsBaseData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataObjectByDataSourceIdsBaseData({
 *   variables: {
 *      first: // value for 'first'
 *      dataSourceIds: // value for 'dataSourceIds'
 *   },
 * });
 */
export function useGetDataObjectByDataSourceIdsBaseData(
  baseOptions?: Apollo.QueryHookOptions<
    GetDataObjectByDataSourceIdsBaseData,
    GetDataObjectByDataSourceIdsBaseDataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDataObjectByDataSourceIdsBaseData,
    GetDataObjectByDataSourceIdsBaseDataVariables
  >(GetDataObjectByDataSourceIdsBaseDataDocument, options);
}
export function useGetDataObjectByDataSourceIdsBaseDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataObjectByDataSourceIdsBaseData,
    GetDataObjectByDataSourceIdsBaseDataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDataObjectByDataSourceIdsBaseData,
    GetDataObjectByDataSourceIdsBaseDataVariables
  >(GetDataObjectByDataSourceIdsBaseDataDocument, options);
}
export function useGetDataObjectByDataSourceIdsBaseDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDataObjectByDataSourceIdsBaseData,
    GetDataObjectByDataSourceIdsBaseDataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDataObjectByDataSourceIdsBaseData,
    GetDataObjectByDataSourceIdsBaseDataVariables
  >(GetDataObjectByDataSourceIdsBaseDataDocument, options);
}
export type GetDataObjectByDataSourceIdsBaseDataHookResult = ReturnType<
  typeof useGetDataObjectByDataSourceIdsBaseData
>;
export type GetDataObjectByDataSourceIdsBaseDataLazyQueryHookResult = ReturnType<
  typeof useGetDataObjectByDataSourceIdsBaseDataLazyQuery
>;
export type GetDataObjectByDataSourceIdsBaseDataSuspenseQueryHookResult = ReturnType<
  typeof useGetDataObjectByDataSourceIdsBaseDataSuspenseQuery
>;
export type GetDataObjectByDataSourceIdsBaseDataQueryResult = Apollo.QueryResult<
  GetDataObjectByDataSourceIdsBaseData,
  GetDataObjectByDataSourceIdsBaseDataVariables
>;
export const GetDataObjectFieldCategoriesDocument = gql`
  query GetDataObjectFieldCategories {
    dataObjectFieldCategories
  }
`;

/**
 * __useGetDataObjectFieldCategories__
 *
 * To run a query within a React component, call `useGetDataObjectFieldCategories` and pass it any options that fit your needs.
 * When your component renders, `useGetDataObjectFieldCategories` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataObjectFieldCategories({
 *   variables: {
 *   },
 * });
 */
export function useGetDataObjectFieldCategories(
  baseOptions?: Apollo.QueryHookOptions<
    GetDataObjectFieldCategories,
    GetDataObjectFieldCategoriesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDataObjectFieldCategories, GetDataObjectFieldCategoriesVariables>(
    GetDataObjectFieldCategoriesDocument,
    options
  );
}
export function useGetDataObjectFieldCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataObjectFieldCategories,
    GetDataObjectFieldCategoriesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDataObjectFieldCategories, GetDataObjectFieldCategoriesVariables>(
    GetDataObjectFieldCategoriesDocument,
    options
  );
}
export function useGetDataObjectFieldCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDataObjectFieldCategories,
    GetDataObjectFieldCategoriesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDataObjectFieldCategories,
    GetDataObjectFieldCategoriesVariables
  >(GetDataObjectFieldCategoriesDocument, options);
}
export type GetDataObjectFieldCategoriesHookResult = ReturnType<
  typeof useGetDataObjectFieldCategories
>;
export type GetDataObjectFieldCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetDataObjectFieldCategoriesLazyQuery
>;
export type GetDataObjectFieldCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetDataObjectFieldCategoriesSuspenseQuery
>;
export type GetDataObjectFieldCategoriesQueryResult = Apollo.QueryResult<
  GetDataObjectFieldCategories,
  GetDataObjectFieldCategoriesVariables
>;
export const GetValidValuesDocument = gql`
  query GetValidValues(
    $id: ID!
    $relatedFields: [PeopleListBuildStepFieldInput!]
    $fieldCombineOperator: FieldCombineOperator!
    $maxCardinality: Int
  ) {
    dataObjectFieldValidValues(
      id: $id
      relatedFields: $relatedFields
      fieldCombineOperator: $fieldCombineOperator
      maxCardinality: $maxCardinality
    ) {
      ...DataObjectFieldValidValues
    }
  }
  ${DataObjectFieldValidValues}
  ${DataObjectFieldContextMetaData}
`;

/**
 * __useGetValidValues__
 *
 * To run a query within a React component, call `useGetValidValues` and pass it any options that fit your needs.
 * When your component renders, `useGetValidValues` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValidValues({
 *   variables: {
 *      id: // value for 'id'
 *      relatedFields: // value for 'relatedFields'
 *      fieldCombineOperator: // value for 'fieldCombineOperator'
 *      maxCardinality: // value for 'maxCardinality'
 *   },
 * });
 */
export function useGetValidValues(
  baseOptions: Apollo.QueryHookOptions<GetValidValues, GetValidValuesVariables> &
    ({ variables: GetValidValuesVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetValidValues, GetValidValuesVariables>(GetValidValuesDocument, options);
}
export function useGetValidValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetValidValues, GetValidValuesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetValidValues, GetValidValuesVariables>(
    GetValidValuesDocument,
    options
  );
}
export function useGetValidValuesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetValidValues, GetValidValuesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetValidValues, GetValidValuesVariables>(
    GetValidValuesDocument,
    options
  );
}
export type GetValidValuesHookResult = ReturnType<typeof useGetValidValues>;
export type GetValidValuesLazyQueryHookResult = ReturnType<typeof useGetValidValuesLazyQuery>;
export type GetValidValuesSuspenseQueryHookResult = ReturnType<
  typeof useGetValidValuesSuspenseQuery
>;
export type GetValidValuesQueryResult = Apollo.QueryResult<GetValidValues, GetValidValuesVariables>;
export const GetDataSourceByIdDocument = gql`
  query GetDataSourceById($id: ID!) {
    dataSource(id: $id) {
      ...DataSourceFragment
    }
  }
  ${DataSourceFragment}
  ${DataSourceMetaFragment}
  ${UserNameFragment}
`;

/**
 * __useGetDataSourceById__
 *
 * To run a query within a React component, call `useGetDataSourceById` and pass it any options that fit your needs.
 * When your component renders, `useGetDataSourceById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataSourceById({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataSourceById(
  baseOptions: Apollo.QueryHookOptions<GetDataSourceById, GetDataSourceByIdVariables> &
    ({ variables: GetDataSourceByIdVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDataSourceById, GetDataSourceByIdVariables>(
    GetDataSourceByIdDocument,
    options
  );
}
export function useGetDataSourceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDataSourceById, GetDataSourceByIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDataSourceById, GetDataSourceByIdVariables>(
    GetDataSourceByIdDocument,
    options
  );
}
export function useGetDataSourceByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetDataSourceById, GetDataSourceByIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDataSourceById, GetDataSourceByIdVariables>(
    GetDataSourceByIdDocument,
    options
  );
}
export type GetDataSourceByIdHookResult = ReturnType<typeof useGetDataSourceById>;
export type GetDataSourceByIdLazyQueryHookResult = ReturnType<typeof useGetDataSourceByIdLazyQuery>;
export type GetDataSourceByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetDataSourceByIdSuspenseQuery
>;
export type GetDataSourceByIdQueryResult = Apollo.QueryResult<
  GetDataSourceById,
  GetDataSourceByIdVariables
>;
export const GetDataSourcesDocument = gql`
  query GetDataSources($before: String, $after: String, $first: Int, $last: Int) {
    dataSources(before: $before, after: $after, first: $first, last: $last) {
      edges {
        cursor
        node {
          ...DataSourceMetaFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${DataSourceMetaFragment}
  ${UserNameFragment}
  ${PageInfoFragment}
`;

/**
 * __useGetDataSources__
 *
 * To run a query within a React component, call `useGetDataSources` and pass it any options that fit your needs.
 * When your component renders, `useGetDataSources` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataSources({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetDataSources(
  baseOptions?: Apollo.QueryHookOptions<GetDataSources, GetDataSourcesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDataSources, GetDataSourcesVariables>(GetDataSourcesDocument, options);
}
export function useGetDataSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDataSources, GetDataSourcesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDataSources, GetDataSourcesVariables>(
    GetDataSourcesDocument,
    options
  );
}
export function useGetDataSourcesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetDataSources, GetDataSourcesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDataSources, GetDataSourcesVariables>(
    GetDataSourcesDocument,
    options
  );
}
export type GetDataSourcesHookResult = ReturnType<typeof useGetDataSources>;
export type GetDataSourcesLazyQueryHookResult = ReturnType<typeof useGetDataSourcesLazyQuery>;
export type GetDataSourcesSuspenseQueryHookResult = ReturnType<
  typeof useGetDataSourcesSuspenseQuery
>;
export type GetDataSourcesQueryResult = Apollo.QueryResult<GetDataSources, GetDataSourcesVariables>;
export const GetDataSourcesBaseDataDocument = gql`
  query GetDataSourcesBaseData($before: String, $after: String, $first: Int, $last: Int) {
    dataSources(before: $before, after: $after, first: $first, last: $last) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useGetDataSourcesBaseData__
 *
 * To run a query within a React component, call `useGetDataSourcesBaseData` and pass it any options that fit your needs.
 * When your component renders, `useGetDataSourcesBaseData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataSourcesBaseData({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetDataSourcesBaseData(
  baseOptions?: Apollo.QueryHookOptions<GetDataSourcesBaseData, GetDataSourcesBaseDataVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDataSourcesBaseData, GetDataSourcesBaseDataVariables>(
    GetDataSourcesBaseDataDocument,
    options
  );
}
export function useGetDataSourcesBaseDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDataSourcesBaseData, GetDataSourcesBaseDataVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDataSourcesBaseData, GetDataSourcesBaseDataVariables>(
    GetDataSourcesBaseDataDocument,
    options
  );
}
export function useGetDataSourcesBaseDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDataSourcesBaseData,
    GetDataSourcesBaseDataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDataSourcesBaseData, GetDataSourcesBaseDataVariables>(
    GetDataSourcesBaseDataDocument,
    options
  );
}
export type GetDataSourcesBaseDataHookResult = ReturnType<typeof useGetDataSourcesBaseData>;
export type GetDataSourcesBaseDataLazyQueryHookResult = ReturnType<
  typeof useGetDataSourcesBaseDataLazyQuery
>;
export type GetDataSourcesBaseDataSuspenseQueryHookResult = ReturnType<
  typeof useGetDataSourcesBaseDataSuspenseQuery
>;
export type GetDataSourcesBaseDataQueryResult = Apollo.QueryResult<
  GetDataSourcesBaseData,
  GetDataSourcesBaseDataVariables
>;
export const AssignToFolderDocument = gql`
  mutation AssignToFolder($input: AssignNodeToFolderInput!) {
    assignNodeToFolder(input: $input) {
      folder {
        ...FolderDetails
      }
    }
  }
  ${FolderDetails}
  ${Creator}
  ${UserNameFragment}
  ${ShareRules}
`;
export type AssignToFolderMutationFn = Apollo.MutationFunction<
  AssignToFolder,
  AssignToFolderVariables
>;

/**
 * __useAssignToFolder__
 *
 * To run a mutation, you first call `useAssignToFolder` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignToFolder` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignToFolder, { data, loading, error }] = useAssignToFolder({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignToFolder(
  baseOptions?: Apollo.MutationHookOptions<AssignToFolder, AssignToFolderVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignToFolder, AssignToFolderVariables>(
    AssignToFolderDocument,
    options
  );
}
export type AssignToFolderHookResult = ReturnType<typeof useAssignToFolder>;
export type AssignToFolderMutationResult = Apollo.MutationResult<AssignToFolder>;
export type AssignToFolderMutationOptions = Apollo.BaseMutationOptions<
  AssignToFolder,
  AssignToFolderVariables
>;
export const CreateFolderDocument = gql`
  mutation CreateFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      folder {
        ...FolderDetails
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${FolderDetails}
  ${Creator}
  ${UserNameFragment}
  ${ShareRules}
  ${ErrorFragment}
`;
export type CreateFolderMutationFn = Apollo.MutationFunction<CreateFolder, CreateFolderVariables>;

/**
 * __useCreateFolder__
 *
 * To run a mutation, you first call `useCreateFolder` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolder` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolder, { data, loading, error }] = useCreateFolder({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFolder(
  baseOptions?: Apollo.MutationHookOptions<CreateFolder, CreateFolderVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFolder, CreateFolderVariables>(CreateFolderDocument, options);
}
export type CreateFolderHookResult = ReturnType<typeof useCreateFolder>;
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolder>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<
  CreateFolder,
  CreateFolderVariables
>;
export const RemoveFromFolderDocument = gql`
  mutation RemoveFromFolder($input: RemoveNodeFromFolderInput!) {
    removeNodeFromFolder(input: $input) {
      errors {
        ...ErrorFragment
      }
      success
    }
  }
  ${ErrorFragment}
`;
export type RemoveFromFolderMutationFn = Apollo.MutationFunction<
  RemoveFromFolder,
  RemoveFromFolderVariables
>;

/**
 * __useRemoveFromFolder__
 *
 * To run a mutation, you first call `useRemoveFromFolder` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromFolder` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromFolder, { data, loading, error }] = useRemoveFromFolder({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFromFolder(
  baseOptions?: Apollo.MutationHookOptions<RemoveFromFolder, RemoveFromFolderVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFromFolder, RemoveFromFolderVariables>(
    RemoveFromFolderDocument,
    options
  );
}
export type RemoveFromFolderHookResult = ReturnType<typeof useRemoveFromFolder>;
export type RemoveFromFolderMutationResult = Apollo.MutationResult<RemoveFromFolder>;
export type RemoveFromFolderMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromFolder,
  RemoveFromFolderVariables
>;
export const GetFolderDocument = gql`
  query GetFolder($name: String!) {
    folder(name: $name) {
      ...FolderDetails
    }
  }
  ${FolderDetails}
  ${Creator}
  ${UserNameFragment}
  ${ShareRules}
`;

/**
 * __useGetFolder__
 *
 * To run a query within a React component, call `useGetFolder` and pass it any options that fit your needs.
 * When your component renders, `useGetFolder` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFolder({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetFolder(
  baseOptions: Apollo.QueryHookOptions<GetFolder, GetFolderVariables> &
    ({ variables: GetFolderVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFolder, GetFolderVariables>(GetFolderDocument, options);
}
export function useGetFolderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFolder, GetFolderVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFolder, GetFolderVariables>(GetFolderDocument, options);
}
export function useGetFolderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetFolder, GetFolderVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFolder, GetFolderVariables>(GetFolderDocument, options);
}
export type GetFolderHookResult = ReturnType<typeof useGetFolder>;
export type GetFolderLazyQueryHookResult = ReturnType<typeof useGetFolderLazyQuery>;
export type GetFolderSuspenseQueryHookResult = ReturnType<typeof useGetFolderSuspenseQuery>;
export type GetFolderQueryResult = Apollo.QueryResult<GetFolder, GetFolderVariables>;
export const GetFoldersDocument = gql`
  query GetFolders($after: String, $before: String, $first: Int, $last: Int) {
    folders(after: $after, before: $before, first: $first, last: $last) {
      nodes {
        ...FolderDetails
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${FolderDetails}
  ${Creator}
  ${UserNameFragment}
  ${ShareRules}
  ${PageInfoFragment}
`;

/**
 * __useGetFolders__
 *
 * To run a query within a React component, call `useGetFolders` and pass it any options that fit your needs.
 * When your component renders, `useGetFolders` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFolders({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetFolders(
  baseOptions?: Apollo.QueryHookOptions<GetFolders, GetFoldersVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFolders, GetFoldersVariables>(GetFoldersDocument, options);
}
export function useGetFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFolders, GetFoldersVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFolders, GetFoldersVariables>(GetFoldersDocument, options);
}
export function useGetFoldersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetFolders, GetFoldersVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFolders, GetFoldersVariables>(GetFoldersDocument, options);
}
export type GetFoldersHookResult = ReturnType<typeof useGetFolders>;
export type GetFoldersLazyQueryHookResult = ReturnType<typeof useGetFoldersLazyQuery>;
export type GetFoldersSuspenseQueryHookResult = ReturnType<typeof useGetFoldersSuspenseQuery>;
export type GetFoldersQueryResult = Apollo.QueryResult<GetFolders, GetFoldersVariables>;
export const AcceptOrgInviteDocument = gql`
  mutation AcceptOrgInvite($input: AcceptOrganizationInviteInput!) {
    acceptOrganizationInvite(input: $input) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;
export type AcceptOrgInviteMutationFn = Apollo.MutationFunction<
  AcceptOrgInvite,
  AcceptOrgInviteVariables
>;

/**
 * __useAcceptOrgInvite__
 *
 * To run a mutation, you first call `useAcceptOrgInvite` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptOrgInvite` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptOrgInvite, { data, loading, error }] = useAcceptOrgInvite({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptOrgInvite(
  baseOptions?: Apollo.MutationHookOptions<AcceptOrgInvite, AcceptOrgInviteVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptOrgInvite, AcceptOrgInviteVariables>(
    AcceptOrgInviteDocument,
    options
  );
}
export type AcceptOrgInviteHookResult = ReturnType<typeof useAcceptOrgInvite>;
export type AcceptOrgInviteMutationResult = Apollo.MutationResult<AcceptOrgInvite>;
export type AcceptOrgInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptOrgInvite,
  AcceptOrgInviteVariables
>;
export const DeclineOrgInviteDocument = gql`
  mutation DeclineOrgInvite($input: DeclineOrganizationInviteInput!) {
    declineOrganizationInvite(input: $input) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;
export type DeclineOrgInviteMutationFn = Apollo.MutationFunction<
  DeclineOrgInvite,
  DeclineOrgInviteVariables
>;

/**
 * __useDeclineOrgInvite__
 *
 * To run a mutation, you first call `useDeclineOrgInvite` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineOrgInvite` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineOrgInvite, { data, loading, error }] = useDeclineOrgInvite({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineOrgInvite(
  baseOptions?: Apollo.MutationHookOptions<DeclineOrgInvite, DeclineOrgInviteVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeclineOrgInvite, DeclineOrgInviteVariables>(
    DeclineOrgInviteDocument,
    options
  );
}
export type DeclineOrgInviteHookResult = ReturnType<typeof useDeclineOrgInvite>;
export type DeclineOrgInviteMutationResult = Apollo.MutationResult<DeclineOrgInvite>;
export type DeclineOrgInviteMutationOptions = Apollo.BaseMutationOptions<
  DeclineOrgInvite,
  DeclineOrgInviteVariables
>;
export const DeleteOrganizationInviteDocument = gql`
  mutation DeleteOrganizationInvite($input: DeleteOrganizationInviteInput!) {
    deleteOrganizationInvite(input: $input) {
      errors {
        ...ErrorFragment
      }
      success
    }
  }
  ${ErrorFragment}
`;
export type DeleteOrganizationInviteMutationFn = Apollo.MutationFunction<
  DeleteOrganizationInvite,
  DeleteOrganizationInviteVariables
>;

/**
 * __useDeleteOrganizationInvite__
 *
 * To run a mutation, you first call `useDeleteOrganizationInvite` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationInvite` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationInvite, { data, loading, error }] = useDeleteOrganizationInvite({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrganizationInvite(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrganizationInvite,
    DeleteOrganizationInviteVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOrganizationInvite, DeleteOrganizationInviteVariables>(
    DeleteOrganizationInviteDocument,
    options
  );
}
export type DeleteOrganizationInviteHookResult = ReturnType<typeof useDeleteOrganizationInvite>;
export type DeleteOrganizationInviteMutationResult =
  Apollo.MutationResult<DeleteOrganizationInvite>;
export type DeleteOrganizationInviteMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganizationInvite,
  DeleteOrganizationInviteVariables
>;
export const GetOrgInviteByIdDocument = gql`
  query GetOrgInviteById($id: ID!) {
    organizationInvite(id: $id) {
      id
      organization {
        id
        name
      }
    }
  }
`;

/**
 * __useGetOrgInviteById__
 *
 * To run a query within a React component, call `useGetOrgInviteById` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgInviteById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgInviteById({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgInviteById(
  baseOptions: Apollo.QueryHookOptions<GetOrgInviteById, GetOrgInviteByIdVariables> &
    ({ variables: GetOrgInviteByIdVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrgInviteById, GetOrgInviteByIdVariables>(
    GetOrgInviteByIdDocument,
    options
  );
}
export function useGetOrgInviteByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrgInviteById, GetOrgInviteByIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrgInviteById, GetOrgInviteByIdVariables>(
    GetOrgInviteByIdDocument,
    options
  );
}
export function useGetOrgInviteByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrgInviteById, GetOrgInviteByIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOrgInviteById, GetOrgInviteByIdVariables>(
    GetOrgInviteByIdDocument,
    options
  );
}
export type GetOrgInviteByIdHookResult = ReturnType<typeof useGetOrgInviteById>;
export type GetOrgInviteByIdLazyQueryHookResult = ReturnType<typeof useGetOrgInviteByIdLazyQuery>;
export type GetOrgInviteByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetOrgInviteByIdSuspenseQuery
>;
export type GetOrgInviteByIdQueryResult = Apollo.QueryResult<
  GetOrgInviteById,
  GetOrgInviteByIdVariables
>;
export const ApproveHydratorRunDocument = gql`
  mutation ApproveHydratorRun($input: ApprovePeopleListHydratorRunInput!) {
    approvePeopleListHydratorRun(input: $input) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;
export type ApproveHydratorRunMutationFn = Apollo.MutationFunction<
  ApproveHydratorRun,
  ApproveHydratorRunVariables
>;

/**
 * __useApproveHydratorRun__
 *
 * To run a mutation, you first call `useApproveHydratorRun` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveHydratorRun` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveHydratorRun, { data, loading, error }] = useApproveHydratorRun({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveHydratorRun(
  baseOptions?: Apollo.MutationHookOptions<ApproveHydratorRun, ApproveHydratorRunVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApproveHydratorRun, ApproveHydratorRunVariables>(
    ApproveHydratorRunDocument,
    options
  );
}
export type ApproveHydratorRunHookResult = ReturnType<typeof useApproveHydratorRun>;
export type ApproveHydratorRunMutationResult = Apollo.MutationResult<ApproveHydratorRun>;
export type ApproveHydratorRunMutationOptions = Apollo.BaseMutationOptions<
  ApproveHydratorRun,
  ApproveHydratorRunVariables
>;
export const ArchivePeopleListDocument = gql`
  mutation ArchivePeopleList($input: ArchivePeopleListInput!) {
    archivePeopleList(input: $input) {
      errors {
        ...ErrorFragment
      }
      peopleList {
        ...PeopleListStateFragment
      }
      success
    }
  }
  ${ErrorFragment}
  ${PeopleListStateFragment}
`;
export type ArchivePeopleListMutationFn = Apollo.MutationFunction<
  ArchivePeopleList,
  ArchivePeopleListVariables
>;

/**
 * __useArchivePeopleList__
 *
 * To run a mutation, you first call `useArchivePeopleList` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePeopleList` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePeopleList, { data, loading, error }] = useArchivePeopleList({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchivePeopleList(
  baseOptions?: Apollo.MutationHookOptions<ArchivePeopleList, ArchivePeopleListVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchivePeopleList, ArchivePeopleListVariables>(
    ArchivePeopleListDocument,
    options
  );
}
export type ArchivePeopleListHookResult = ReturnType<typeof useArchivePeopleList>;
export type ArchivePeopleListMutationResult = Apollo.MutationResult<ArchivePeopleList>;
export type ArchivePeopleListMutationOptions = Apollo.BaseMutationOptions<
  ArchivePeopleList,
  ArchivePeopleListVariables
>;
export const ClonePeopleListDocument = gql`
  mutation ClonePeopleList($input: ClonePeopleListInput!) {
    clonePeopleList(input: $input) {
      peopleList {
        id
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;
export type ClonePeopleListMutationFn = Apollo.MutationFunction<
  ClonePeopleList,
  ClonePeopleListVariables
>;

/**
 * __useClonePeopleList__
 *
 * To run a mutation, you first call `useClonePeopleList` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClonePeopleList` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clonePeopleList, { data, loading, error }] = useClonePeopleList({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClonePeopleList(
  baseOptions?: Apollo.MutationHookOptions<ClonePeopleList, ClonePeopleListVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClonePeopleList, ClonePeopleListVariables>(
    ClonePeopleListDocument,
    options
  );
}
export type ClonePeopleListHookResult = ReturnType<typeof useClonePeopleList>;
export type ClonePeopleListMutationResult = Apollo.MutationResult<ClonePeopleList>;
export type ClonePeopleListMutationOptions = Apollo.BaseMutationOptions<
  ClonePeopleList,
  ClonePeopleListVariables
>;
export const CreateDataObjectBreakdownDocument = gql`
  mutation CreateDataObjectBreakdown($input: CreateDataObjectBreakdownInput!) {
    createDataObjectBreakdown(input: $input) {
      breakdown {
        ...BreakdownFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${BreakdownFragment}
  ${BreakdownsFieldFragment}
  ${DataObjectSchemaFieldMeta}
  ${ErrorFragment}
`;
export type CreateDataObjectBreakdownMutationFn = Apollo.MutationFunction<
  CreateDataObjectBreakdown,
  CreateDataObjectBreakdownVariables
>;

/**
 * __useCreateDataObjectBreakdown__
 *
 * To run a mutation, you first call `useCreateDataObjectBreakdown` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataObjectBreakdown` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataObjectBreakdown, { data, loading, error }] = useCreateDataObjectBreakdown({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataObjectBreakdown(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDataObjectBreakdown,
    CreateDataObjectBreakdownVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDataObjectBreakdown, CreateDataObjectBreakdownVariables>(
    CreateDataObjectBreakdownDocument,
    options
  );
}
export type CreateDataObjectBreakdownHookResult = ReturnType<typeof useCreateDataObjectBreakdown>;
export type CreateDataObjectBreakdownMutationResult =
  Apollo.MutationResult<CreateDataObjectBreakdown>;
export type CreateDataObjectBreakdownMutationOptions = Apollo.BaseMutationOptions<
  CreateDataObjectBreakdown,
  CreateDataObjectBreakdownVariables
>;
export const CreateFavoriteDocument = gql`
  mutation CreateFavorite($input: CreateFavoriteInput!) {
    createFavorite(input: $input) {
      errors {
        ...ErrorFragment
      }
      success
    }
  }
  ${ErrorFragment}
`;
export type CreateFavoriteMutationFn = Apollo.MutationFunction<
  CreateFavorite,
  CreateFavoriteVariables
>;

/**
 * __useCreateFavorite__
 *
 * To run a mutation, you first call `useCreateFavorite` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavorite` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavorite, { data, loading, error }] = useCreateFavorite({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFavorite(
  baseOptions?: Apollo.MutationHookOptions<CreateFavorite, CreateFavoriteVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFavorite, CreateFavoriteVariables>(
    CreateFavoriteDocument,
    options
  );
}
export type CreateFavoriteHookResult = ReturnType<typeof useCreateFavorite>;
export type CreateFavoriteMutationResult = Apollo.MutationResult<CreateFavorite>;
export type CreateFavoriteMutationOptions = Apollo.BaseMutationOptions<
  CreateFavorite,
  CreateFavoriteVariables
>;
export const CreateHydratorRunDocument = gql`
  mutation CreateHydratorRun($input: CreatePeopleListHydratorRunInput!) {
    createPeopleListHydratorRun(input: $input) {
      peopleListHydratorRun {
        ...PeopleListHydratorRunDetails
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${PeopleListHydratorRunDetails}
  ${Creator}
  ${UserNameFragment}
  ${ErrorFragment}
`;
export type CreateHydratorRunMutationFn = Apollo.MutationFunction<
  CreateHydratorRun,
  CreateHydratorRunVariables
>;

/**
 * __useCreateHydratorRun__
 *
 * To run a mutation, you first call `useCreateHydratorRun` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHydratorRun` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHydratorRun, { data, loading, error }] = useCreateHydratorRun({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHydratorRun(
  baseOptions?: Apollo.MutationHookOptions<CreateHydratorRun, CreateHydratorRunVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateHydratorRun, CreateHydratorRunVariables>(
    CreateHydratorRunDocument,
    options
  );
}
export type CreateHydratorRunHookResult = ReturnType<typeof useCreateHydratorRun>;
export type CreateHydratorRunMutationResult = Apollo.MutationResult<CreateHydratorRun>;
export type CreateHydratorRunMutationOptions = Apollo.BaseMutationOptions<
  CreateHydratorRun,
  CreateHydratorRunVariables
>;
export const CreatePeopleListDocument = gql`
  mutation CreatePeopleList($input: CreatePeopleListInput!) {
    createPeopleList(input: $input) {
      peopleList {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;
export type CreatePeopleListMutationFn = Apollo.MutationFunction<
  CreatePeopleList,
  CreatePeopleListVariables
>;

/**
 * __useCreatePeopleList__
 *
 * To run a mutation, you first call `useCreatePeopleList` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePeopleList` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPeopleList, { data, loading, error }] = useCreatePeopleList({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePeopleList(
  baseOptions?: Apollo.MutationHookOptions<CreatePeopleList, CreatePeopleListVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePeopleList, CreatePeopleListVariables>(
    CreatePeopleListDocument,
    options
  );
}
export type CreatePeopleListHookResult = ReturnType<typeof useCreatePeopleList>;
export type CreatePeopleListMutationResult = Apollo.MutationResult<CreatePeopleList>;
export type CreatePeopleListMutationOptions = Apollo.BaseMutationOptions<
  CreatePeopleList,
  CreatePeopleListVariables
>;
export const CreatePeopleListDownloadUrlDocument = gql`
  mutation CreatePeopleListDownloadUrl($input: CreatePeopleListDownloadUrlInput!) {
    createPeopleListDownloadUrl(input: $input) {
      urlDetails {
        ...DownloadUrlDetails
      }
      progress {
        ...DownloadUrlProgress
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DownloadUrlDetails}
  ${DownloadUrlProgress}
  ${ErrorFragment}
`;
export type CreatePeopleListDownloadUrlMutationFn = Apollo.MutationFunction<
  CreatePeopleListDownloadUrl,
  CreatePeopleListDownloadUrlVariables
>;

/**
 * __useCreatePeopleListDownloadUrl__
 *
 * To run a mutation, you first call `useCreatePeopleListDownloadUrl` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePeopleListDownloadUrl` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPeopleListDownloadUrl, { data, loading, error }] = useCreatePeopleListDownloadUrl({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePeopleListDownloadUrl(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePeopleListDownloadUrl,
    CreatePeopleListDownloadUrlVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePeopleListDownloadUrl, CreatePeopleListDownloadUrlVariables>(
    CreatePeopleListDownloadUrlDocument,
    options
  );
}
export type CreatePeopleListDownloadUrlHookResult = ReturnType<
  typeof useCreatePeopleListDownloadUrl
>;
export type CreatePeopleListDownloadUrlMutationResult =
  Apollo.MutationResult<CreatePeopleListDownloadUrl>;
export type CreatePeopleListDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreatePeopleListDownloadUrl,
  CreatePeopleListDownloadUrlVariables
>;
export const DeleteFavoriteDocument = gql`
  mutation DeleteFavorite($input: DeleteFavoriteInput!) {
    deleteFavorite(input: $input) {
      errors {
        ...ErrorFragment
      }
      success
    }
  }
  ${ErrorFragment}
`;
export type DeleteFavoriteMutationFn = Apollo.MutationFunction<
  DeleteFavorite,
  DeleteFavoriteVariables
>;

/**
 * __useDeleteFavorite__
 *
 * To run a mutation, you first call `useDeleteFavorite` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavorite` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavorite, { data, loading, error }] = useDeleteFavorite({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFavorite(
  baseOptions?: Apollo.MutationHookOptions<DeleteFavorite, DeleteFavoriteVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFavorite, DeleteFavoriteVariables>(
    DeleteFavoriteDocument,
    options
  );
}
export type DeleteFavoriteHookResult = ReturnType<typeof useDeleteFavorite>;
export type DeleteFavoriteMutationResult = Apollo.MutationResult<DeleteFavorite>;
export type DeleteFavoriteMutationOptions = Apollo.BaseMutationOptions<
  DeleteFavorite,
  DeleteFavoriteVariables
>;
export const DeleteHydratorRunDocument = gql`
  mutation DeleteHydratorRun($input: DeletePeopleListHydratorRunInput!) {
    deletePeopleListHydratorRun(input: $input) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;
export type DeleteHydratorRunMutationFn = Apollo.MutationFunction<
  DeleteHydratorRun,
  DeleteHydratorRunVariables
>;

/**
 * __useDeleteHydratorRun__
 *
 * To run a mutation, you first call `useDeleteHydratorRun` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHydratorRun` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHydratorRun, { data, loading, error }] = useDeleteHydratorRun({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteHydratorRun(
  baseOptions?: Apollo.MutationHookOptions<DeleteHydratorRun, DeleteHydratorRunVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteHydratorRun, DeleteHydratorRunVariables>(
    DeleteHydratorRunDocument,
    options
  );
}
export type DeleteHydratorRunHookResult = ReturnType<typeof useDeleteHydratorRun>;
export type DeleteHydratorRunMutationResult = Apollo.MutationResult<DeleteHydratorRun>;
export type DeleteHydratorRunMutationOptions = Apollo.BaseMutationOptions<
  DeleteHydratorRun,
  DeleteHydratorRunVariables
>;
export const UnarchivePeopleListDocument = gql`
  mutation UnarchivePeopleList($input: UnarchivePeopleListInput!) {
    unarchivePeopleList(input: $input) {
      errors {
        ...ErrorFragment
      }
      peopleList {
        ...PeopleListStateFragment
      }
      success
    }
  }
  ${ErrorFragment}
  ${PeopleListStateFragment}
`;
export type UnarchivePeopleListMutationFn = Apollo.MutationFunction<
  UnarchivePeopleList,
  UnarchivePeopleListVariables
>;

/**
 * __useUnarchivePeopleList__
 *
 * To run a mutation, you first call `useUnarchivePeopleList` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchivePeopleList` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchivePeopleList, { data, loading, error }] = useUnarchivePeopleList({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnarchivePeopleList(
  baseOptions?: Apollo.MutationHookOptions<UnarchivePeopleList, UnarchivePeopleListVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchivePeopleList, UnarchivePeopleListVariables>(
    UnarchivePeopleListDocument,
    options
  );
}
export type UnarchivePeopleListHookResult = ReturnType<typeof useUnarchivePeopleList>;
export type UnarchivePeopleListMutationResult = Apollo.MutationResult<UnarchivePeopleList>;
export type UnarchivePeopleListMutationOptions = Apollo.BaseMutationOptions<
  UnarchivePeopleList,
  UnarchivePeopleListVariables
>;
export const UpdatePeopleListDocument = gql`
  mutation UpdatePeopleList($input: UpdatePeopleListInput!) {
    updatePeopleList(input: $input) {
      peopleList {
        ...PeopleListMeta
        buildDefinition {
          steps {
            ...BuildDefinitionStepMeta
            fields {
              ...FieldMeta
            }
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
  ${PeopleListMeta}
  ${BuildDefinitionStepMeta}
  ${FieldMeta}
  ${DataObjectSchemaFieldMeta}
  ${RangeFragment}
`;
export type UpdatePeopleListMutationFn = Apollo.MutationFunction<
  UpdatePeopleList,
  UpdatePeopleListVariables
>;

/**
 * __useUpdatePeopleList__
 *
 * To run a mutation, you first call `useUpdatePeopleList` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeopleList` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeopleList, { data, loading, error }] = useUpdatePeopleList({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePeopleList(
  baseOptions?: Apollo.MutationHookOptions<UpdatePeopleList, UpdatePeopleListVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePeopleList, UpdatePeopleListVariables>(
    UpdatePeopleListDocument,
    options
  );
}
export type UpdatePeopleListHookResult = ReturnType<typeof useUpdatePeopleList>;
export type UpdatePeopleListMutationResult = Apollo.MutationResult<UpdatePeopleList>;
export type UpdatePeopleListMutationOptions = Apollo.BaseMutationOptions<
  UpdatePeopleList,
  UpdatePeopleListVariables
>;
export const GetDataObjectBreakdownComputedPreviewDocument = gql`
  query GetDataObjectBreakdownComputedPreview(
    $peopleListId: ID!
    $breakdownId: ID
    $breakdownDefinition: DataObjectBreakdownDefinitionInput
  ) {
    computeDataObjectBreakdownPreview(
      peopleListId: $peopleListId
      breakdownId: $breakdownId
      breakdownDefinition: $breakdownDefinition
    ) {
      pivotTable {
        ...BreakdownTable
      }
    }
  }
  ${BreakdownTable}
`;

/**
 * __useGetDataObjectBreakdownComputedPreview__
 *
 * To run a query within a React component, call `useGetDataObjectBreakdownComputedPreview` and pass it any options that fit your needs.
 * When your component renders, `useGetDataObjectBreakdownComputedPreview` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataObjectBreakdownComputedPreview({
 *   variables: {
 *      peopleListId: // value for 'peopleListId'
 *      breakdownId: // value for 'breakdownId'
 *      breakdownDefinition: // value for 'breakdownDefinition'
 *   },
 * });
 */
export function useGetDataObjectBreakdownComputedPreview(
  baseOptions: Apollo.QueryHookOptions<
    GetDataObjectBreakdownComputedPreview,
    GetDataObjectBreakdownComputedPreviewVariables
  > &
    (
      | { variables: GetDataObjectBreakdownComputedPreviewVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDataObjectBreakdownComputedPreview,
    GetDataObjectBreakdownComputedPreviewVariables
  >(GetDataObjectBreakdownComputedPreviewDocument, options);
}
export function useGetDataObjectBreakdownComputedPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataObjectBreakdownComputedPreview,
    GetDataObjectBreakdownComputedPreviewVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDataObjectBreakdownComputedPreview,
    GetDataObjectBreakdownComputedPreviewVariables
  >(GetDataObjectBreakdownComputedPreviewDocument, options);
}
export function useGetDataObjectBreakdownComputedPreviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDataObjectBreakdownComputedPreview,
    GetDataObjectBreakdownComputedPreviewVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDataObjectBreakdownComputedPreview,
    GetDataObjectBreakdownComputedPreviewVariables
  >(GetDataObjectBreakdownComputedPreviewDocument, options);
}
export type GetDataObjectBreakdownComputedPreviewHookResult = ReturnType<
  typeof useGetDataObjectBreakdownComputedPreview
>;
export type GetDataObjectBreakdownComputedPreviewLazyQueryHookResult = ReturnType<
  typeof useGetDataObjectBreakdownComputedPreviewLazyQuery
>;
export type GetDataObjectBreakdownComputedPreviewSuspenseQueryHookResult = ReturnType<
  typeof useGetDataObjectBreakdownComputedPreviewSuspenseQuery
>;
export type GetDataObjectBreakdownComputedPreviewQueryResult = Apollo.QueryResult<
  GetDataObjectBreakdownComputedPreview,
  GetDataObjectBreakdownComputedPreviewVariables
>;
export const GetDataObjectsForBreakdownsDocument = gql`
  query GetDataObjectsForBreakdowns($peopleListId: ID!) {
    dataObjectsForBreakdowns(peopleListId: $peopleListId) {
      edges {
        cursor
        node {
          id
          dataSource {
            name
          }
        }
      }
      nodes {
        id
        name
        dataSource {
          id
          name
        }
        schema {
          fields {
            ...DataObjectSchemaFieldMeta
          }
        }
      }
    }
  }
  ${DataObjectSchemaFieldMeta}
`;

/**
 * __useGetDataObjectsForBreakdowns__
 *
 * To run a query within a React component, call `useGetDataObjectsForBreakdowns` and pass it any options that fit your needs.
 * When your component renders, `useGetDataObjectsForBreakdowns` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataObjectsForBreakdowns({
 *   variables: {
 *      peopleListId: // value for 'peopleListId'
 *   },
 * });
 */
export function useGetDataObjectsForBreakdowns(
  baseOptions: Apollo.QueryHookOptions<
    GetDataObjectsForBreakdowns,
    GetDataObjectsForBreakdownsVariables
  > &
    ({ variables: GetDataObjectsForBreakdownsVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDataObjectsForBreakdowns, GetDataObjectsForBreakdownsVariables>(
    GetDataObjectsForBreakdownsDocument,
    options
  );
}
export function useGetDataObjectsForBreakdownsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataObjectsForBreakdowns,
    GetDataObjectsForBreakdownsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDataObjectsForBreakdowns, GetDataObjectsForBreakdownsVariables>(
    GetDataObjectsForBreakdownsDocument,
    options
  );
}
export function useGetDataObjectsForBreakdownsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDataObjectsForBreakdowns,
    GetDataObjectsForBreakdownsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDataObjectsForBreakdowns, GetDataObjectsForBreakdownsVariables>(
    GetDataObjectsForBreakdownsDocument,
    options
  );
}
export type GetDataObjectsForBreakdownsHookResult = ReturnType<
  typeof useGetDataObjectsForBreakdowns
>;
export type GetDataObjectsForBreakdownsLazyQueryHookResult = ReturnType<
  typeof useGetDataObjectsForBreakdownsLazyQuery
>;
export type GetDataObjectsForBreakdownsSuspenseQueryHookResult = ReturnType<
  typeof useGetDataObjectsForBreakdownsSuspenseQuery
>;
export type GetDataObjectsForBreakdownsQueryResult = Apollo.QueryResult<
  GetDataObjectsForBreakdowns,
  GetDataObjectsForBreakdownsVariables
>;
export const GetPeopleListBreakdownsByIdDocument = gql`
  query GetPeopleListBreakdownsById($id: ID!) {
    peopleList(id: $id) {
      id
      breakdowns {
        pivotTable {
          ...BreakdownTable
        }
        dataObjectBreakdown {
          ...BreakdownFragment
        }
      }
    }
  }
  ${BreakdownTable}
  ${BreakdownFragment}
  ${BreakdownsFieldFragment}
  ${DataObjectSchemaFieldMeta}
`;

/**
 * __useGetPeopleListBreakdownsById__
 *
 * To run a query within a React component, call `useGetPeopleListBreakdownsById` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleListBreakdownsById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleListBreakdownsById({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPeopleListBreakdownsById(
  baseOptions: Apollo.QueryHookOptions<
    GetPeopleListBreakdownsById,
    GetPeopleListBreakdownsByIdVariables
  > &
    ({ variables: GetPeopleListBreakdownsByIdVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeopleListBreakdownsById, GetPeopleListBreakdownsByIdVariables>(
    GetPeopleListBreakdownsByIdDocument,
    options
  );
}
export function useGetPeopleListBreakdownsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPeopleListBreakdownsById,
    GetPeopleListBreakdownsByIdVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeopleListBreakdownsById, GetPeopleListBreakdownsByIdVariables>(
    GetPeopleListBreakdownsByIdDocument,
    options
  );
}
export function useGetPeopleListBreakdownsByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPeopleListBreakdownsById,
    GetPeopleListBreakdownsByIdVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPeopleListBreakdownsById, GetPeopleListBreakdownsByIdVariables>(
    GetPeopleListBreakdownsByIdDocument,
    options
  );
}
export type GetPeopleListBreakdownsByIdHookResult = ReturnType<
  typeof useGetPeopleListBreakdownsById
>;
export type GetPeopleListBreakdownsByIdLazyQueryHookResult = ReturnType<
  typeof useGetPeopleListBreakdownsByIdLazyQuery
>;
export type GetPeopleListBreakdownsByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetPeopleListBreakdownsByIdSuspenseQuery
>;
export type GetPeopleListBreakdownsByIdQueryResult = Apollo.QueryResult<
  GetPeopleListBreakdownsById,
  GetPeopleListBreakdownsByIdVariables
>;
export const GetPeopleListByIdDocument = gql`
  query GetPeopleListById($id: ID!) {
    peopleList(id: $id) {
      ...PeopleListMeta
      creator {
        ...Creator
      }
      ...ShareRules
      nodeUserPreferences {
        favorite
      }
      state
      buildDefinition {
        steps {
          ...BuildDefinitionStepMeta
          fields {
            ...FieldMeta
          }
        }
      }
      downloadUrl {
        progress {
          ...DownloadUrlProgress
        }
        urlDetails {
          ...DownloadUrlDetails
        }
      }
      dataSources {
        id
      }
      dataObjectId
    }
  }
  ${PeopleListMeta}
  ${Creator}
  ${UserNameFragment}
  ${ShareRules}
  ${BuildDefinitionStepMeta}
  ${FieldMeta}
  ${DataObjectSchemaFieldMeta}
  ${RangeFragment}
  ${DownloadUrlProgress}
  ${DownloadUrlDetails}
`;

/**
 * __useGetPeopleListById__
 *
 * To run a query within a React component, call `useGetPeopleListById` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleListById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleListById({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPeopleListById(
  baseOptions: Apollo.QueryHookOptions<GetPeopleListById, GetPeopleListByIdVariables> &
    ({ variables: GetPeopleListByIdVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeopleListById, GetPeopleListByIdVariables>(
    GetPeopleListByIdDocument,
    options
  );
}
export function useGetPeopleListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPeopleListById, GetPeopleListByIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeopleListById, GetPeopleListByIdVariables>(
    GetPeopleListByIdDocument,
    options
  );
}
export function useGetPeopleListByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPeopleListById, GetPeopleListByIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPeopleListById, GetPeopleListByIdVariables>(
    GetPeopleListByIdDocument,
    options
  );
}
export type GetPeopleListByIdHookResult = ReturnType<typeof useGetPeopleListById>;
export type GetPeopleListByIdLazyQueryHookResult = ReturnType<typeof useGetPeopleListByIdLazyQuery>;
export type GetPeopleListByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetPeopleListByIdSuspenseQuery
>;
export type GetPeopleListByIdQueryResult = Apollo.QueryResult<
  GetPeopleListById,
  GetPeopleListByIdVariables
>;
export const GetPeopleListDownloadUrlDocument = gql`
  query GetPeopleListDownloadUrl($id: ID!) {
    peopleList(id: $id) {
      id
      downloadUrl {
        progress {
          ...DownloadUrlProgress
        }
        urlDetails {
          ...DownloadUrlDetails
        }
      }
    }
  }
  ${DownloadUrlProgress}
  ${DownloadUrlDetails}
`;

/**
 * __useGetPeopleListDownloadUrl__
 *
 * To run a query within a React component, call `useGetPeopleListDownloadUrl` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleListDownloadUrl` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleListDownloadUrl({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPeopleListDownloadUrl(
  baseOptions: Apollo.QueryHookOptions<
    GetPeopleListDownloadUrl,
    GetPeopleListDownloadUrlVariables
  > &
    ({ variables: GetPeopleListDownloadUrlVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeopleListDownloadUrl, GetPeopleListDownloadUrlVariables>(
    GetPeopleListDownloadUrlDocument,
    options
  );
}
export function useGetPeopleListDownloadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPeopleListDownloadUrl,
    GetPeopleListDownloadUrlVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeopleListDownloadUrl, GetPeopleListDownloadUrlVariables>(
    GetPeopleListDownloadUrlDocument,
    options
  );
}
export function useGetPeopleListDownloadUrlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPeopleListDownloadUrl,
    GetPeopleListDownloadUrlVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPeopleListDownloadUrl, GetPeopleListDownloadUrlVariables>(
    GetPeopleListDownloadUrlDocument,
    options
  );
}
export type GetPeopleListDownloadUrlHookResult = ReturnType<typeof useGetPeopleListDownloadUrl>;
export type GetPeopleListDownloadUrlLazyQueryHookResult = ReturnType<
  typeof useGetPeopleListDownloadUrlLazyQuery
>;
export type GetPeopleListDownloadUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetPeopleListDownloadUrlSuspenseQuery
>;
export type GetPeopleListDownloadUrlQueryResult = Apollo.QueryResult<
  GetPeopleListDownloadUrl,
  GetPeopleListDownloadUrlVariables
>;
export const GetPeopleListHydrationRunsDocument = gql`
  query GetPeopleListHydrationRuns(
    $id: ID!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    peopleList(id: $id) {
      id
      name
      peopleListHydratorRuns(first: $first, last: $last, after: $after, before: $before) {
        edges {
          node {
            ...PeopleListHydratorRunDetails
          }
          cursor
        }
        pageInfo {
          ...PageInfoFragment
        }
      }
    }
  }
  ${PeopleListHydratorRunDetails}
  ${Creator}
  ${UserNameFragment}
  ${PageInfoFragment}
`;

/**
 * __useGetPeopleListHydrationRuns__
 *
 * To run a query within a React component, call `useGetPeopleListHydrationRuns` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleListHydrationRuns` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleListHydrationRuns({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetPeopleListHydrationRuns(
  baseOptions: Apollo.QueryHookOptions<
    GetPeopleListHydrationRuns,
    GetPeopleListHydrationRunsVariables
  > &
    ({ variables: GetPeopleListHydrationRunsVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeopleListHydrationRuns, GetPeopleListHydrationRunsVariables>(
    GetPeopleListHydrationRunsDocument,
    options
  );
}
export function useGetPeopleListHydrationRunsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPeopleListHydrationRuns,
    GetPeopleListHydrationRunsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeopleListHydrationRuns, GetPeopleListHydrationRunsVariables>(
    GetPeopleListHydrationRunsDocument,
    options
  );
}
export function useGetPeopleListHydrationRunsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPeopleListHydrationRuns,
    GetPeopleListHydrationRunsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPeopleListHydrationRuns, GetPeopleListHydrationRunsVariables>(
    GetPeopleListHydrationRunsDocument,
    options
  );
}
export type GetPeopleListHydrationRunsHookResult = ReturnType<typeof useGetPeopleListHydrationRuns>;
export type GetPeopleListHydrationRunsLazyQueryHookResult = ReturnType<
  typeof useGetPeopleListHydrationRunsLazyQuery
>;
export type GetPeopleListHydrationRunsSuspenseQueryHookResult = ReturnType<
  typeof useGetPeopleListHydrationRunsSuspenseQuery
>;
export type GetPeopleListHydrationRunsQueryResult = Apollo.QueryResult<
  GetPeopleListHydrationRuns,
  GetPeopleListHydrationRunsVariables
>;
export const GetPeopleListHydratorRunByIdDocument = gql`
  query GetPeopleListHydratorRunById($id: ID!) {
    peopleListHydratorRun(id: $id) {
      id
      columns
      completedAt
      createdAt
      creator {
        email
        firstName
        id
        lastName
      }
      downloadUrl(ttl: 900) {
        expiration
        ttl
        url
      }
      estimatedCost
      matchedCount
      state
    }
  }
`;

/**
 * __useGetPeopleListHydratorRunById__
 *
 * To run a query within a React component, call `useGetPeopleListHydratorRunById` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleListHydratorRunById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleListHydratorRunById({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPeopleListHydratorRunById(
  baseOptions: Apollo.QueryHookOptions<
    GetPeopleListHydratorRunById,
    GetPeopleListHydratorRunByIdVariables
  > &
    ({ variables: GetPeopleListHydratorRunByIdVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeopleListHydratorRunById, GetPeopleListHydratorRunByIdVariables>(
    GetPeopleListHydratorRunByIdDocument,
    options
  );
}
export function useGetPeopleListHydratorRunByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPeopleListHydratorRunById,
    GetPeopleListHydratorRunByIdVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeopleListHydratorRunById, GetPeopleListHydratorRunByIdVariables>(
    GetPeopleListHydratorRunByIdDocument,
    options
  );
}
export function useGetPeopleListHydratorRunByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPeopleListHydratorRunById,
    GetPeopleListHydratorRunByIdVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPeopleListHydratorRunById,
    GetPeopleListHydratorRunByIdVariables
  >(GetPeopleListHydratorRunByIdDocument, options);
}
export type GetPeopleListHydratorRunByIdHookResult = ReturnType<
  typeof useGetPeopleListHydratorRunById
>;
export type GetPeopleListHydratorRunByIdLazyQueryHookResult = ReturnType<
  typeof useGetPeopleListHydratorRunByIdLazyQuery
>;
export type GetPeopleListHydratorRunByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetPeopleListHydratorRunByIdSuspenseQuery
>;
export type GetPeopleListHydratorRunByIdQueryResult = Apollo.QueryResult<
  GetPeopleListHydratorRunById,
  GetPeopleListHydratorRunByIdVariables
>;
export const GetPeopleListStatusDocument = gql`
  query GetPeopleListStatus($id: ID!) {
    peopleList(id: $id) {
      id
      state
    }
  }
`;

/**
 * __useGetPeopleListStatus__
 *
 * To run a query within a React component, call `useGetPeopleListStatus` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleListStatus` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleListStatus({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPeopleListStatus(
  baseOptions: Apollo.QueryHookOptions<GetPeopleListStatus, GetPeopleListStatusVariables> &
    ({ variables: GetPeopleListStatusVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeopleListStatus, GetPeopleListStatusVariables>(
    GetPeopleListStatusDocument,
    options
  );
}
export function useGetPeopleListStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPeopleListStatus, GetPeopleListStatusVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeopleListStatus, GetPeopleListStatusVariables>(
    GetPeopleListStatusDocument,
    options
  );
}
export function useGetPeopleListStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPeopleListStatus, GetPeopleListStatusVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPeopleListStatus, GetPeopleListStatusVariables>(
    GetPeopleListStatusDocument,
    options
  );
}
export type GetPeopleListStatusHookResult = ReturnType<typeof useGetPeopleListStatus>;
export type GetPeopleListStatusLazyQueryHookResult = ReturnType<
  typeof useGetPeopleListStatusLazyQuery
>;
export type GetPeopleListStatusSuspenseQueryHookResult = ReturnType<
  typeof useGetPeopleListStatusSuspenseQuery
>;
export type GetPeopleListStatusQueryResult = Apollo.QueryResult<
  GetPeopleListStatus,
  GetPeopleListStatusVariables
>;
export const GetPeopleListSummaryStatsDocument = gql`
  query GetPeopleListSummaryStats($id: ID!) {
    peopleList(id: $id) {
      id
      ...PeopleListSummaryStatsFragment
    }
  }
  ${PeopleListSummaryStatsFragment}
`;

/**
 * __useGetPeopleListSummaryStats__
 *
 * To run a query within a React component, call `useGetPeopleListSummaryStats` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleListSummaryStats` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleListSummaryStats({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPeopleListSummaryStats(
  baseOptions: Apollo.QueryHookOptions<
    GetPeopleListSummaryStats,
    GetPeopleListSummaryStatsVariables
  > &
    ({ variables: GetPeopleListSummaryStatsVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeopleListSummaryStats, GetPeopleListSummaryStatsVariables>(
    GetPeopleListSummaryStatsDocument,
    options
  );
}
export function useGetPeopleListSummaryStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPeopleListSummaryStats,
    GetPeopleListSummaryStatsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeopleListSummaryStats, GetPeopleListSummaryStatsVariables>(
    GetPeopleListSummaryStatsDocument,
    options
  );
}
export function useGetPeopleListSummaryStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPeopleListSummaryStats,
    GetPeopleListSummaryStatsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPeopleListSummaryStats, GetPeopleListSummaryStatsVariables>(
    GetPeopleListSummaryStatsDocument,
    options
  );
}
export type GetPeopleListSummaryStatsHookResult = ReturnType<typeof useGetPeopleListSummaryStats>;
export type GetPeopleListSummaryStatsLazyQueryHookResult = ReturnType<
  typeof useGetPeopleListSummaryStatsLazyQuery
>;
export type GetPeopleListSummaryStatsSuspenseQueryHookResult = ReturnType<
  typeof useGetPeopleListSummaryStatsSuspenseQuery
>;
export type GetPeopleListSummaryStatsQueryResult = Apollo.QueryResult<
  GetPeopleListSummaryStats,
  GetPeopleListSummaryStatsVariables
>;
export const GetPeopleListUserPreferencesDocument = gql`
  query GetPeopleListUserPreferences($id: ID!) {
    peopleList(id: $id) {
      id
      nodeUserPreferences {
        ...PeopleListUserPreferences
      }
    }
  }
  ${PeopleListUserPreferences}
`;

/**
 * __useGetPeopleListUserPreferences__
 *
 * To run a query within a React component, call `useGetPeopleListUserPreferences` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleListUserPreferences` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleListUserPreferences({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPeopleListUserPreferences(
  baseOptions: Apollo.QueryHookOptions<
    GetPeopleListUserPreferences,
    GetPeopleListUserPreferencesVariables
  > &
    ({ variables: GetPeopleListUserPreferencesVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeopleListUserPreferences, GetPeopleListUserPreferencesVariables>(
    GetPeopleListUserPreferencesDocument,
    options
  );
}
export function useGetPeopleListUserPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPeopleListUserPreferences,
    GetPeopleListUserPreferencesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeopleListUserPreferences, GetPeopleListUserPreferencesVariables>(
    GetPeopleListUserPreferencesDocument,
    options
  );
}
export function useGetPeopleListUserPreferencesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPeopleListUserPreferences,
    GetPeopleListUserPreferencesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPeopleListUserPreferences,
    GetPeopleListUserPreferencesVariables
  >(GetPeopleListUserPreferencesDocument, options);
}
export type GetPeopleListUserPreferencesHookResult = ReturnType<
  typeof useGetPeopleListUserPreferences
>;
export type GetPeopleListUserPreferencesLazyQueryHookResult = ReturnType<
  typeof useGetPeopleListUserPreferencesLazyQuery
>;
export type GetPeopleListUserPreferencesSuspenseQueryHookResult = ReturnType<
  typeof useGetPeopleListUserPreferencesSuspenseQuery
>;
export type GetPeopleListUserPreferencesQueryResult = Apollo.QueryResult<
  GetPeopleListUserPreferences,
  GetPeopleListUserPreferencesVariables
>;
export const GetPeopleListsDocument = gql`
  query GetPeopleLists(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filters: PeopleListFilterInput!
  ) {
    peopleLists(after: $after, before: $before, first: $first, last: $last, filters: $filters) {
      edges {
        cursor
        node {
          ...PeopleListMeta
          creator {
            ...Creator
          }
          ...ShareRules
          nodeUserPreferences {
            favorite
          }
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${PeopleListMeta}
  ${Creator}
  ${UserNameFragment}
  ${ShareRules}
  ${PageInfoFragment}
`;

/**
 * __useGetPeopleLists__
 *
 * To run a query within a React component, call `useGetPeopleLists` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleLists` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleLists({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPeopleLists(
  baseOptions: Apollo.QueryHookOptions<GetPeopleLists, GetPeopleListsVariables> &
    ({ variables: GetPeopleListsVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeopleLists, GetPeopleListsVariables>(GetPeopleListsDocument, options);
}
export function useGetPeopleListsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPeopleLists, GetPeopleListsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeopleLists, GetPeopleListsVariables>(
    GetPeopleListsDocument,
    options
  );
}
export function useGetPeopleListsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPeopleLists, GetPeopleListsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPeopleLists, GetPeopleListsVariables>(
    GetPeopleListsDocument,
    options
  );
}
export type GetPeopleListsHookResult = ReturnType<typeof useGetPeopleLists>;
export type GetPeopleListsLazyQueryHookResult = ReturnType<typeof useGetPeopleListsLazyQuery>;
export type GetPeopleListsSuspenseQueryHookResult = ReturnType<
  typeof useGetPeopleListsSuspenseQuery
>;
export type GetPeopleListsQueryResult = Apollo.QueryResult<GetPeopleLists, GetPeopleListsVariables>;
export const ValidateListStepDocument = gql`
  query ValidateListStep($id: ID, $definition: PeopleListBuildStepInput) {
    peopleListBuildStepValidator(id: $id, definition: $definition) {
      valid
      invalidValues {
        dataObjectSchemaFieldId
        message
        values
      }
    }
  }
`;

/**
 * __useValidateListStep__
 *
 * To run a query within a React component, call `useValidateListStep` and pass it any options that fit your needs.
 * When your component renders, `useValidateListStep` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateListStep({
 *   variables: {
 *      id: // value for 'id'
 *      definition: // value for 'definition'
 *   },
 * });
 */
export function useValidateListStep(
  baseOptions?: Apollo.QueryHookOptions<ValidateListStep, ValidateListStepVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateListStep, ValidateListStepVariables>(
    ValidateListStepDocument,
    options
  );
}
export function useValidateListStepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidateListStep, ValidateListStepVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidateListStep, ValidateListStepVariables>(
    ValidateListStepDocument,
    options
  );
}
export function useValidateListStepSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ValidateListStep, ValidateListStepVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ValidateListStep, ValidateListStepVariables>(
    ValidateListStepDocument,
    options
  );
}
export type ValidateListStepHookResult = ReturnType<typeof useValidateListStep>;
export type ValidateListStepLazyQueryHookResult = ReturnType<typeof useValidateListStepLazyQuery>;
export type ValidateListStepSuspenseQueryHookResult = ReturnType<
  typeof useValidateListStepSuspenseQuery
>;
export type ValidateListStepQueryResult = Apollo.QueryResult<
  ValidateListStep,
  ValidateListStepVariables
>;
export const GetGeoCountsByPeopleListIdDocument = gql`
  query GetGeoCountsByPeopleListId($peopleListId: ID!, $localityType: String, $unit: String) {
    geoCounts(peopleListId: $peopleListId, localityType: $localityType, unit: $unit) {
      localityType
      scores {
        id
        label
        value
      }
      unit
    }
  }
`;

/**
 * __useGetGeoCountsByPeopleListId__
 *
 * To run a query within a React component, call `useGetGeoCountsByPeopleListId` and pass it any options that fit your needs.
 * When your component renders, `useGetGeoCountsByPeopleListId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeoCountsByPeopleListId({
 *   variables: {
 *      peopleListId: // value for 'peopleListId'
 *      localityType: // value for 'localityType'
 *      unit: // value for 'unit'
 *   },
 * });
 */
export function useGetGeoCountsByPeopleListId(
  baseOptions: Apollo.QueryHookOptions<
    GetGeoCountsByPeopleListId,
    GetGeoCountsByPeopleListIdVariables
  > &
    ({ variables: GetGeoCountsByPeopleListIdVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGeoCountsByPeopleListId, GetGeoCountsByPeopleListIdVariables>(
    GetGeoCountsByPeopleListIdDocument,
    options
  );
}
export function useGetGeoCountsByPeopleListIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGeoCountsByPeopleListId,
    GetGeoCountsByPeopleListIdVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGeoCountsByPeopleListId, GetGeoCountsByPeopleListIdVariables>(
    GetGeoCountsByPeopleListIdDocument,
    options
  );
}
export function useGetGeoCountsByPeopleListIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetGeoCountsByPeopleListId,
    GetGeoCountsByPeopleListIdVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetGeoCountsByPeopleListId, GetGeoCountsByPeopleListIdVariables>(
    GetGeoCountsByPeopleListIdDocument,
    options
  );
}
export type GetGeoCountsByPeopleListIdHookResult = ReturnType<typeof useGetGeoCountsByPeopleListId>;
export type GetGeoCountsByPeopleListIdLazyQueryHookResult = ReturnType<
  typeof useGetGeoCountsByPeopleListIdLazyQuery
>;
export type GetGeoCountsByPeopleListIdSuspenseQueryHookResult = ReturnType<
  typeof useGetGeoCountsByPeopleListIdSuspenseQuery
>;
export type GetGeoCountsByPeopleListIdQueryResult = Apollo.QueryResult<
  GetGeoCountsByPeopleListId,
  GetGeoCountsByPeopleListIdVariables
>;
export const UpdateUserProfileDocument = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  UpdateUserProfile,
  UpdateUserProfileVariables
>;

/**
 * __useUpdateUserProfile__
 *
 * To run a mutation, you first call `useUpdateUserProfile` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfile` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfile, { data, loading, error }] = useUpdateUserProfile({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfile(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserProfile, UpdateUserProfileVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserProfile, UpdateUserProfileVariables>(
    UpdateUserProfileDocument,
    options
  );
}
export type UpdateUserProfileHookResult = ReturnType<typeof useUpdateUserProfile>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfile>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProfile,
  UpdateUserProfileVariables
>;
export const GetMyDetailsDocument = gql`
  query GetMyDetails {
    me {
      id
      ...UserNameFragment
      superadmin
      apiKeys {
        ...ApiKeyFragment
      }
      organizationInvites {
        id
        state
        organization {
          id
          name
        }
        role {
          id
          name
        }
      }
    }
    organizations {
      ...OrganizationBaseFields
    }
  }
  ${UserNameFragment}
  ${ApiKeyFragment}
  ${OrganizationBaseFields}
`;

/**
 * __useGetMyDetails__
 *
 * To run a query within a React component, call `useGetMyDetails` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDetails({
 *   variables: {
 *   },
 * });
 */
export function useGetMyDetails(
  baseOptions?: Apollo.QueryHookOptions<GetMyDetails, GetMyDetailsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyDetails, GetMyDetailsVariables>(GetMyDetailsDocument, options);
}
export function useGetMyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyDetails, GetMyDetailsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyDetails, GetMyDetailsVariables>(GetMyDetailsDocument, options);
}
export function useGetMyDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyDetails, GetMyDetailsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMyDetails, GetMyDetailsVariables>(
    GetMyDetailsDocument,
    options
  );
}
export type GetMyDetailsHookResult = ReturnType<typeof useGetMyDetails>;
export type GetMyDetailsLazyQueryHookResult = ReturnType<typeof useGetMyDetailsLazyQuery>;
export type GetMyDetailsSuspenseQueryHookResult = ReturnType<typeof useGetMyDetailsSuspenseQuery>;
export type GetMyDetailsQueryResult = Apollo.QueryResult<GetMyDetails, GetMyDetailsVariables>;
export const GetMyDetailsWithRoleDocument = gql`
  query GetMyDetailsWithRole {
    me {
      id
      ...UserNameFragment
      superadmin
      apiKeys {
        ...ApiKeyFragment
      }
      organizationInvites {
        id
        state
        organization {
          id
          name
        }
        role {
          id
          name
        }
      }
      role {
        ...RoleFragment
      }
    }
    organizations {
      ...OrganizationBaseFields
    }
  }
  ${UserNameFragment}
  ${ApiKeyFragment}
  ${OrganizationBaseFields}
  ${RoleFragment}
  ${RoleSettingsFragment}
`;

/**
 * __useGetMyDetailsWithRole__
 *
 * To run a query within a React component, call `useGetMyDetailsWithRole` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDetailsWithRole` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDetailsWithRole({
 *   variables: {
 *   },
 * });
 */
export function useGetMyDetailsWithRole(
  baseOptions?: Apollo.QueryHookOptions<GetMyDetailsWithRole, GetMyDetailsWithRoleVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyDetailsWithRole, GetMyDetailsWithRoleVariables>(
    GetMyDetailsWithRoleDocument,
    options
  );
}
export function useGetMyDetailsWithRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyDetailsWithRole, GetMyDetailsWithRoleVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyDetailsWithRole, GetMyDetailsWithRoleVariables>(
    GetMyDetailsWithRoleDocument,
    options
  );
}
export function useGetMyDetailsWithRoleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyDetailsWithRole, GetMyDetailsWithRoleVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMyDetailsWithRole, GetMyDetailsWithRoleVariables>(
    GetMyDetailsWithRoleDocument,
    options
  );
}
export type GetMyDetailsWithRoleHookResult = ReturnType<typeof useGetMyDetailsWithRole>;
export type GetMyDetailsWithRoleLazyQueryHookResult = ReturnType<
  typeof useGetMyDetailsWithRoleLazyQuery
>;
export type GetMyDetailsWithRoleSuspenseQueryHookResult = ReturnType<
  typeof useGetMyDetailsWithRoleSuspenseQuery
>;
export type GetMyDetailsWithRoleQueryResult = Apollo.QueryResult<
  GetMyDetailsWithRole,
  GetMyDetailsWithRoleVariables
>;
export const GetMyDetailsWithPrivilegesDocument = gql`
  query GetMyDetailsWithPrivileges {
    me {
      id
      apiKeys {
        ...ApiKeyFragment
      }
      ...UserNameFragment
      email
      superadmin
      userPrivileges
      organizationInvites {
        id
        state
        organization {
          id
          name
        }
        role {
          id
          name
        }
      }
      role {
        ...RoleFragment
      }
    }
    organizations {
      ...OrganizationBaseFields
    }
  }
  ${ApiKeyFragment}
  ${OrganizationBaseFields}
  ${UserNameFragment}
  ${RoleFragment}
  ${RoleSettingsFragment}
`;

/**
 * __useGetMyDetailsWithPrivileges__
 *
 * To run a query within a React component, call `useGetMyDetailsWithPrivileges` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDetailsWithPrivileges` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDetailsWithPrivileges({
 *   variables: {
 *   },
 * });
 */
export function useGetMyDetailsWithPrivileges(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyDetailsWithPrivileges,
    GetMyDetailsWithPrivilegesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyDetailsWithPrivileges, GetMyDetailsWithPrivilegesVariables>(
    GetMyDetailsWithPrivilegesDocument,
    options
  );
}
export function useGetMyDetailsWithPrivilegesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyDetailsWithPrivileges,
    GetMyDetailsWithPrivilegesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyDetailsWithPrivileges, GetMyDetailsWithPrivilegesVariables>(
    GetMyDetailsWithPrivilegesDocument,
    options
  );
}
export function useGetMyDetailsWithPrivilegesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyDetailsWithPrivileges,
    GetMyDetailsWithPrivilegesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMyDetailsWithPrivileges, GetMyDetailsWithPrivilegesVariables>(
    GetMyDetailsWithPrivilegesDocument,
    options
  );
}
export type GetMyDetailsWithPrivilegesHookResult = ReturnType<typeof useGetMyDetailsWithPrivileges>;
export type GetMyDetailsWithPrivilegesLazyQueryHookResult = ReturnType<
  typeof useGetMyDetailsWithPrivilegesLazyQuery
>;
export type GetMyDetailsWithPrivilegesSuspenseQueryHookResult = ReturnType<
  typeof useGetMyDetailsWithPrivilegesSuspenseQuery
>;
export type GetMyDetailsWithPrivilegesQueryResult = Apollo.QueryResult<
  GetMyDetailsWithPrivileges,
  GetMyDetailsWithPrivilegesVariables
>;
export const UpdateOrganizationSettingsDocument = gql`
  mutation UpdateOrganizationSettings($input: UpdateOrganizationSettingsInput!) {
    updateOrganizationSettings(input: $input) {
      errors {
        ...ErrorFragment
      }
      settings {
        modules
        defaultShareLevel
      }
    }
  }
  ${ErrorFragment}
`;
export type UpdateOrganizationSettingsMutationFn = Apollo.MutationFunction<
  UpdateOrganizationSettings,
  UpdateOrganizationSettingsVariables
>;

/**
 * __useUpdateOrganizationSettings__
 *
 * To run a mutation, you first call `useUpdateOrganizationSettings` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSettings` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSettings, { data, loading, error }] = useUpdateOrganizationSettings({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationSettings(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationSettings,
    UpdateOrganizationSettingsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationSettings, UpdateOrganizationSettingsVariables>(
    UpdateOrganizationSettingsDocument,
    options
  );
}
export type UpdateOrganizationSettingsHookResult = ReturnType<typeof useUpdateOrganizationSettings>;
export type UpdateOrganizationSettingsMutationResult =
  Apollo.MutationResult<UpdateOrganizationSettings>;
export type UpdateOrganizationSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationSettings,
  UpdateOrganizationSettingsVariables
>;
export const CreateApiKeyDocument = gql`
  mutation CreateApiKey($input: CreateApiKeyInput!) {
    createApiKey(input: $input) {
      token
      apiKey {
        ...ApiKeyFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ApiKeyFragment}
  ${OrganizationBaseFields}
  ${ErrorFragment}
`;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<CreateApiKey, CreateApiKeyVariables>;

/**
 * __useCreateApiKey__
 *
 * To run a mutation, you first call `useCreateApiKey` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKey` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKey, { data, loading, error }] = useCreateApiKey({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiKey(
  baseOptions?: Apollo.MutationHookOptions<CreateApiKey, CreateApiKeyVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApiKey, CreateApiKeyVariables>(CreateApiKeyDocument, options);
}
export type CreateApiKeyHookResult = ReturnType<typeof useCreateApiKey>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKey>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<
  CreateApiKey,
  CreateApiKeyVariables
>;
export const DeleteApiKeyDocument = gql`
  mutation DeleteApiKey($input: DeleteApiKeyInput!) {
    deleteApiKey(input: $input) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;
export type DeleteApiKeyMutationFn = Apollo.MutationFunction<DeleteApiKey, DeleteApiKeyVariables>;

/**
 * __useDeleteApiKey__
 *
 * To run a mutation, you first call `useDeleteApiKey` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKey` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKey, { data, loading, error }] = useDeleteApiKey({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApiKey(
  baseOptions?: Apollo.MutationHookOptions<DeleteApiKey, DeleteApiKeyVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteApiKey, DeleteApiKeyVariables>(DeleteApiKeyDocument, options);
}
export type DeleteApiKeyHookResult = ReturnType<typeof useDeleteApiKey>;
export type DeleteApiKeyMutationResult = Apollo.MutationResult<DeleteApiKey>;
export type DeleteApiKeyMutationOptions = Apollo.BaseMutationOptions<
  DeleteApiKey,
  DeleteApiKeyVariables
>;
export const DeleteUserFromOrganizationDocument = gql`
  mutation DeleteUserFromOrganization($input: DeleteUserFromOrganizationInput!) {
    deleteUserFromOrganization(input: $input) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;
export type DeleteUserFromOrganizationMutationFn = Apollo.MutationFunction<
  DeleteUserFromOrganization,
  DeleteUserFromOrganizationVariables
>;

/**
 * __useDeleteUserFromOrganization__
 *
 * To run a mutation, you first call `useDeleteUserFromOrganization` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFromOrganization` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFromOrganization, { data, loading, error }] = useDeleteUserFromOrganization({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserFromOrganization(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserFromOrganization,
    DeleteUserFromOrganizationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserFromOrganization, DeleteUserFromOrganizationVariables>(
    DeleteUserFromOrganizationDocument,
    options
  );
}
export type DeleteUserFromOrganizationHookResult = ReturnType<typeof useDeleteUserFromOrganization>;
export type DeleteUserFromOrganizationMutationResult =
  Apollo.MutationResult<DeleteUserFromOrganization>;
export type DeleteUserFromOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserFromOrganization,
  DeleteUserFromOrganizationVariables
>;
export const GetOrganizationUsersDocument = gql`
  query GetOrganizationUsers($id: ID!) {
    organization(id: $id) {
      id
      userRoles {
        id
        role {
          id
          name
        }
        user {
          id
          ...UserNameFragment
          apiKeys {
            ...ApiKeyFragment
          }
        }
      }
    }
  }
  ${UserNameFragment}
  ${ApiKeyFragment}
  ${OrganizationBaseFields}
`;

/**
 * __useGetOrganizationUsers__
 *
 * To run a query within a React component, call `useGetOrganizationUsers` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationUsers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationUsers({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationUsers(
  baseOptions: Apollo.QueryHookOptions<GetOrganizationUsers, GetOrganizationUsersVariables> &
    ({ variables: GetOrganizationUsersVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationUsers, GetOrganizationUsersVariables>(
    GetOrganizationUsersDocument,
    options
  );
}
export function useGetOrganizationUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationUsers, GetOrganizationUsersVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganizationUsers, GetOrganizationUsersVariables>(
    GetOrganizationUsersDocument,
    options
  );
}
export function useGetOrganizationUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganizationUsers, GetOrganizationUsersVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOrganizationUsers, GetOrganizationUsersVariables>(
    GetOrganizationUsersDocument,
    options
  );
}
export type GetOrganizationUsersHookResult = ReturnType<typeof useGetOrganizationUsers>;
export type GetOrganizationUsersLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationUsersLazyQuery
>;
export type GetOrganizationUsersSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationUsersSuspenseQuery
>;
export type GetOrganizationUsersQueryResult = Apollo.QueryResult<
  GetOrganizationUsers,
  GetOrganizationUsersVariables
>;
export const GetOrganizationsDocument = gql`
  query GetOrganizations {
    organizations {
      ...OrganizationBaseFields
    }
  }
  ${OrganizationBaseFields}
`;

/**
 * __useGetOrganizations__
 *
 * To run a query within a React component, call `useGetOrganizations` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizations` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizations({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizations(
  baseOptions?: Apollo.QueryHookOptions<GetOrganizations, GetOrganizationsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizations, GetOrganizationsVariables>(
    GetOrganizationsDocument,
    options
  );
}
export function useGetOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizations, GetOrganizationsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganizations, GetOrganizationsVariables>(
    GetOrganizationsDocument,
    options
  );
}
export function useGetOrganizationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganizations, GetOrganizationsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOrganizations, GetOrganizationsVariables>(
    GetOrganizationsDocument,
    options
  );
}
export type GetOrganizationsHookResult = ReturnType<typeof useGetOrganizations>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationsSuspenseQuery
>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<
  GetOrganizations,
  GetOrganizationsVariables
>;
export const GetPendingOrgInvitesDocument = gql`
  query GetPendingOrgInvites($id: ID!) {
    organization(id: $id) {
      id
      organizationInvites {
        id
        creator {
          id
          firstName
          lastName
          email
        }
        role {
          id
          name
        }
        email
        state
      }
    }
  }
`;

/**
 * __useGetPendingOrgInvites__
 *
 * To run a query within a React component, call `useGetPendingOrgInvites` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingOrgInvites` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingOrgInvites({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPendingOrgInvites(
  baseOptions: Apollo.QueryHookOptions<GetPendingOrgInvites, GetPendingOrgInvitesVariables> &
    ({ variables: GetPendingOrgInvitesVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPendingOrgInvites, GetPendingOrgInvitesVariables>(
    GetPendingOrgInvitesDocument,
    options
  );
}
export function useGetPendingOrgInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPendingOrgInvites, GetPendingOrgInvitesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPendingOrgInvites, GetPendingOrgInvitesVariables>(
    GetPendingOrgInvitesDocument,
    options
  );
}
export function useGetPendingOrgInvitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPendingOrgInvites, GetPendingOrgInvitesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPendingOrgInvites, GetPendingOrgInvitesVariables>(
    GetPendingOrgInvitesDocument,
    options
  );
}
export type GetPendingOrgInvitesHookResult = ReturnType<typeof useGetPendingOrgInvites>;
export type GetPendingOrgInvitesLazyQueryHookResult = ReturnType<
  typeof useGetPendingOrgInvitesLazyQuery
>;
export type GetPendingOrgInvitesSuspenseQueryHookResult = ReturnType<
  typeof useGetPendingOrgInvitesSuspenseQuery
>;
export type GetPendingOrgInvitesQueryResult = Apollo.QueryResult<
  GetPendingOrgInvites,
  GetPendingOrgInvitesVariables
>;
export const CreateRoleDocument = gql`
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      role {
        ...RoleFragment
      }
    }
  }
  ${RoleFragment}
  ${RoleSettingsFragment}
`;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRole, CreateRoleVariables>;

/**
 * __useCreateRole__
 *
 * To run a mutation, you first call `useCreateRole` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRole` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRole, { data, loading, error }] = useCreateRole({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRole(
  baseOptions?: Apollo.MutationHookOptions<CreateRole, CreateRoleVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRole, CreateRoleVariables>(CreateRoleDocument, options);
}
export type CreateRoleHookResult = ReturnType<typeof useCreateRole>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRole>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRole, CreateRoleVariables>;
export const DeleteRoleDocument = gql`
  mutation DeleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      errors {
        message
        path
      }
      success
    }
  }
`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRole, DeleteRoleVariables>;

/**
 * __useDeleteRole__
 *
 * To run a mutation, you first call `useDeleteRole` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRole` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRole, { data, loading, error }] = useDeleteRole({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRole(
  baseOptions?: Apollo.MutationHookOptions<DeleteRole, DeleteRoleVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRole, DeleteRoleVariables>(DeleteRoleDocument, options);
}
export type DeleteRoleHookResult = ReturnType<typeof useDeleteRole>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRole>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRole, DeleteRoleVariables>;
export const UpdateRoleDocument = gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      errors {
        message
        path
      }
      role {
        ...RoleFragment
      }
    }
  }
  ${RoleFragment}
  ${RoleSettingsFragment}
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRole, UpdateRoleVariables>;

/**
 * __useUpdateRole__
 *
 * To run a mutation, you first call `useUpdateRole` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRole` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRole, { data, loading, error }] = useUpdateRole({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRole(
  baseOptions?: Apollo.MutationHookOptions<UpdateRole, UpdateRoleVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRole, UpdateRoleVariables>(UpdateRoleDocument, options);
}
export type UpdateRoleHookResult = ReturnType<typeof useUpdateRole>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRole>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRole, UpdateRoleVariables>;
export const GetRolesDocument = gql`
  query GetRoles {
    roles {
      id
      name
      organizationId
      settings {
        ...RoleSettingsFragment
      }
    }
  }
  ${RoleSettingsFragment}
`;

/**
 * __useGetRoles__
 *
 * To run a query within a React component, call `useGetRoles` and pass it any options that fit your needs.
 * When your component renders, `useGetRoles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoles({
 *   variables: {
 *   },
 * });
 */
export function useGetRoles(baseOptions?: Apollo.QueryHookOptions<GetRoles, GetRolesVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoles, GetRolesVariables>(GetRolesDocument, options);
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoles, GetRolesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoles, GetRolesVariables>(GetRolesDocument, options);
}
export function useGetRolesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetRoles, GetRolesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRoles, GetRolesVariables>(GetRolesDocument, options);
}
export type GetRolesHookResult = ReturnType<typeof useGetRoles>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesSuspenseQueryHookResult = ReturnType<typeof useGetRolesSuspenseQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRoles, GetRolesVariables>;
export const UpdateShareSettingsDocument = gql`
  mutation UpdateShareSettings($input: UpdateNodeShareSettingsInput!) {
    updateNodeShareSettings(input: $input) {
      orgShareLevel
      shareRules {
        user {
          ...UserNameFragment
        }
        permission
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${UserNameFragment}
  ${ErrorFragment}
`;
export type UpdateShareSettingsMutationFn = Apollo.MutationFunction<
  UpdateShareSettings,
  UpdateShareSettingsVariables
>;

/**
 * __useUpdateShareSettings__
 *
 * To run a mutation, you first call `useUpdateShareSettings` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareSettings` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareSettings, { data, loading, error }] = useUpdateShareSettings({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShareSettings(
  baseOptions?: Apollo.MutationHookOptions<UpdateShareSettings, UpdateShareSettingsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateShareSettings, UpdateShareSettingsVariables>(
    UpdateShareSettingsDocument,
    options
  );
}
export type UpdateShareSettingsHookResult = ReturnType<typeof useUpdateShareSettings>;
export type UpdateShareSettingsMutationResult = Apollo.MutationResult<UpdateShareSettings>;
export type UpdateShareSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateShareSettings,
  UpdateShareSettingsVariables
>;
export const CreateUploadAuthorizationDocument = gql`
  mutation CreateUploadAuthorization($input: CreateUploadAuthorizationInput!) {
    createUploadAuthorization(input: $input) {
      fields
      headers
      httpMethod
      uploadedFileId
      url
    }
  }
`;
export type CreateUploadAuthorizationMutationFn = Apollo.MutationFunction<
  CreateUploadAuthorization,
  CreateUploadAuthorizationVariables
>;

/**
 * __useCreateUploadAuthorization__
 *
 * To run a mutation, you first call `useCreateUploadAuthorization` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadAuthorization` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadAuthorization, { data, loading, error }] = useCreateUploadAuthorization({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUploadAuthorization(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUploadAuthorization,
    CreateUploadAuthorizationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUploadAuthorization, CreateUploadAuthorizationVariables>(
    CreateUploadAuthorizationDocument,
    options
  );
}
export type CreateUploadAuthorizationHookResult = ReturnType<typeof useCreateUploadAuthorization>;
export type CreateUploadAuthorizationMutationResult =
  Apollo.MutationResult<CreateUploadAuthorization>;
export type CreateUploadAuthorizationMutationOptions = Apollo.BaseMutationOptions<
  CreateUploadAuthorization,
  CreateUploadAuthorizationVariables
>;
export const FinalizeUploadDocument = gql`
  mutation FinalizeUpload($input: FinalizeUploadInput!) {
    finalizeUpload(input: $input) {
      errors {
        message
        path
      }
      success
      uploadedFile {
        id
        creator {
          id
          ...UserNameFragment
        }
        name
        orgShareLevel
        createdAt
        updatedAt
      }
    }
  }
  ${UserNameFragment}
`;
export type FinalizeUploadMutationFn = Apollo.MutationFunction<
  FinalizeUpload,
  FinalizeUploadVariables
>;

/**
 * __useFinalizeUpload__
 *
 * To run a mutation, you first call `useFinalizeUpload` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeUpload` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeUpload, { data, loading, error }] = useFinalizeUpload({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinalizeUpload(
  baseOptions?: Apollo.MutationHookOptions<FinalizeUpload, FinalizeUploadVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinalizeUpload, FinalizeUploadVariables>(
    FinalizeUploadDocument,
    options
  );
}
export type FinalizeUploadHookResult = ReturnType<typeof useFinalizeUpload>;
export type FinalizeUploadMutationResult = Apollo.MutationResult<FinalizeUpload>;
export type FinalizeUploadMutationOptions = Apollo.BaseMutationOptions<
  FinalizeUpload,
  FinalizeUploadVariables
>;
export const InviteUserToOrganizationDocument = gql`
  mutation InviteUserToOrganization($input: InviteUserToOrganizationInput!) {
    inviteUserToOrganization(input: $input) {
      errors {
        ...ErrorFragment
      }
      organizationInvite {
        id
        creator {
          firstName
          lastName
        }
        email
        role {
          ...RoleFragment
        }
        state
      }
    }
  }
  ${ErrorFragment}
  ${RoleFragment}
  ${RoleSettingsFragment}
`;
export type InviteUserToOrganizationMutationFn = Apollo.MutationFunction<
  InviteUserToOrganization,
  InviteUserToOrganizationVariables
>;

/**
 * __useInviteUserToOrganization__
 *
 * To run a mutation, you first call `useInviteUserToOrganization` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserToOrganization` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserToOrganization, { data, loading, error }] = useInviteUserToOrganization({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserToOrganization(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUserToOrganization,
    InviteUserToOrganizationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserToOrganization, InviteUserToOrganizationVariables>(
    InviteUserToOrganizationDocument,
    options
  );
}
export type InviteUserToOrganizationHookResult = ReturnType<typeof useInviteUserToOrganization>;
export type InviteUserToOrganizationMutationResult =
  Apollo.MutationResult<InviteUserToOrganization>;
export type InviteUserToOrganizationMutationOptions = Apollo.BaseMutationOptions<
  InviteUserToOrganization,
  InviteUserToOrganizationVariables
>;
export const UpdateUserRoleDocument = gql`
  mutation UpdateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      errors {
        message
        path
      }
      userRole {
        id
        role {
          id
          name
        }
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;
export type UpdateUserRoleMutationFn = Apollo.MutationFunction<
  UpdateUserRole,
  UpdateUserRoleVariables
>;

/**
 * __useUpdateUserRole__
 *
 * To run a mutation, you first call `useUpdateUserRole` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRole` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRole, { data, loading, error }] = useUpdateUserRole({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRole(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserRole, UpdateUserRoleVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserRole, UpdateUserRoleVariables>(
    UpdateUserRoleDocument,
    options
  );
}
export type UpdateUserRoleHookResult = ReturnType<typeof useUpdateUserRole>;
export type UpdateUserRoleMutationResult = Apollo.MutationResult<UpdateUserRole>;
export type UpdateUserRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserRole,
  UpdateUserRoleVariables
>;
export const GetUsersWithRolesDocument = gql`
  query GetUsersWithRoles {
    users {
      ...UserNameFragment
      role {
        name
      }
    }
  }
  ${UserNameFragment}
`;

/**
 * __useGetUsersWithRoles__
 *
 * To run a query within a React component, call `useGetUsersWithRoles` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersWithRoles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersWithRoles({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersWithRoles(
  baseOptions?: Apollo.QueryHookOptions<GetUsersWithRoles, GetUsersWithRolesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersWithRoles, GetUsersWithRolesVariables>(
    GetUsersWithRolesDocument,
    options
  );
}
export function useGetUsersWithRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersWithRoles, GetUsersWithRolesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersWithRoles, GetUsersWithRolesVariables>(
    GetUsersWithRolesDocument,
    options
  );
}
export function useGetUsersWithRolesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersWithRoles, GetUsersWithRolesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUsersWithRoles, GetUsersWithRolesVariables>(
    GetUsersWithRolesDocument,
    options
  );
}
export type GetUsersWithRolesHookResult = ReturnType<typeof useGetUsersWithRoles>;
export type GetUsersWithRolesLazyQueryHookResult = ReturnType<typeof useGetUsersWithRolesLazyQuery>;
export type GetUsersWithRolesSuspenseQueryHookResult = ReturnType<
  typeof useGetUsersWithRolesSuspenseQuery
>;
export type GetUsersWithRolesQueryResult = Apollo.QueryResult<
  GetUsersWithRoles,
  GetUsersWithRolesVariables
>;
