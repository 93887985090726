import { gql } from '@apollo/client';
import { USER_NAME_FRAGMENT } from '../../users/UserFragments';
import { API_KEY_FRAGMENT } from '../OrganizationFragments';

export const GET_ORGANIZATION_USERS = gql`
  query GetOrganizationUsers($id: ID!) {
    organization(id: $id) {
      id
      userRoles {
        id
        role {
          id
          name
        }
        user {
          id
          ...UserNameFragment
          apiKeys {
            ...ApiKeyFragment
          }
        }
      }
    }
  }

  ${USER_NAME_FRAGMENT}
  ${API_KEY_FRAGMENT}
`;
