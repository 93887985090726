import { IntrinsicChildrenProps } from '@src/types';
import React from 'react';

interface CardProps extends IntrinsicChildrenProps {
  className?: string;
}

export const Card: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div
      className={`tw-bg-white tw-rounded-lg tw-shadow tw-w-full tw-py-6 tw-px-6 tw-mb-4 ${
        className ?? ''
      }`}
    >
      {children}
    </div>
  );
};
