import { useLazyQuery, LazyQueryResultTuple } from '@apollo/client';
import { GET_PEOPLE_LIST_USER_PREFERENCES } from './queries/GetPeopleListUserPreferences';
import { GetPeopleListUserPreferences, GetPeopleListUserPreferencesVariables } from '@gql';

type UseUpdateListUserPreferencesSignature = (
  listId: string
) => LazyQueryResultTuple<GetPeopleListUserPreferences, GetPeopleListUserPreferencesVariables>;

export const useUpdateListUserPreferences: UseUpdateListUserPreferencesSignature = listId => {
  return useLazyQuery<GetPeopleListUserPreferences, GetPeopleListUserPreferencesVariables>(
    GET_PEOPLE_LIST_USER_PREFERENCES,
    {
      variables: {
        id: listId
      },
      fetchPolicy: 'network-only'
    }
  );
};
