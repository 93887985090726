import * as React from 'react';
import {
  Listbox,
  ListboxButton,
  Label,
  ListboxOption,
  ListboxOptions,
  Field
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '@helpers/classNames';
import { useTranslation } from 'react-i18next';

export type DropdownOption = {
  label: string | (() => JSX.Element);
  value: string;
};

type Sizes = 'sm' | 'default';

export type DropdownProps = {
  label?: string | React.ReactNode;
  isDisabled?: boolean;
  multiple?: boolean;
  name: string;
  onChange: ((optionValue: string) => void) | ((optionValue: string[]) => void);
  options: DropdownOption[];
  placeholder?: string;
  value?: string | string[];
  size?: Sizes;
};

const { Fragment } = React;

export const Dropdown: React.FC<DropdownProps> = ({
  label,
  isDisabled = false,
  multiple = false,
  name,
  onChange,
  options,
  placeholder,
  value,
  size
}) => {
  let initialButtonLabel: React.ReactChild | React.ReactFragment | React.ReactPortal;
  const { t } = useTranslation();

  if (multiple && Array.isArray(value) && value.length) {
    const selectedOptions = value
      .map(val => {
        return options.filter(opt => opt.value === val);
      })
      .flat()
      .map(opt => opt.label)
      .join(', ');

    initialButtonLabel = selectedOptions;
  } else {
    const currentSingleSelectValue = options.find(opt => opt.value === value);
    if (currentSingleSelectValue?.label) {
      initialButtonLabel = String(currentSingleSelectValue.label);
    } else if (placeholder) {
      initialButtonLabel = placeholder;
    } else {
      initialButtonLabel = t('components.dropdown.defaultPlaceholder') ?? '';
    }
  }

  const getSizeClasses = (size?: Sizes) => {
    if (size === 'sm') {
      return 'tw-py-1 tw-pl-2 tw-pr-8';
    }

    return 'tw-py-2 tw-pl-3 tw-pr-10';
  };

  const sizeClasses = getSizeClasses(size);

  return (
    <Field>
      {label ? (
        <Label
          data-testid={`dropdown:${name}:label`}
          className="tw-block tw-text-sm tw-mb-2 tw-font-medium tw-leading-6 tw-text-gray-900"
        >
          {label}
        </Label>
      ) : null}
      <Listbox
        value={value}
        onChange={onChange}
        disabled={isDisabled || options.length === 0}
        multiple={multiple}
      >
        <div className="tw-relative">
          <ListboxButton
            data-testid={`dropdown:${name}:button`}
            className={({ disabled }) =>
              classNames(
                disabled ? 'tw-cursor-not-allowed tw-bg-gray-100' : 'tw-bg-white tw-cursor-pointer',
                `tw-relative tw-w-full tw-rounded-md  ${sizeClasses} tw-text-left tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`
              )
            }
          >
            <span className={classNames('tw-block tw-truncate', size === 'sm' ? 'tw-text-sm' : '')}>
              {initialButtonLabel}
            </span>
            <span className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
              <ChevronUpDownIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <ListboxOptions
            data-testid={`dropdown:${name}:menu`}
            transition
            className="tw-origin-top tw-transition tw-duration-200 tw-ease-out data-[closed]:tw-scale-95 data-[closed]:tw-opacity-0 tw-absolute tw-z-10 tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-text-sm"
          >
            {options.map(opt => (
              <ListboxOption
                key={opt.value}
                value={opt.value}
                className={({ focus, selected }) =>
                  classNames(
                    selected && !focus ? 'tw-bg-nrs-indigo/25' : '',
                    focus ? 'tw-bg-nrs-indigo tw-text-white' : 'tw-text-gray-900',
                    'tw-relative tw-cursor-pointer tw-select-none tw-py-2 tw-pl-3 tw-pr-9'
                  )
                }
              >
                {({ selected, focus }) => (
                  <>
                    <span
                      data-testid="dropdown:option:label"
                      className={classNames(
                        selected ? 'tw-font-semibold' : 'tw-font-normal',
                        'tw-block tw-truncate'
                      )}
                    >
                      {typeof opt.label === 'function' ? opt.label() : opt.label}
                    </span>

                    {selected ? (
                      <span
                        className={classNames(
                          focus ? 'tw-text-white' : 'tw-text-nrs-indigo',
                          'tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4'
                        )}
                      >
                        <CheckIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    </Field>
  );
};
