import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import ChevronUpDownIcon from '@heroicons/react/20/solid/ChevronUpDownIcon';
import {
  Field,
  Combobox,
  Label,
  ComboboxInput,
  ComboboxButton,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/react';
import { classNames } from '@helpers/classNames';

type ShareModalUserSelectProps = {
  users: ShareModalUser[];
  onChange: (user: ShareModalUser) => void;
};

export type ShareModalUser = {
  id: string;
  name: string;
  email: string;
  current: boolean;
};

export type ShareModalUserSelectRef = {
  clearSelectedUser: () => void;
};

const defaultUserData: ShareModalUser = {
  id: '',
  name: '',
  email: '',
  current: false
};

export const ShareModalUserSelect: React.ForwardRefExoticComponent<
  ShareModalUserSelectProps & React.RefAttributes<ShareModalUserSelectRef>
> = forwardRef<ShareModalUserSelectRef, ShareModalUserSelectProps>(({ users, onChange }, ref) => {
  const { t } = useTranslation();
  const [query, setQuery] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState<ShareModalUser>(defaultUserData);

  const filteredUsers =
    query === ''
      ? users
      : users.filter((users: ShareModalUser) => {
          return users.name.toLowerCase().includes(query.toLowerCase());
        });

  useImperativeHandle(ref, () => {
    return {
      clearSelectedUser() {
        setSelectedUser(defaultUserData);
      }
    };
  });

  return (
    <div className="tw-mb-4">
      <Field>
        <Combobox
          as="div"
          immediate
          value={selectedUser}
          onChange={(selected: ShareModalUser) => {
            setSelectedUser(selected);
            onChange(selected);
          }}
        >
          <Label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
            {t('components.share.userSelectLabel')}
          </Label>
          <div className="tw-relative tw-mt-2">
            <ComboboxInput
              className="tw-w-full tw-rounded-md tw-border-0 tw-bg-white tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-indigo-600 tw-sm:text-sm tw-sm:leading-6"
              onChange={event => setQuery(event.target.value)}
              displayValue={(user: ShareModalUser) => user?.name}
              placeholder={t('components.share.userSelectPlaceholder')}
              data-testid="shareModalUserSelect:input"
            />
            <ComboboxButton
              className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-rounded-r-md tw-px-2 tw-focus:outline-none"
              data-testid="shareModalUserSelect:comboButton"
            >
              <ChevronUpDownIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
            </ComboboxButton>

            {filteredUsers.length > 0 && (
              <ComboboxOptions className="tw-absolute tw-z-10 tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none tw-sm:text-sm">
                {filteredUsers.map((user: ShareModalUser) => (
                  <ComboboxOption
                    key={user.id}
                    id={user.id}
                    value={user}
                    className={({ active }) =>
                      classNames(
                        'tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-8 tw-pr-4',
                        active ? 'tw-bg-indigo-600 tw-text-white' : 'tw-text-gray-900'
                      )
                    }
                    data-testid="shareModalUserSelect:userOption"
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={classNames(
                            'tw-block tw-truncate',
                            selected ? 'tw-font-semibold' : ''
                          )}
                        >
                          {user.name}
                        </span>
                      </>
                    )}
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            )}
          </div>
        </Combobox>
      </Field>
    </div>
  );
});
