export const BASE_URL = process.env.BASE_URL;
export const API_URL = process.env.API_URL;

const GRAPHQL_URI = process.env.GRAPHQL_URI;
export const GRAPHQL_URL = `${API_URL}${GRAPHQL_URI}`;

export const AWS_AMP_REGION = process.env.AWS_AMP_REGION;
export const AWS_AMP_USER_POOL_ID = process.env.AWS_AMP_USER_POOL_ID;
export const AWS_AMP_USER_POOL_CLIENT_ID = process.env.AWS_AMP_USER_POOL_CLIENT_ID;

export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;

export const SENTRY_DSN = process.env.SENTRY_DSN;
export const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT;

export const VERSION = process.env.VERSION;
