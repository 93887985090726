import { ORG_STORE_KEY } from '@config/store-keys';
import { localStore } from '@utils/storage';
import { Organization } from '@contexts/OrganizationContext';

export const getOrganizationFromStorage = (): Organization | null => {
  const storedOrgEncoded: string | null = localStore.getItem<string>(ORG_STORE_KEY);
  if (storedOrgEncoded) {
    try {
      const decodedOrg = atob(storedOrgEncoded);
      const organization = JSON.parse(decodedOrg);
      return organization;
    } catch (err) {
      console.error('Error: could not parse organization object');
    }
  }

  return null;
};
