import React from 'react';
import { Icon, IconProps } from './Icon';

export const LessThanIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="currentColor"
      viewBox="0 0 20 20"
      stroke="none"
    >
      <path d="M14.8,5.65l-6.62,6A2.16,2.16,0,0,1,5,11.48,2.17,2.17,0,0,1,5.1,8.25l6.61-6a2.16,2.16,0,0,1,3.23.15A2.15,2.15,0,0,1,14.8,5.65Z" />
      <path d="M15.05,17.57a2.16,2.16,0,0,1-3.23.15l-6.62-6a2.17,2.17,0,0,1-.14-3.23,2.16,2.16,0,0,1,3.23-.14l6.61,6A2.15,2.15,0,0,1,15.05,17.57Z" />
    </svg>
  </Icon>
);
