import { gql } from '@apollo/client';

export const ORGANIZATION_BASE_FIELDS = gql`
  fragment OrganizationBaseFields on Organization {
    id
    name
    settings {
      modules
      defaultShareLevel
      hydratorColumns
    }
  }
`;

export const API_KEY_FRAGMENT = gql`
  fragment ApiKeyFragment on ApiKey {
    id
    exp
    label
    jti
    organization {
      ...OrganizationBaseFields
    }
  }
  ${ORGANIZATION_BASE_FIELDS}
`;
