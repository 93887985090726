import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Button, ButtonKind } from '@components/Button';
import {
  OrganizationInvite,
  ORGANIZATION_ACTION_TYPES,
  useOrganizationContext
} from '@contexts/OrganizationContext';
import { CardTitle } from '@components/CardTitle';
import { ACCEPT_ORG_INVITE, DECLINE_ORG_INVITE } from '@data/invites/mutations';
import {
  AcceptOrgInvite,
  AcceptOrgInviteVariables,
  DeclineOrgInvite,
  DeclineOrgInviteVariables
} from '@gql';

type OrganizationInvitesProps = {
  invites: OrganizationInvite[];
};

export const OrganizationInvites: React.FC<OrganizationInvitesProps> = ({ invites = [] }) => {
  const { t } = useTranslation();
  const [state, dispatch] = useOrganizationContext();
  const [acceptInvite] = useMutation<AcceptOrgInvite, AcceptOrgInviteVariables>(ACCEPT_ORG_INVITE, {
    refetchQueries: ['GetOrganizations']
  });
  const [declineInvite] = useMutation<DeclineOrgInvite, DeclineOrgInviteVariables>(
    DECLINE_ORG_INVITE,
    {
      refetchQueries: ['GetOrganizations']
    }
  );

  const updateInviteList = useCallback(
    (id: string) => {
      const nextInvites = state.invites.filter(invite => invite.id !== id);
      dispatch({
        type: ORGANIZATION_ACTION_TYPES.SET_ORGANIZATION_INVITES,
        payload: {
          invites: nextInvites
        }
      });
    },
    [state.invites, dispatch]
  );

  const handleAcceptInvite = async (inviteId: string) => {
    try {
      await acceptInvite({
        variables: {
          input: {
            organizationInviteId: inviteId
          }
        }
      });

      updateInviteList(inviteId);
    } catch (err) {
      // handled elsewhere
    }
  };

  const handleDeclineInvite = async (inviteId: string) => {
    try {
      await declineInvite({
        variables: {
          input: {
            organizationInviteId: inviteId
          }
        }
      });

      updateInviteList(inviteId);
    } catch (err) {
      // handled elsewhere
    }
  };

  return (
    <div className="tw-mb-6" data-testid="org:invite">
      <CardTitle>{t('organization.pages.select.pendingInvitesTitle')}</CardTitle>
      {invites.map(invite => (
        <div
          className="tw-border-t tw-border-b tw-py-10 tw-flex tw-justify-between tw-px-6"
          key={invite.id}
        >
          <div className="tw-text-2xl">{invite.organization.name}</div>
          <div className="tw-flex tw-justify-end tw-space-x-2">
            <Button kind={ButtonKind.success} onClick={() => handleAcceptInvite(invite.id)}>
              {t('organization.pages.select.acceptInviteBtn')}
            </Button>
            <Button kind={ButtonKind.error} onClick={() => handleDeclineInvite(invite.id)}>
              {t('organization.pages.select.declineInviteBtn')}
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};
