const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  content: ['./src/**/*.ts', './src/**/*.tsx'],
  prefix: 'tw-',
  theme: {
    extend: {
      fontFamily: {
        sans: ['"Noto Sans Variable"', ...defaultTheme.fontFamily.sans]
      },
      colors: {
        'nrs-gray': {
          dark: '#051622ff',
          DEFAULT: '#212221ff',
          light: '#666666ff'
        },
        'nrs-blue': {
          dark: '#00458bff',
          DEFAULT: '#1181b2ff',
          light: '#99ddffff',
          'light-alt': '#81bfdbff'
        },
        'nrs-green': {
          dark: '#006837ff',
          DEFAULT: '#339966ff',
          light: '#79ccacff'
        },
        'nrs-orange': {
          DEFAULT: '#ff9900'
        },
        'nrs-salmon': {
          DEFAULT: '#ff7490'
        },
        'nrs-pink': {
          DEFAULT: '#b4a7d6'
        },
        'nrs-periwinkle': {
          DEFAULT: '#6666ff',
          light: '#cfcfff',
          xlight: '#ebebff'
        },
        'nrs-indigo': {
          DEFAULT: '#333399',
          dark: '#262673'
        }
      },
      typography: theme => ({
        DEFAULT: {
          css: {
            color: theme('colors.nrs-gray.DEFAULT')
          }
        }
      }),
      containers: {
        '2xs': '16rem'
      }
    }
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/container-queries')
  ]
};
