import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';
import { USER_NAME_FRAGMENT } from '@data/users';

export const UPDATE_SHARE_SETTINGS = gql`
  mutation UpdateShareSettings($input: UpdateNodeShareSettingsInput!) {
    updateNodeShareSettings(input: $input) {
      orgShareLevel
      shareRules {
        user {
          ...UserNameFragment
        }

        permission
      }
      errors {
        ...ErrorFragment
      }
    }
  }

  ${USER_NAME_FRAGMENT}
  ${ERROR_FRAGMENT}
`;
