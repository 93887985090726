import { gql } from '@apollo/client';
import { CREATOR_FRAGMENT } from '../../users/CreatorFragment';
import {
  DOWNLOAD_URL_DETAILS,
  DOWNLOAD_URL_PROGRESS,
  PEOPLE_LIST_META,
  PEOPLE_LIST_SHARE_RULES
} from '../PeopleListFragments';
import { BUILD_DEFINITION_STEP_META, FIELD_META } from '../PeopleListFragments';

export const GET_PEOPLE_LIST_BY_ID = gql`
  query GetPeopleListById($id: ID!) {
    peopleList(id: $id) {
      ...PeopleListMeta
      creator {
        ...Creator
      }
      ...ShareRules
      nodeUserPreferences {
        favorite
      }
      state
      buildDefinition {
        steps {
          ...BuildDefinitionStepMeta
          fields {
            ...FieldMeta
          }
        }
      }
      downloadUrl {
        progress {
          ...DownloadUrlProgress
        }
        urlDetails {
          ...DownloadUrlDetails
        }
      }
      dataSources {
        id
      }
      dataObjectId
    }
  }

  ${PEOPLE_LIST_META}
  ${CREATOR_FRAGMENT}
  ${BUILD_DEFINITION_STEP_META}
  ${FIELD_META}
  ${DOWNLOAD_URL_PROGRESS}
  ${DOWNLOAD_URL_DETAILS}
  ${PEOPLE_LIST_SHARE_RULES}
`;
