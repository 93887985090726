import { ApolloClient, InMemoryCache, HttpLink, from, ApolloLink } from '@apollo/client';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { setContext } from '@apollo/client/link/context';
import { GraphQLFormattedError } from 'graphql';
import { getSessionToken } from './getSessionToken';
import { GRAPHQL_URL } from '@utils/env';
import { getOrganizationFromStorage } from './getOrganizationFromStorage';

type AppHeaders = Headers & {
  'x-org-id'?: string;
};

const authMiddleware = setContext(getSessionToken);

const onErrorHandler = ({ graphQLErrors, networkError }: ErrorResponse) => {
  if (graphQLErrors && process.env.NODE_ENV === 'development')
    graphQLErrors.map(({ message, locations, path }: GraphQLFormattedError) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

  if (networkError) console.error(`[Network error]: ${networkError}`);
};

const headerLink = new ApolloLink((operation, forward) => {
  const context = operation.getContext();
  operation.setContext(({ headers }: Request) => {
    const storedOrg = getOrganizationFromStorage();
    let nextHeaders: AppHeaders = headers;
    if (storedOrg) {
      nextHeaders = {
        ...headers,
        'x-org-id': storedOrg.id
      };
    }

    if (!context.token) {
      return {
        headers: nextHeaders
      };
    }

    return {
      headers: {
        ...nextHeaders,
        Authorization: `Bearer ${context.token}`
      }
    };
  });
  return forward(operation);
});

const removeTypenameLink = removeTypenameFromVariables();

const client = from([
  removeTypenameLink,
  onError(onErrorHandler),
  authMiddleware,
  headerLink,
  new HttpLink({
    uri: GRAPHQL_URL
  })
]);

export const graphQLClient = new ApolloClient({
  link: client,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === 'development'
});
