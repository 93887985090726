import { gql } from '@apollo/client';
import { FOLDER_DETAILS } from '../FolderFragments';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';

export const CREATE_FOLDER = gql`
  mutation CreateFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      folder {
        ...FolderDetails
      }
      errors {
        ...ErrorFragment
      }
    }
  }

  ${FOLDER_DETAILS}
  ${ERROR_FRAGMENT}
`;
