import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MY_DETAILS, GET_MY_DETAILS_WITH_PRIVILEGES } from '@data/me';
import { ORGANIZATION_ACTION_TYPES, useOrganizationContext } from '../OrganizationContext';
import { BluebellPrivilege, OrganizationInviteState, GetMyDetailsWithPrivileges } from '@gql';
import {
  CurrentUserMeta,
  CURRENT_USER_ACTION_TYPES,
  CurrentUser
} from './CurrentUserContext.types';
import { CurrentUserReducer, initialCurrentUserState } from './CurrentUserReducer';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { isAuthenticated } from '@helpers/auth';
import { IntrinsicChildrenProps } from '@src/types';

export type ICurrentUserContext = {
  user: CurrentUser | null;
  getUserPrivileges: () => BluebellPrivilege[];
  isSuperAdmin: () => boolean;
  meta: CurrentUserMeta;
};

export const CurrentUserContext = createContext<ICurrentUserContext>({
  user: {} as CurrentUser,
  getUserPrivileges: () => [],
  isSuperAdmin: () => false,
  meta: {
    loading: false,
    hasFetched: false,
    error: null
  }
});

export const useCurrentUserContext = (): ICurrentUserContext => useContext(CurrentUserContext);

export const CurrentUserStore: React.FC<IntrinsicChildrenProps> = ({ children }) => {
  const [state, dispatch] = useReducer(CurrentUserReducer, initialCurrentUserState);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orgState, orgDispatch] = useOrganizationContext();
  const authContext = useAuthenticator(context => [context.route]);

  const myDetailsQuery = orgState?.organization?.id
    ? GET_MY_DETAILS_WITH_PRIVILEGES
    : GET_MY_DETAILS;
  const { data, loading, called, error } = useQuery<GetMyDetailsWithPrivileges>(myDetailsQuery, {
    skip: !isAuthenticated(authContext.route),
    errorPolicy: 'all'
  });

  const isSuperAdmin = useCallback(() => {
    return !!state.isSuperAdmin;
  }, [state]);

  const getUserPrivileges = useCallback(() => {
    return state.userPrivileges || [];
  }, [state.userPrivileges]);

  useEffect(() => {
    if (called) {
      dispatch({
        type: CURRENT_USER_ACTION_TYPES.SET_CURRENT_USER_HAS_FETCHED,
        payload: {
          hasFetched: called
        }
      });
    }
  }, [called]);

  useEffect(() => {
    dispatch({
      type: CURRENT_USER_ACTION_TYPES.SET_CURRENT_USER_LOADING,
      payload: {
        loading
      }
    });
  }, [loading]);

  useEffect(() => {
    if (error) {
      dispatch({
        type: CURRENT_USER_ACTION_TYPES.SET_CURRENT_USER_ERROR,
        payload: {
          error
        }
      });
    }
  }, [error]);

  useEffect(() => {
    if (data?.me) {
      const { id, firstName, lastName, superadmin, userPrivileges, email } = data.me;

      dispatch({
        type: CURRENT_USER_ACTION_TYPES.SET_CURRENT_USER,
        payload: {
          user: {
            id,
            firstName,
            lastName,
            email,
            superadmin,
            userPrivileges
          }
        }
      });
    }

    if (data?.organizations?.length) {
      orgDispatch({
        type: ORGANIZATION_ACTION_TYPES.SET_MEMBER_ORGANIZATIONS,
        payload: {
          memberOrganizations: data.organizations
        }
      });
    }
  }, [data, orgDispatch]);

  useEffect(() => {
    if (data?.me.organizationInvites?.length) {
      const pendingInvites = data.me.organizationInvites.filter(
        invite => invite.state === OrganizationInviteState.pending
      );

      orgDispatch({
        type: ORGANIZATION_ACTION_TYPES.SET_ORGANIZATION_INVITES,
        payload: {
          invites: pendingInvites
        }
      });
    }
  }, [data?.me.organizationInvites, orgDispatch]);

  return (
    <CurrentUserContext.Provider
      value={{
        user: state.currentUser,
        getUserPrivileges,
        isSuperAdmin,
        meta: state.meta
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
