import { gql } from '@apollo/client';
import { BREAKDOWN_TABLE } from '../PeopleListFragments';
import { BREAKDOWN_FRAGMENT } from '../../fragments/BreakdownFragment';

export const GET_PEOPLE_LIST_BREAKDOWNS_BY_ID = gql`
  query GetPeopleListBreakdownsById($id: ID!) {
    peopleList(id: $id) {
      id
      breakdowns {
        pivotTable {
          ...BreakdownTable
        }
        dataObjectBreakdown {
          ...BreakdownFragment
        }
      }
    }
  }
  ${BREAKDOWN_TABLE}
  ${BREAKDOWN_FRAGMENT}
`;
