import React from 'react';
import { useTranslation } from 'react-i18next';

type CancelLinkProps = {
  onCancel: (args?: unknown) => void;
  label?: string;
  ['data-testid']?: string;
};

export const CancelLink: React.FC<CancelLinkProps> = ({ onCancel, label, ...props }) => {
  const { t } = useTranslation();

  return (
    <div
      className="tw-text-gray-500 hover:tw-text-gray-800 hover:tw-underline tw-text-small tw-cursor-pointer tw-p-2 focus:tw-outline-nrs-indigo"
      onClick={onCancel}
      onKeyDown={onCancel}
      data-testid={props['data-testid'] ? props['data-testid'] : 'cancelLink'}
      role="button"
      tabIndex={0}
    >
      {label ? label : t('common.cancel')}
    </div>
  );
};
