import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption } from '@components/Dropdown';
import { UpdateNodeShareSettingsInput } from '@gql';
import { BluebellShareLevel } from '@gql';

type ShareModalLevelSelectProps = {
  label?: string;
  value: BluebellShareLevel;
  userId?: string;
  shareInput: UpdateNodeShareSettingsInput;
  onChange: (nextInput: UpdateNodeShareSettingsInput) => void;
};

export type ShareModalLevelSelectRef = {
  clearSelectedShareRule: () => void;
};

export const ShareModalLevelSelect: React.ForwardRefExoticComponent<
  ShareModalLevelSelectProps & React.RefAttributes<ShareModalLevelSelectRef>
> = forwardRef<ShareModalLevelSelectRef, ShareModalLevelSelectProps>(
  ({ label, value, userId = '', shareInput, onChange }, ref) => {
    const { t } = useTranslation();
    const orgShareLevels: DropdownOption[] = [
      { label: t('components.share.levelNone'), value: BluebellShareLevel.NONE },
      { label: t('components.share.levelRead'), value: BluebellShareLevel.READ },
      { label: t('components.share.levelWrite'), value: BluebellShareLevel.WRITE }
    ];

    const userShareLevels = orgShareLevels.filter(
      (opt: DropdownOption) => opt.value !== BluebellShareLevel.NONE
    );

    const getNextShareInputWithUser = (permission: BluebellShareLevel) => {
      const nextShareInput = shareInput;
      const nextUserShareRule = { userId: userId, permission: permission };

      if (nextShareInput.shareRules) {
        const filteredShareRules = nextShareInput.shareRules.filter(rule => rule.userId !== userId);
        const nextUserShareRules = [...filteredShareRules, nextUserShareRule];
        nextShareInput.shareRules = nextUserShareRules;
      } else {
        nextShareInput.shareRules = [nextUserShareRule];
      }

      return nextShareInput;
    };

    const getNextShareInputWithOrg = (shareLevel: BluebellShareLevel) => {
      return { ...shareInput, orgShareLevel: shareLevel };
    };

    const handleSetShareRuleInput = (selectedOptValue: string) => {
      const shareLevel = selectedOptValue as BluebellShareLevel;
      const nextShareInput = userId
        ? getNextShareInputWithUser(shareLevel)
        : getNextShareInputWithOrg(shareLevel);

      onChange(nextShareInput);
    };

    useImperativeHandle(ref, () => {
      return {
        clearSelectedShareRule() {
          handleSetShareRuleInput(BluebellShareLevel.NONE);
        }
      };
    });

    return (
      <Dropdown
        name="shareModalLevelSelect"
        label={label}
        value={value}
        options={userId ? userShareLevels : orgShareLevels}
        onChange={(selectedOptValue: string) => handleSetShareRuleInput(selectedOptValue)}
      />
    );
  }
);
