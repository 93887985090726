import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';

export const DECLINE_ORG_INVITE = gql`
  mutation DeclineOrgInvite($input: DeclineOrganizationInviteInput!) {
    declineOrganizationInvite(input: $input) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }

  ${ERROR_FRAGMENT}
`;
