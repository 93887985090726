import * as React from 'react';
import {
  Combobox,
  Transition,
  Field,
  Label,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { classNames } from '@helpers/classNames';

const { useState } = React;

export type SearchableDropdownOption = {
  label: string;
  value: string;
};

type ComboBoxProps = {
  options: SearchableDropdownOption[];
  onChange: (value: string) => void;
  value: SearchableDropdownOption | undefined;
  label?: string | (() => JSX.Element);
  name: string;
  placeholder?: string;
};

export const SearchableDropdown: React.FC<ComboBoxProps> = ({
  options,
  onChange,
  value,
  label,
  name,
  placeholder
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');

  const filteredOptions: SearchableDropdownOption[] =
    query === ''
      ? options
      : options.filter(option =>
          option.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  return (
    <Field>
      <Combobox
        value={value}
        name={name}
        onChange={selected => onChange(selected as unknown as string)}
        immediate
      >
        <div className="tw-relative tw-mt-1">
          {label ? (
            <Label
              data-testid={`comboBox:${name}:label`}
              className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
            >
              {label}
            </Label>
          ) : null}
          <div className="tw-relative tw-w-full tw-rounded-md tw-pr-10 tw-text-left tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6">
            <ComboboxInput
              data-testid={`comboBox:${name}:input`}
              className="tw-w-full tw-border-none tw-bg-transparent tw-py-2 tw-pl-3 tw-pr-10 tw-text-sm tw-leading-5 tw-text-gray-900 focus:tw-ring-0 tw-min-h-10"
              displayValue={(option: SearchableDropdownOption) => option.label}
              placeholder={placeholder || t('components.dropdown.defaultPlaceholder')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setQuery(event.target.value)
              }
            />
            <ComboboxButton
              data-testid="combobox:openOptionButton"
              className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2"
            >
              <ChevronUpDownIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
            </ComboboxButton>
          </div>
          <Transition
            as={'div'}
            leave="tw-transition tw-ease-in tw-duration-100"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
            afterLeave={() => setQuery('')}
          >
            <ComboboxOptions className="tw-absolute tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-text-sm">
              {filteredOptions.length === 0 && query !== '' ? (
                <div className="tw-relative tw-cursor-default tw-select-none tw-py-2 tw-px-4 tw-text-gray-700">
                  {t('components.combobox.nothingFound')}
                </div>
              ) : (
                filteredOptions.map(option => (
                  <ComboboxOption
                    key={option.value}
                    data-testid="comboBox:option"
                    className={({ focus }) =>
                      classNames(
                        `tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-10 tw-pr-4`,
                        focus ? 'tw-bg-nrs-indigo tw-text-white' : 'tw-text-gray-900'
                      )
                    }
                    value={option.value}
                  >
                    {({ selected, focus }) => (
                      <>
                        <span
                          className={classNames(
                            'tw-block tw-truncate',
                            selected ? 'tw-font-medium' : 'tw-font-normal'
                          )}
                        >
                          {option.label}
                        </span>
                        {selected ? (
                          <span
                            className={classNames(
                              'tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3',
                              focus ? 'tw-text-white' : 'tw-bg-nrs-indigo'
                            )}
                          >
                            <CheckIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ComboboxOption>
                ))
              )}
            </ComboboxOptions>
          </Transition>
        </div>
      </Combobox>
    </Field>
  );
};
