import * as React from 'react';
import { useParams } from 'react-router';
import { Alert } from '@components/Alert';
import { Card } from '@components/Card';
import { ContainerLayout } from '@components/ContainerLayout';
import { Invite } from './components/Invite';
import { useTranslation } from 'react-i18next';

type OrganizationInvitesRouteParams = {
  inviteId: string;
};

export const OrganizationInvitesRoute: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { inviteId } = useParams<OrganizationInvitesRouteParams>();

  if (inviteId === undefined) {
    return (
      <ContainerLayout>
        <Card>
          <Alert type="error">{t('invite.noInviteId')}</Alert>
        </Card>
      </ContainerLayout>
    );
  }

  return (
    <ContainerLayout>
      <Card>
        <Invite inviteId={inviteId} />
      </Card>
    </ContainerLayout>
  );
});
