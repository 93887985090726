import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Button';
import { Card } from '@components/Card';
import { CardTitle } from '@components/CardTitle';
import { Organization } from '@contexts/OrganizationContext';

type OrganizationSelectViewProps = {
  organizations: Organization[];
  onOrgSelect: (id: string) => void;
};

export const OrganizationSelectView: React.FC<OrganizationSelectViewProps> = ({
  organizations = [],
  onOrgSelect
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardTitle>{t('organization.selectOrganization')}</CardTitle>
      {organizations.map(({ id, name }: Organization) => (
        <div
          key={id}
          className="tw-border-t tw-border-b tw-py-10 tw-flex tw-justify-between tw-px-6"
        >
          <div className="tw-flex-1">{name}</div>
          <div className="tw-w-40 tw-flex tw-justify-end">
            <Button onClick={() => onOrgSelect(id)}>{t('common.select')}</Button>
          </div>
        </div>
      ))}
    </Card>
  );
};
