import React from 'react';

type SkeletonFieldProps = {
  className?: string;
};

export const SkeletonField: React.FC<SkeletonFieldProps> = ({ className }) => (
  <div
    className={`${
      className ? className : ''
    } tw-rounded-lg tw-h-10 tw-py-2 tw-px-4 tw-bg-gray-200 tw-border-gray-200 tw-animate-pulse`}
  />
);
