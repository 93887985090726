import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';
import { DOWNLOAD_URL_PROGRESS, DOWNLOAD_URL_DETAILS } from '../PeopleListFragments';

export const CREATE_PEOPLE_LIST_DOWNLOAD_URL = gql`
  mutation CreatePeopleListDownloadUrl($input: CreatePeopleListDownloadUrlInput!) {
    createPeopleListDownloadUrl(input: $input) {
      urlDetails {
        ...DownloadUrlDetails
      }
      progress {
        ...DownloadUrlProgress
      }
      errors {
        ...ErrorFragment
      }
    }
  }

  ${DOWNLOAD_URL_PROGRESS}
  ${DOWNLOAD_URL_DETAILS}
  ${ERROR_FRAGMENT}
`;
