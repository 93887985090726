import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from './NavLink';
import { ROOT } from '../config/routes';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { PlusIcon } from './icons';
import { PrivilegeEnforcementWrapper } from '@domains/Auth';
import { LIST_WRITER, LIST_READER } from '@config/privileges';
import { BluebellPrivilege } from '@gql';

export type RouteLink = {
  label: string;
  url: string;
  privileges?: BluebellPrivilege[];
  testId?: string;
};

export const Nav: React.FC = () => {
  const { t } = useTranslation();

  const navLinks: RouteLink[] = useMemo(
    () => [
      {
        label: t('header.nav.lists'),
        url: ROOT.LISTS.BASE,
        privileges: [LIST_READER],
        testId: 'nav:lists'
      },
      {
        label: t('header.nav.folders'),
        url: ROOT.FOLDERS.BASE,
        testId: 'nav:folders'
      },
      {
        label: t('header.nav.dataSources'),
        url: ROOT.DATA_SOURCES.BASE,
        testId: 'nav:dataSources'
      }
    ],
    [t]
  );

  return (
    <nav data-testid="navigation" role="navigation" className="tw-flex">
      <PrivilegeEnforcementWrapper requiredPrivileges={[LIST_WRITER]}>
        <Link to={`${ROOT.LISTS.BASE}${ROOT.LISTS.NEW}`}>
          <Button type="button" className="tw-flex tw-items-center">
            <span className="tw-mr-1" data-testid="nav:newList">
              <PlusIcon />
            </span>
            <span>{t('header.nav.newList')}</span>
          </Button>
        </Link>
      </PrivilegeEnforcementWrapper>
      <ul className="tw-flex tw-items-center">
        {navLinks.map((navLink: RouteLink) =>
          navLink.privileges ? (
            <PrivilegeEnforcementWrapper
              key={navLink.label}
              requiredPrivileges={navLink.privileges}
            >
              <NavLink to={navLink.url}>
                <span data-testid={navLink.testId}>{navLink.label}</span>
              </NavLink>
            </PrivilegeEnforcementWrapper>
          ) : (
            <NavLink key={navLink.label} to={navLink.url}>
              <span data-testid={navLink.testId}>{navLink.label}</span>
            </NavLink>
          )
        )}
      </ul>
    </nav>
  );
};
