import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';

export const DELETE_USER_FROM_ORGANIZATION = gql`
  mutation DeleteUserFromOrganization($input: DeleteUserFromOrganizationInput!) {
    deleteUserFromOrganization(input: $input) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }

  ${ERROR_FRAGMENT}
`;
