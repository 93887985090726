import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, BadgeKinds } from '@components/Badge';

type PeopleListSharedBadgeProps = {
  isShared?: boolean;
};

export const PeopleListSharedBadge: React.FC<PeopleListSharedBadgeProps> = ({
  isShared = false
}) => {
  const { t } = useTranslation();

  if (isShared) {
    return (
      <Badge
        data-testid="peopleList:sharedBadge"
        kind={BadgeKinds.info}
        className="tw-bg-nrs-salmon"
      >
        {t('components.share.shared')}
      </Badge>
    );
  }

  return (
    <Badge data-testid="peopleList:sharedBadge" kind={BadgeKinds.disabled}>
      {t('components.share.notShared')}
    </Badge>
  );
};
