import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';

export const CREATE_FAVORITE = gql`
  mutation CreateFavorite($input: CreateFavoriteInput!) {
    createFavorite(input: $input) {
      errors {
        ...ErrorFragment
      }
      success
    }
  }

  ${ERROR_FRAGMENT}
`;
