import { ROOT } from './routes';

export enum RedirectRuleName {
  OrganizationChanging = 'organizationChanging'
}

export interface IRedirectRule {
  redirectStrategy: 'stayAlways' | 'redirectAlways';
  targetRoute: string;
  excludedRoutes: string[];
}

type RedirectRulesType = Record<RedirectRuleName, IRedirectRule>;

export const RedirectRules: RedirectRulesType = {
  organizationChanging: {
    redirectStrategy: 'stayAlways',
    targetRoute: ROOT.LISTS.BASE,
    excludedRoutes: [ROOT.LISTS.BASE]
  }
};
