import React, { useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Alert } from '@components/Alert';
import { Button } from '@components/Button';
import { CancelLink } from '@components/CancelLink';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { SkeletonField } from '@components/SkeletonField';
import { GET_USERS_WITH_ROLES } from '@data/users';
import {
  ShareModalUserSelect,
  ShareModalUser,
  ShareModalUserSelectRef
} from './ShareModalUserSelect';
import { ShareModalUserList } from './ShareModalUserList';
import { ShareModalLevelSelect, ShareModalLevelSelectRef } from './ShareModalLevelSelect';
import { ShareObject } from './ShareButton';
import { UPDATE_SHARE_SETTINGS } from '@data/sharing';
import {
  BluebellShareLevel,
  GetUsersWithRoles,
  UpdateNodeShareSettingsInput,
  UpdateShareSettings,
  UpdateShareSettingsVariables,
  FolderDetails
} from '@gql';
import { useCurrentUserContext, CurrentUser } from '@contexts/CurrentUser';
import { ArrayItem } from '@src/types';

type ShareRules = ArrayItem<NonNullable<FolderDetails['shareRules']>>;

type ShareModalProps = {
  isOpen: boolean;
  shareObject?: ShareObject;
  refetch?: () => void;
  onClose: () => void;
};

const normalizeShareRules = (shareRules: ShareRules[]) =>
  shareRules.map(rule => ({ userId: rule.user.id, permission: rule.permission }));

const { useState, useEffect, useCallback } = React;

export const ShareModal: React.FC<ShareModalProps> = ({
  isOpen,
  shareObject,
  refetch,
  onClose
}) => {
  const { t } = useTranslation();
  const shareModalUserSelectRef = useRef<ShareModalUserSelectRef>(null);
  const shareModalLevelSelectRef = useRef<ShareModalLevelSelectRef>(null);
  const [orgUsers, setOrgUsers] = useState<ShareModalUser[]>([]);
  const [shareUsers, setShareUsers] = useState<ShareModalUser[]>([]);
  const [fetchError, setFetchError] = useState<string | undefined>();

  const [shareRuleInput, setShareRuleInput] = useState<UpdateNodeShareSettingsInput>(() => {
    const nextShareRuleInput: Partial<UpdateNodeShareSettingsInput> = {};
    if (shareObject) {
      nextShareRuleInput.shareableId = shareObject.id;
      nextShareRuleInput.orgShareLevel = shareObject.orgShareLevel;

      if (shareObject.shareRules) {
        nextShareRuleInput.shareRules = normalizeShareRules(shareObject.shareRules);
      } else {
        nextShareRuleInput.shareRules = [];
      }
    }

    return nextShareRuleInput as UpdateNodeShareSettingsInput;
  });

  const currentUser = useCurrentUserContext();
  const [getUsers, { data: orgUsersData, loading, called }] =
    useLazyQuery<GetUsersWithRoles>(GET_USERS_WITH_ROLES);
  const [createOrUpdateShareRules] = useMutation<UpdateShareSettings, UpdateShareSettingsVariables>(
    UPDATE_SHARE_SETTINGS
  );

  const getShareModalUser = useCallback(
    (user: CurrentUser, isCurrent: boolean) => {
      let name: string;
      if (user.firstName && user.lastName) {
        name = `${user.firstName} ${user.lastName}`;
      } else {
        name = user.email;
      }

      if (isCurrent) {
        name = t('common.name(you)', { name: name });
      }

      return {
        id: user.id,
        name: name,
        email: user.email,
        current: isCurrent
      };
    },
    [t]
  );

  const setShareUsersAndInput = useCallback(
    (user: ShareModalUser) => {
      const notShared = shareUsers.find(u => u.id === user.id) === undefined;

      if (notShared) {
        const existingRules = shareRuleInput.shareRules || [];
        const existingRule = existingRules.length
          ? existingRules.find(rule => rule.userId === user.id)
          : null;
        const newRule = existingRule || { userId: user.id, permission: BluebellShareLevel.READ };

        setShareRuleInput({
          ...shareRuleInput,
          shareRules: [...existingRules, newRule]
        });
        setShareUsers(previousShareUsers => [...previousShareUsers, user]);
      }
    },
    [shareRuleInput, shareUsers]
  );

  const handleCreateOrUpdateShareRules = useCallback(
    async (shareRuleInput: UpdateNodeShareSettingsInput) => {
      try {
        await createOrUpdateShareRules({
          variables: {
            input: shareRuleInput
          }
        });
        refetch?.();
        onClose();
      } catch (err: any) {
        setFetchError(t('lists.pages.list.errorWhileSharing'));
      }
    },
    [createOrUpdateShareRules, onClose, refetch, t]
  );

  const handleClose = useCallback(() => {
    onClose();
    const rules = normalizeShareRules(shareObject?.shareRules || []);
    shareModalLevelSelectRef.current?.clearSelectedShareRule();

    setShareRuleInput(prevInput => ({
      ...prevInput,
      shareRules: [...rules]
    }));

    const me = currentUser.user;
    const userData = orgUsersData?.users?.filter(
      (u: ArrayItem<GetUsersWithRoles['users']>) => me?.id !== u.id
    );

    if (userData?.length && me !== null) {
      const currentUserInfo = getShareModalUser(me, true);
      const users = userData.map((u: ArrayItem<GetUsersWithRoles['users']>) =>
        getShareModalUser(u, false)
      );

      const sharedModalUsers = rules
        .map(rule => users.find(u => u.id === rule.userId))
        .filter(Boolean);
      setShareUsers([currentUserInfo, ...(sharedModalUsers as ShareModalUser[])]);
    }
  }, [onClose, shareObject?.shareRules, currentUser.user, orgUsersData?.users, getShareModalUser]);

  const handleRemoveUser = (userId: string) => {
    setShareUsers(shareUsers => shareUsers.filter(u => u.id !== userId));
    shareModalUserSelectRef.current?.clearSelectedUser();
  };

  useEffect(() => {
    const me = currentUser.user;
    const userData = orgUsersData?.users?.filter(
      (u: ArrayItem<GetUsersWithRoles['users']>) => me?.id !== u.id
    );
    const orgUsersEmpty = !orgUsers.length;

    if (userData?.length && me !== null && orgUsersEmpty) {
      const currentUserInfo = getShareModalUser(me, true);
      const users = userData.map((u: ArrayItem<GetUsersWithRoles['users']>) =>
        getShareModalUser(u, false)
      );
      setOrgUsers(users);

      const rules = shareRuleInput?.shareRules || [];
      const sharedModalUsers = rules
        .map(rule => users.find(u => u.id === rule.userId))
        .filter(Boolean);
      setShareUsers([currentUserInfo, ...(sharedModalUsers as ShareModalUser[])]);
    }
  }, [orgUsers, orgUsersData, currentUser, shareRuleInput, getShareModalUser]);

  useEffect(() => {
    if (isOpen && !called) {
      try {
        getUsers();
      } catch (error) {
        setFetchError(t('error.fetching'));
      }
    }
  }, [called, getUsers, isOpen, t]);

  useEffect(() => {
    if (shareObject) {
      if (shareObject.id !== shareRuleInput.shareableId) {
        setShareRuleInput(prevInput => ({ ...prevInput, shareableId: shareObject.id }));
      }

      if (shareObject.orgShareLevel !== shareRuleInput.orgShareLevel) {
        setShareRuleInput(prevInput => ({
          ...prevInput,
          orgShareLevel: shareRuleInput.orgShareLevel || shareObject.orgShareLevel
        }));
      }

      if (shareObject.shareRules !== undefined) {
        setShareRuleInput(prevInput => ({
          ...prevInput,
          shareRules: normalizeShareRules([...(shareObject.shareRules ?? [])])
        }));
      }
    }
  }, [shareObject, shareRuleInput.orgShareLevel, shareRuleInput.shareableId]);

  return (
    <Modal
      isOpen={isOpen}
      title={t('components.share.modalTitle', { objectName: shareObject?.name })}
      data-testid="shareModal:modal"
      width="medium"
      onClose={handleClose}
    >
      <div className="tw-flex tw-flex-col tw-justify-between tw-min-h-[350px]">
        {loading ? (
          <SkeletonField />
        ) : (
          <ShareModalUserSelect
            users={orgUsers}
            onChange={(user: ShareModalUser) => setShareUsersAndInput(user)}
            ref={shareModalUserSelectRef}
          />
        )}

        {loading ? (
          <Loading />
        ) : (
          <ShareModalUserList
            users={shareUsers}
            shareInput={shareRuleInput}
            onChange={(nextInput: UpdateNodeShareSettingsInput) => {
              setShareRuleInput(currentInput => ({ ...currentInput, ...nextInput }));
            }}
            removeUser={handleRemoveUser}
            shareModalLevelSelectRef={shareModalLevelSelectRef}
          />
        )}

        <ShareModalLevelSelect
          label={t('components.share.orgShareLevelLabel')}
          value={shareRuleInput.orgShareLevel}
          shareInput={shareRuleInput}
          onChange={(nextInput: UpdateNodeShareSettingsInput) => {
            setShareRuleInput(currentInput => ({ ...currentInput, ...nextInput }));
          }}
          ref={shareModalLevelSelectRef}
        />

        {fetchError && (
          <Alert type="error" data-testid="shareModal:mutationError">
            {fetchError}
          </Alert>
        )}

        <div className="tw-flex tw-justify-between tw-mt-6">
          <CancelLink onCancel={() => handleClose()} data-testid="shareModal:cancel" />
          <Button
            className="tw-ml-2"
            onClick={() => handleCreateOrUpdateShareRules(shareRuleInput)}
            data-testid="shareModal:save"
          >
            {t('common.save')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
