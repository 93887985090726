import * as React from 'react';
import { DropdownOption, Dropdown } from '@components/Dropdown';
import { useTranslation } from 'react-i18next';

const geoLevelOptions: DropdownOption[] = [
  {
    label: 'National',
    value: 'national'
  },
  {
    label: 'State',
    value: 'state'
  },
  {
    label: 'County',
    value: 'county'
  }
];

type FilterProps = {
  filterOptions: string[];
  filterTitle: string;
  geoLevel: string;
  maxSelections?: number;
  onChangeFilter: (val: Array<string | null>) => void;
  onChangeGeoLevel: (val: string) => void;
  selectedFilters: Array<string | null>;
};

const { useCallback } = React;

export const Filter: React.FC<FilterProps> = ({
  filterOptions,
  filterTitle,
  geoLevel = 'national',
  onChangeFilter,
  onChangeGeoLevel,
  maxSelections,
  selectedFilters
}) => {
  const { t } = useTranslation();

  const handleChangeFilters = useCallback(
    (value: string, index: number) => {
      if (maxSelections && selectedFilters.length >= maxSelections) return;
      onChangeFilter(
        selectedFilters.includes(value)
          ? [...selectedFilters.fill(null, index, index + 1)]
          : [...selectedFilters.fill(value, index, index + 1)]
      );
    },
    [maxSelections, onChangeFilter, selectedFilters]
  );

  const handleClearFilters = useCallback(() => {
    onChangeFilter([...selectedFilters.fill(null)]);
    onChangeGeoLevel('national');
  }, [selectedFilters, onChangeFilter, onChangeGeoLevel]);

  return (
    <div className="tw-bg-gradient-to-b tw-from-nrs-blue tw-to-nrs-blue-dark tw-p-4 tw-text-white tw-rounded-r-lg tw-w-[250px]">
      <h3 className="tw-pb-4 tw-border-b tw-border-white tw-w-full tw-text-base">{filterTitle}</h3>
      <div className="tw-my-4">
        <fieldset>
          <legend className="tw-text-xs">
            {filterOptions.map((subgroup: string, index: number) => (
              <div key={subgroup} className="tw-my-2 tw-text-xs">
                <label>
                  <input
                    type="checkbox"
                    name={subgroup}
                    checked={selectedFilters.includes(subgroup)}
                    onChange={e => handleChangeFilters(subgroup, index)}
                    className="tw-h-4 tw-w-4 tw-rounded tw-border-white tw-text-nrs-indigo focus:tw-ring-transparent focus:tw-ring-offset-nrs-indigo tw-mr-2 tw-bg-nrs-blue"
                  />
                  {subgroup}
                </label>
              </div>
            ))}
            <div className="tw-my-4" role="button" onClick={handleClearFilters}>
              <span className="tw-text-xs hover:tw-underline">
                {t('automodeler.filters.clearFilters')}
              </span>
            </div>
          </legend>
        </fieldset>
        <div className="tw-my-4">
          <h3 className="tw-pb-4 tw-border-b tw-border-white tw-w-full tw-text-lg">
            {t('automodeler.filters.geoLevel')}
          </h3>
          <div className="tw-my-4">
            <label htmlFor="geo-level" className="tw-text-white tw-text-xs tw-mb-2 tw-block">
              {t('automodeler.filters.geographicLevel')}
            </label>
            <Dropdown
              name="geo-level"
              options={geoLevelOptions}
              onChange={(val: string) => onChangeGeoLevel(val)}
              value={geoLevel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
