import { gql } from '@apollo/client';

export const ROLE_SETTINGS_FRAGMENT = gql`
  fragment RoleSettingsFragment on RoleSettings {
    privileges
  }
`;

export const ROLE_FRAGMENT = gql`
  fragment RoleFragment on Role {
    id
    name
    settings {
      ...RoleSettingsFragment
    }
  }

  ${ROLE_SETTINGS_FRAGMENT}
`;
