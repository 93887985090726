import React from 'react';
import { Link } from 'react-router-dom';
import { FolderIcon } from '@heroicons/react/24/outline';
import { ShareButton, ShareObject } from '../../components/Sharing/ShareButton';
import { ROOT } from '@config/routes';

type FolderProps = {
  folderObject: ShareObject;
  folderPath: string;
  refetch: () => void;
};

export const Folder: React.FC<FolderProps> = ({ folderObject, folderPath, refetch }) => {
  return (
    <div
      className="tw-bg-white tw-rounded-lg tw-shadow tw-py-4 tw-px-4 tw-mb-4 tw-max-w-[250px]"
      data-testid="folder:folderComponent"
    >
      <div className="tw-flex tw-items-center tw-justify-between">
        <Link to={`${ROOT.FOLDERS.BASE}/${folderPath}`}>
          <div className=" tw-flex tw-items-center tw-cursor-pointer tw-text-nrs-blue hover:tw-text-nrs-blue-dark tw-mr-4">
            <FolderIcon className="tw-w-6 tw-h-6" />
            <span className="tw-text-lg tw-ml-4 ">{folderObject.name}</span>
          </div>
        </Link>

        <ShareButton shareObject={folderObject} refetch={refetch} />
      </div>
    </div>
  );
};
