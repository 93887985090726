import { gql } from '@apollo/client';
import { CREATOR_FRAGMENT } from '@data/users/CreatorFragment';
import { PEOPLE_LIST_SHARE_RULES } from '@data/lists/PeopleListFragments';

export const FOLDER_DETAILS = gql`
  fragment FolderDetails on Folder {
    id
    name
    orgShareLevel
    shareRules {
      user {
        ...Creator
      }
      permission
    }

    contents {
      id
      folderable {
        ... on PeopleList {
          id
          name
          creator {
            ...Creator
          }
          state
          mutability
          orgShareLevel
          ...ShareRules
          createdAt
          updatedAt
          nodeUserPreferences {
            favorite
          }
        }
      }
    }
  }

  ${CREATOR_FRAGMENT}
  ${PEOPLE_LIST_SHARE_RULES}
`;
