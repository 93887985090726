import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';

export const ACCEPT_ORG_INVITE = gql`
  mutation AcceptOrgInvite($input: AcceptOrganizationInviteInput!) {
    acceptOrganizationInvite(input: $input) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }

  ${ERROR_FRAGMENT}
`;
