import React from 'react';
import { BluebellPrivilege } from '@gql';
import { usePrivilegeMatch } from './usePrivilegeMatch';
import { IntrinsicChildrenProps } from '@src/types';

export interface PrivilegeEnforcementWrapperProps extends IntrinsicChildrenProps {
  requiredPrivileges: BluebellPrivilege[];
  children: React.ReactElement<any, any>;
}

export const PrivilegeEnforcementWrapper: React.FC<PrivilegeEnforcementWrapperProps> = ({
  requiredPrivileges,
  children
}) => {
  const { hasPermission } = usePrivilegeMatch(requiredPrivileges);

  if (hasPermission) return children;
  return null;
};
