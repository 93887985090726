import React from 'react';
import { BluebellPrivilege } from '@gql';
import { FourOhFour } from './404';
import { usePrivilegeMatch } from './usePrivilegeMatch';
import { Loading } from '@components/Loading';
import { IntrinsicChildrenProps } from '@src/types';

export interface PrivilegeEnforcementRouteWrapperProps extends IntrinsicChildrenProps {
  requiredPrivileges: Array<BluebellPrivilege | 'SUPER_ADMIN'>;
}

export const PrivilegeEnforcementRouteWrapper: React.FC<PrivilegeEnforcementRouteWrapperProps> = ({
  requiredPrivileges,
  children
}) => {
  const { hasPermission, isLoading } = usePrivilegeMatch(requiredPrivileges);

  if (isLoading) return <Loading />;
  if (hasPermission) return <>{children}</>;
  return <FourOhFour />;
};
