import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareModalUser } from './ShareModalUserSelect';
import { ShareModalLevelSelect, ShareModalLevelSelectRef } from './ShareModalLevelSelect';
import { BluebellShareLevel, UpdateNodeShareSettingsInput } from '@gql';
import { TrashIcon } from '@components/icons/Trash';
import { IconButton } from '@components/IconButton';
import { Title4 } from '@components/Title4';
import { Title5 } from '@components/Title5';

type ShareModalUserListProps = {
  users: ShareModalUser[];
  shareInput: UpdateNodeShareSettingsInput;
  onChange: (nextInput: UpdateNodeShareSettingsInput) => void;
  removeUser: (userId: string) => void;
  shareModalLevelSelectRef?: React.RefObject<ShareModalLevelSelectRef>;
};

export const ShareModalUserList: React.FC<ShareModalUserListProps> = ({
  users,
  shareInput,
  onChange,
  removeUser,
  shareModalLevelSelectRef
}) => {
  const { t } = useTranslation();
  const handleRemoveUser = (user: ShareModalUser) => {
    const nextInput = shareInput;
    const nextRuleInput = shareInput.shareRules?.filter(u => user.id !== u.userId);
    nextInput.shareRules = nextRuleInput;

    onChange(nextInput);
    removeUser(user.id);
  };

  const getUserSharePermissions = (user: ShareModalUser, share: UpdateNodeShareSettingsInput) => {
    return (
      share.shareRules?.find(rule => rule.userId === user.id)?.permission || BluebellShareLevel.READ
    );
  };

  return (
    <div>
      <header className="tw-flex tw-justify-between">
        <Title4 className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-1 tw-mb-4">
          {t('components.share.userListLabel')}
        </Title4>
        <Title5>{t('components.share.levelSelectLabel')}</Title5>
      </header>
      <div className="tw-px-2">
        <ul className="tw-divide-y tw-divide-gray-100">
          {users.map((user: ShareModalUser, index: number) => (
            <li
              key={user.id ?? index}
              className="tw-flex tw-justify-between tw-gap-x-6 tw-py-2"
              data-testid="shareModalUserList:listItem"
            >
              <p className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
                {user.name}
              </p>

              {!user.current && (
                <div className="hidden tw-flex tw-flex-row tw-items-center">
                  <div data-testid="shareModalUserList:levelSelect">
                    <ShareModalLevelSelect
                      value={getUserSharePermissions(user, shareInput)}
                      userId={user.id}
                      shareInput={shareInput}
                      onChange={onChange}
                      ref={shareModalLevelSelectRef}
                    />
                  </div>

                  <IconButton
                    className="tw-ml-2"
                    onClick={() => handleRemoveUser(user)}
                    data-testid="shareModalUserList:removeUser"
                  >
                    <TrashIcon />
                  </IconButton>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
