import * as Sentry from '@sentry/react';
import { API_URL, VERSION, SENTRY_DSN, SENTRY_ENVIRONMENT } from '@src/utils/env';

export function configureSentry() {
  if (SENTRY_DSN && SENTRY_ENVIRONMENT) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      integrations: [Sentry.browserTracingIntegration()],
      release: VERSION ?? '',
      // Performance Monitoring
      //  Capture 100% of the transactions
      tracesSampleRate: 1.0,
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [API_URL ?? '']
    });
  }
}
