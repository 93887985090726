import { gql } from '@apollo/client';
import { DATA_OBJECT_SCHEMA_FIELD_META } from '@data/dataObjects/DataObjectsFragments';

export const BREAKDOWNS_FIELDS_FRAGMENT = gql`
  fragment BreakdownsFieldFragment on DataObjectBreakdownField {
    id
    createdAt
    updatedAt
    position
    dataObjectBreakdown {
      id
      createdAt
      updatedAt
      name
      statistics
      schemaFields {
        ...DataObjectSchemaFieldMeta
      }
      settings {
        pivotColumnIds
        pivotRowIds
      }
    }
    dataObjectSchemaField {
      ...DataObjectSchemaFieldMeta
    }
  }

  ${DATA_OBJECT_SCHEMA_FIELD_META}
`;

export const BREAKDOWN_FRAGMENT = gql`
  fragment BreakdownFragment on DataObjectBreakdown {
    id
    createdAt
    updatedAt
    name
    statistics
    breakdownFields {
      ...BreakdownsFieldFragment
    }
    schemaFields {
      ...DataObjectSchemaFieldMeta
    }
    settings {
      pivotColumnIds
      pivotRowIds
    }
  }

  ${BREAKDOWNS_FIELDS_FRAGMENT}
  ${DATA_OBJECT_SCHEMA_FIELD_META}
`;
