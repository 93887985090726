import { Alert } from '@components/Alert';
import { Button, ButtonKind } from '@components/Button';
import { Card } from '@components/Card';
import { Loading } from '@components/Loading';
import { Title3 } from '@components/Title3';
import { ROOT } from '@config/routes';
import { useApproveMfaReset, useRejectMfaReset } from '@gql';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

const TIME_TO_REDIRECT = 30000;
const REQUEST_ID_LENGTH = 43;

export default function ResetMfaConfirmRoute() {
  const { t } = useTranslation();
  const { requestId } = useParams();
  const navigate = useNavigate();
  const [rejectMfaReset, rejectMfaResetMutation] = useRejectMfaReset();
  const [approveMfaReset, approveMfaResetMutation] = useApproveMfaReset();
  const [newPassword, setNewPassword] = useState<null | string>(null);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [isRejected, setIsRejected] = useState<boolean>(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleApproveResetMfaRequest = (id: string) => {
    approveMfaReset({
      variables: {
        input: {
          id
        }
      },
      onCompleted({ approveMfaReset }) {
        if (!approveMfaReset?.password) {
          setErrorMessage(t('auth.passwordNotReturnedError'));
          return;
        }
        setNewPassword(approveMfaReset.password);

        timerRef.current = setTimeout(() => {
          navigate(ROOT.AUTH.LOGIN);
        }, TIME_TO_REDIRECT);
      }
    });
  };

  const isLoading = approveMfaResetMutation.loading || rejectMfaResetMutation.loading;

  const handleRejectResetMfaRequest = (id: string) => {
    rejectMfaReset({
      variables: {
        input: {
          id
        }
      },
      onCompleted() {
        setIsRejected(true);
      }
    });
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!requestId || requestId.length !== REQUEST_ID_LENGTH) {
    return (
      <Card>
        <Alert type="error">{t('auth.incorrectRequestId')}</Alert>
      </Card>
    );
  }

  if (isRejected) {
    return (
      <Card className="tw-text-center">
        <p>{t('auth.mfaResetRejected')}</p>
        <Link className="tw-text-blue-400 hover:tw-underline" to={ROOT.AUTH.LOGIN}>
          {t('auth.goToLoginPage')}
        </Link>
      </Card>
    );
  }

  return (
    <div className="tw-p-24">
      <Card>
        {newPassword && (
          <div className="tw-text-center">
            <p>
              {t('auth.passwordResetTo')}
              <i>
                <b data-testid="confirmResetMfa:newPassword">{newPassword}</b>
              </i>
              <span>{t('auth.willBeAskedSetupMfa')}</span>
            </p>
            <Link className="tw-text-blue-400 hover:tw-underline" to={ROOT.AUTH.LOGIN}>
              {t('auth.goToLoginPage')}
            </Link>
          </div>
        )}
        {!newPassword && (
          <div>
            <Title3>{t('auth.resetMfa')}</Title3>
            <div className="tw-flex tw-gap-4">
              <Button
                onClick={() => handleApproveResetMfaRequest(requestId)}
                kind={ButtonKind.default}
              >
                {t('common.confirm')}
              </Button>
              <Button
                onClick={() => handleRejectResetMfaRequest(requestId)}
                kind={ButtonKind.error}
              >
                {t('common.reject')}
              </Button>
            </div>
          </div>
        )}
      </Card>
      {errorMessage && <Alert type="error">{errorMessage}</Alert>}
    </div>
  );
}
