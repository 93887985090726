import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ROOT } from '@config/routes';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { isAuthenticated } from '@helpers/auth';

type ProtectedRouteProps<T> = {
  component: React.ComponentType<T>;
  props?: T;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps<any>> = React.memo(
  ({ component: Component, props }) => {
    const authContext = useAuthenticator(context => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams();
    let referrerUrl = encodeURIComponent(location.pathname);

    if (location.search) {
      referrerUrl += `${location.search}`;
    }

    searchParams.set('referrer', referrerUrl);
    const url = `${ROOT.AUTH.LOGIN}?${searchParams.toString()}`;

    useEffect(() => {
      if (!isAuthenticated(authContext.route)) {
        navigate(url);
      }
    }, [authContext.route, navigate, url]);

    return isAuthenticated(authContext.route) ? <Component {...props} /> : null;
  }
);
