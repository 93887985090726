import { gql } from '@apollo/client';

export const DATA_OBJECT_FIELD_CONTEXT_META_DATA = gql`
  fragment DataObjectFieldContextMetaData on DataObjectSchemaFieldContextMetadata {
    categories
    required
  }
`;

export const DATA_OBJECT_SCHEMA_FIELD_META = gql`
  fragment DataObjectSchemaFieldMeta on DataObjectSchemaField {
    id
    name
    category
    description
    dataType
  }
`;

export const DATA_OBJECT_FIELD_VALID_VALUES = gql`
  fragment DataObjectFieldValidValues on DataObjectSchemaFieldValidValues {
    contextMetadata {
      ...DataObjectFieldContextMetaData
    }
    moreContextRequired
    validValues
    operators
  }

  ${DATA_OBJECT_FIELD_CONTEXT_META_DATA}
`;
