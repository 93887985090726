import { gql } from '@apollo/client';
import { CREATOR_FRAGMENT } from '@data/users/CreatorFragment';
import { FOLDER_DETAILS } from '../FolderFragments';

export const GET_FOLDER = gql`
  query GetFolder($name: String!) {
    folder(name: $name) {
      ...FolderDetails
    }
  }

  ${CREATOR_FRAGMENT}
  ${FOLDER_DETAILS}
`;
