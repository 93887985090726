import { IntrinsicChildrenProps } from '@src/types';
import React from 'react';

interface CardTitleProps extends IntrinsicChildrenProps {
  className?: string;
}

export const CardTitle: React.FC<CardTitleProps> = ({ children, className }) => (
  <h2 className={`tw-text-xl tw-text-nrs-blue-dark tw-mb-4 ${className ?? ''}`}>{children}</h2>
);
