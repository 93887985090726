import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';
import { PEOPLE_LIST_STATE_FRAGMENT } from '@data/fragments/PeopleListStateFragment';

export const UNARCHIVE_PEOPLE_LIST = gql`
  mutation UnarchivePeopleList($input: UnarchivePeopleListInput!) {
    unarchivePeopleList(input: $input) {
      errors {
        ...ErrorFragment
      }
      peopleList {
        ...PeopleListStateFragment
      }
      success
    }
  }

  ${ERROR_FRAGMENT},
  ${PEOPLE_LIST_STATE_FRAGMENT}
`;
