import { gql } from '@apollo/client';

export const GET_ORG_INVITE_BY_ID = gql`
  query GetOrgInviteById($id: ID!) {
    organizationInvite(id: $id) {
      id
      organization {
        id
        name
      }
    }
  }
`;
