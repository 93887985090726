import React, { createContext, useContext, Dispatch, useReducer, useEffect } from 'react';
import { localStore } from '@utils/storage';
import { ORG_STORE_KEY } from '@config/store-keys';
import { getOrganizationFromStorage } from '@src/utils/getOrganizationFromStorage';
import { BluebellShareLevel, OrganizationInviteState, Role, OrganizationBaseFields } from '@gql';
import { IntrinsicChildrenProps } from '@src/types';

export enum ORGANIZATION_ACTION_TYPES {
  'SET_ORGANIZATION',
  'CLEAR_ORGANIZATION',
  'SET_MEMBER_ORGANIZATIONS',
  'SET_ORGANIZATION_INVITES',
  'UPDATE_ORGANIZATION_SETTINGS'
}

type OrganizationReducerAction = {
  type: ORGANIZATION_ACTION_TYPES;
  payload?: OrganizationActionPayload;
};

type OrganizationSettings = Partial<OrganizationBaseFields['settings']>;

type OrganizationActionPayload = {
  organization?: Organization;
  memberOrganizations?: Organization[];
  invites?: OrganizationInvite[];
  organizationSettings?: OrganizationSettings;
};

export type Organization = {
  id: string;
  name?: string;
  settings?:
    | Omit<OrganizationBaseFields['settings'], '__typename'>
    | OrganizationBaseFields['settings'];
};

export type OrganizationInvite = {
  id: string;
  organization: Organization;
  role: Pick<Role, 'id' | 'name'>;
  state: OrganizationInviteState;
};

export type OrganizationReducerState = {
  organization: Organization | null;
  memberOrganizations: Organization[];
  invites: OrganizationInvite[];
};

export const OrganizationReducer = (
  state: OrganizationReducerState,
  action: OrganizationReducerAction
): OrganizationReducerState => {
  switch (action.type) {
    case ORGANIZATION_ACTION_TYPES.SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload?.organization ? { ...action.payload.organization } : null
      };
    case ORGANIZATION_ACTION_TYPES.CLEAR_ORGANIZATION:
      return {
        ...state,
        organization: null
      };
    case ORGANIZATION_ACTION_TYPES.SET_MEMBER_ORGANIZATIONS:
      return {
        ...state,
        memberOrganizations: action.payload?.memberOrganizations ?? []
      };
    case ORGANIZATION_ACTION_TYPES.SET_ORGANIZATION_INVITES:
      return {
        ...state,
        invites:
          action.payload?.invites?.filter(
            invite => invite.state === OrganizationInviteState.pending
          ) ?? []
      };

    case ORGANIZATION_ACTION_TYPES.UPDATE_ORGANIZATION_SETTINGS:
      if (action.payload?.organizationSettings && state.organization) {
        return {
          ...state,
          organization: {
            ...state.organization,
            settings: {
              modules: state.organization?.settings?.modules ?? [],
              defaultShareLevel:
                action.payload.organizationSettings.defaultShareLevel ||
                state.organization?.settings?.defaultShareLevel ||
                BluebellShareLevel.NONE,
              hydratorColumns:
                action.payload.organizationSettings.hydratorColumns ||
                state.organization.settings?.hydratorColumns ||
                []
            }
          }
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

const initialOrganizationState = {
  organization: getOrganizationFromStorage(),
  memberOrganizations: [],
  invites: []
};

export type IOrganizationContext = [
  OrganizationReducerState,
  Dispatch<OrganizationReducerAction> | ((args: any) => void)
];
export const OrganizationContext = createContext<IOrganizationContext>([
  initialOrganizationState,
  () => null
]);

export const useOrganizationContext = (): IOrganizationContext => useContext(OrganizationContext);

export const OrganizationStore: React.FC<IntrinsicChildrenProps> = ({ children }) => {
  const [state, dispatch] = useReducer(OrganizationReducer, initialOrganizationState);

  useEffect(() => {
    if (state.organization?.id) {
      const storedOrg = getOrganizationFromStorage();
      if (storedOrg?.id !== state.organization.id) {
        const encodedOrg = btoa(JSON.stringify(state.organization));
        localStore.setItem(ORG_STORE_KEY, encodedOrg);
      } else {
        const encodedOrg = btoa(JSON.stringify(state.organization));
        localStore.setItem(ORG_STORE_KEY, encodedOrg);
      }
    } else if (state.organization === null) {
      localStore.removeItem(ORG_STORE_KEY);
    }
  }, [state]);

  return (
    <OrganizationContext.Provider value={[state, dispatch]}>
      {children}
    </OrganizationContext.Provider>
  );
};
