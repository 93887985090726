import { gql } from '@apollo/client';
import { DATA_OBJECT_SCHEMA_FIELD_META } from '@data/dataObjects/DataObjectsFragments';
import { CREATOR_FRAGMENT } from '@data/users/CreatorFragment';

export const PEOPLE_LIST_META = gql`
  fragment PeopleListMeta on PeopleList {
    id
    name
    state
    mutability
    createdAt
    updatedAt
    orgShareLevel
  }
`;

export const BUILD_DEFINITION_STEP_META = gql`
  fragment BuildDefinitionStepMeta on PeopleListBuildStep {
    id
    name
    action
    fieldCombineOperator
  }
`;

const RANGE_FRAGMENT = gql`
  fragment RangeFragment on Range {
    min
    max
  }
`;

export const FIELD_META = gql`
  fragment FieldMeta on PeopleListBuildStepField {
    id
    comparisonOperator
    dataObjectSchemaField {
      ...DataObjectSchemaFieldMeta
    }
    inputValue
    peopleListBuildStepId
    distance {
      googlePlaceId
      lat
      long
      distance
    }
    range {
      ...RangeFragment
    }
  }

  ${DATA_OBJECT_SCHEMA_FIELD_META}
  ${RANGE_FRAGMENT}
`;

export const DOWNLOAD_URL_PROGRESS = gql`
  fragment DownloadUrlProgress on ProgressBar {
    complete
    currentStepIndex
    message
    steps
    success
  }
`;

export const DOWNLOAD_URL_DETAILS = gql`
  fragment DownloadUrlDetails on ExpiringUrl {
    expiration
    ttl
    url
  }
`;

export const BREAKDOWN_TABLE = gql`
  fragment BreakdownTable on PivotTable {
    columnLabels
    columns {
      aggregates {
        statistic
        value
      }
      label
    }
    rows {
      points {
        fieldValues {
          field {
            id
            name
          }
          value
        }
        statisticValues {
          statistic
          value
        }
      }
      aggregates {
        statistic
        value
      }
      rowLabels
    }
  }
`;

export const PEOPLE_LIST_SHARE_RULES = gql`
  fragment ShareRules on PeopleList {
    shareRules {
      user {
        ...Creator
      }
      permission
    }
  }

  ${CREATOR_FRAGMENT}
`;

export const PEOPLE_LIST_USER_PREFERENCES = gql`
  fragment PeopleListUserPreferences on NodeUserPreferences {
    favorite
  }
`;

export const PEOPLE_LIST_SUMMARY_STATS = gql`
  fragment PeopleListSummaryStatsFragment on PeopleList {
    summaryStats {
      description
      label
      value
    }
  }
`;

export const PEOPLE_LIST_HYDRATOR_RUN = gql`
  fragment PeopleListHydratorRunDetails on PeopleListHydratorRun {
    id
    columns
    completedAt
    creator {
      ...Creator
    }
    downloadUrl {
      url
    }
    estimatedCost
    state
    matchedCount
  }

  ${CREATOR_FRAGMENT}
`;
