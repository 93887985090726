import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';

export const REMOVE_FROM_FOLDER = gql`
  mutation RemoveFromFolder($input: RemoveNodeFromFolderInput!) {
    removeNodeFromFolder(input: $input) {
      errors {
        ...ErrorFragment
      }
      success
    }
  }

  ${ERROR_FRAGMENT}
`;
