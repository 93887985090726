import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_PEOPLE_LIST_BY_ID } from './queries';
import { GetPeopleListById, GetPeopleListByIdVariables } from '@gql';

export const useGetPeopleListById = (
  peopleListId: string,
  options?: QueryHookOptions<GetPeopleListById, GetPeopleListByIdVariables>
) => {
  const result = useQuery<GetPeopleListById, GetPeopleListByIdVariables>(GET_PEOPLE_LIST_BY_ID, {
    variables: {
      id: peopleListId
    },
    skip: !peopleListId,
    ...options
  });

  return result;
};
