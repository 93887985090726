import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Authenticator, Heading, translations } from '@aws-amplify/ui-react';
import { NRSLogoStacked } from '@components/NRSLogoStacked';
import { ROOT } from '@config/routes';
import { useURLSearchParams } from '@src/utils/useURLSearchParams';
import { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents';
import { AuthFormFields } from '@aws-amplify/ui/dist/types/types/authenticator/form.d';
import { I18n } from 'aws-amplify/utils';

//Changing confirm button text
I18n.putVocabularies(translations);
I18n.setLanguage('en');
I18n.putVocabulariesForLanguage('en', {
  Confirm: 'Sign in'
});

export const LoginRoute: React.FC = () => {
  const { t } = useTranslation();
  const params = useURLSearchParams();
  const referrer = useRef<string | null>(params.get('referrer'));

  const formFields: AuthFormFields = {
    signIn: {
      username: {
        placeholder: t('common.emailAddress'),
        isRequired: true,
        labelHidden: true
      },
      password: {
        placeholder: t('profile.password'),
        isRequired: true,
        labelHidden: true
      }
    },
    forceNewPassword: {
      given_name: {
        placeholder: t('profile.placeholderFirstName'),
        labelHidden: true
      },
      family_name: {
        placeholder: t('profile.placeholderLastName'),
        labelHidden: true
      },
      password: {
        placeholder: t('profile.password'),
        labelHidden: true
      },
      confirm_password: {
        placeholder: '* * * * *',
        label: t('profile.confirmPassword')
      }
    },
    confirmSignIn: {
      confirmation_code: {
        label: '',
        placeholder: t('auth.code')
      }
    },
    setupTotp: {
      confirmation_code: {
        label: t('auth.totpCode'),
        placeholder: t('auth.totpCodePlaceholder')
      },
      QR: {
        totpIssuer: t('glossary.bluebell')
      }
    }
  };

  const components: DefaultComponents = {
    Header() {
      return (
        <div className="tw-flex tw-justify-center tw-w-full tw-mb-6 tw-mt-20">
          <div className="tw-w-4/5 tw-max-w-[20rem]">
            <NRSLogoStacked />
          </div>
        </div>
      );
    },
    ForceNewPassword: {
      FormFields: () => {
        return (
          <>
            <Authenticator.ForceNewPassword.FormFields />
          </>
        );
      }
    },
    SetupTotp: {
      Header() {
        return (
          <div className="tw-leading-relaxed tw-flex">
            <Heading level={3} className="tw-mb-4 tw-mt-1 tw-mr-2">
              {t('auth.totp.heading')}
            </Heading>
            <ol className="tw-list-decimal tw-ml-4">
              <li className="tw-my-2 tw-text-gray-600 tw-text-sm">{t('auth.totp.step1')}</li>
              <li className="tw-my-2 tw-text-gray-600 tw-text-sm">{t('auth.totp.step2')}</li>
              <li className="tw-my-2 tw-text-gray-600 tw-text-sm">{t('auth.totp.step3')}</li>
              <li className="tw-my-2 tw-text-gray-600 tw-text-sm">{t('auth.totp.step4')}</li>
            </ol>
          </div>
        );
      }
    },
    ConfirmSignIn: {
      Header() {
        return (
          <div>
            <Heading level={3}>{t('auth.confirmSignInHeading')}</Heading>
            <p className="tw-text-sm tw-text-gray-300 tw-italic">{t('auth.inputTotpTip')}</p>
          </div>
        );
      }
    }
  };

  let url = ROOT.LISTS.BASE;
  if (referrer.current) {
    url = decodeURIComponent(referrer.current);
  }

  //This use effect for hiding 'Back to Sign In' button
  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(() => {
        const buttons = document.querySelectorAll<HTMLButtonElement>(
          '.authenticator-wrapper button'
        );
        const heading = document.querySelector('h3');
        buttons.forEach(button => {
          if (
            button.textContent?.trim() === t('auth.backToSignIn') &&
            heading?.textContent?.trim() === t('auth.confirmSignInHeading')
          ) {
            button.style.display = 'none'; // Hide the button
          }
        });
      });
    });

    const config = { childList: true, subtree: true };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    observer.observe(document.querySelector('.authenticator-wrapper')!, config);

    return () => observer.disconnect();
  });

  return (
    <main className="tw-h-screen tw-flex tw-flex-col tw-justify-center -tw-mt-40">
      <div className="authenticator-wrapper tw-m-auto">
        <Authenticator
          formFields={formFields}
          loginMechanisms={['email']}
          hideSignUp={true}
          components={components}
        >
          {() => <Navigate to={url} replace={true} />}
        </Authenticator>
      </div>
    </main>
  );
};
