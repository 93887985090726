import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { GetFolders_folders_nodes } from '@gql';
import { Alert } from '@components/Alert';
import { Card } from '@components/Card';
import { CardTitle } from '@components/CardTitle';
import { ContainerLayout } from '@components/ContainerLayout';
import { Loading } from '@components/Loading';
import { PeopleListNode } from '../Lists/components/PeopleListNode';
import { Folder } from './Folder';
import { FolderBreadcrumbs } from './FolderBreadcrumbs';
import { BluebellShareLevel, FolderDetails } from '@gql';
import { useGetFolders } from './hooks/useGetFolders';
import { ArrayItem } from '@src/types';

type FolderContent = ArrayItem<FolderDetails['contents']>;

export const FoldersRoute: React.FC = () => {
  const { t } = useTranslation();
  const { '*': splat } = useParams();
  const isRoot = splat === '';
  const path = splat || '';

  const { query, folderId, folderData, foldersData, loading, error, refetch } = useGetFolders(
    isRoot,
    path
  );

  React.useEffect(() => {
    query();
  }, [query]);

  const handleRefetch = () => {
    refetch();
  };

  return (
    <ContainerLayout>
      <div data-testid="foldersRoute:layout">
        {loading ? (
          <Loading />
        ) : (
          <Card>
            <CardTitle>{t('folders.pages.route.title')}</CardTitle>
            <div className="tw-p-4">
              {!isRoot && <FolderBreadcrumbs path={path} />}

              {foldersData && getFolders(foldersData, handleRefetch)}

              {folderData && getFolderContent(folderId, folderData, handleRefetch)}

              {folderData && !folderData.length && (
                <h3 className="tw-text-xl tw-text-nrs-blue tw-text-center">No Content...</h3>
              )}
            </div>

            {error && (
              <Alert data-testid="foldersRoute:error" type="error">
                {error.message}
              </Alert>
            )}
          </Card>
        )}
      </div>
    </ContainerLayout>
  );
};

const getFolderContent = (
  folderId: string | undefined,
  contents: FolderContent[],
  handleRefetch: () => void
) => {
  return contents.map((content: FolderContent) => {
    return (
      <PeopleListNode
        key={content.folderable.id}
        node={content.folderable}
        folderId={folderId}
        refetch={handleRefetch}
        onFolderDelete={() => handleRefetch()}
      />
    );
  });
};

const getFolders = (folders: (GetFolders_folders_nodes | null)[], handleRefetch: () => void) => {
  return folders.map((folder: GetFolders_folders_nodes | null) => {
    const folderObj = getFolderShareObject(folder);
    return (
      <Folder
        key={folder?.id}
        folderObject={folderObj}
        folderPath={folder?.name || ''}
        refetch={() => handleRefetch()}
      />
    );
  });
};

const getFolderShareObject = (folder: GetFolders_folders_nodes | null) => {
  return {
    id: folder?.id || '',
    name: folder?.name || '',
    orgShareLevel: folder?.orgShareLevel || BluebellShareLevel.NONE,
    shareRules: folder?.shareRules || []
  };
};
