import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';

export const UPDATE_ORGANIZATION_SETTINGS = gql`
  mutation UpdateOrganizationSettings($input: UpdateOrganizationSettingsInput!) {
    updateOrganizationSettings(input: $input) {
      errors {
        ...ErrorFragment
      }
      settings {
        modules
        defaultShareLevel
      }
    }
  }

  ${ERROR_FRAGMENT}
`;
