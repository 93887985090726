import {
  CURRENT_USER_ACTION_TYPES,
  CurrentUserReducerAction,
  CurrentUserReducerState
} from './CurrentUserContext.types';

export const initialCurrentUserState: CurrentUserReducerState = {
  currentUser: null,
  userPrivileges: [],
  meta: {
    loading: false,
    hasFetched: false,
    error: null
  }
};

export const CurrentUserReducer = (
  state: CurrentUserReducerState = initialCurrentUserState,
  action: CurrentUserReducerAction
): CurrentUserReducerState => {
  switch (action.type) {
    case CURRENT_USER_ACTION_TYPES.SET_CURRENT_USER:
      if (action.payload.user) {
        return {
          ...state,
          currentUser: {
            id: action.payload.user?.id,
            firstName: action.payload.user?.firstName,
            lastName: action.payload.user?.lastName,
            email: action.payload.user?.email
          },
          userPrivileges: action.payload.user.userPrivileges,
          isSuperAdmin: action.payload.user.superadmin
        };
      }
      return state;
    case CURRENT_USER_ACTION_TYPES.CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        userPrivileges: []
      };
    case CURRENT_USER_ACTION_TYPES.SET_CURRENT_USER_LOADING:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: !!action.payload?.loading
        }
      };
    case CURRENT_USER_ACTION_TYPES.SET_CURRENT_USER_HAS_FETCHED:
      return {
        ...state,
        meta: {
          ...state.meta,
          hasFetched: true
        }
      };
    case CURRENT_USER_ACTION_TYPES.SET_CURRENT_USER_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          error: action.payload.error
        }
      };
    default:
      return state;
  }
};
