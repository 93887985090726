import { PeopleListState } from '@gql';

export const isStableListState = (state: PeopleListState | undefined) => {
  if (state === undefined) return false;
  const stableListStates = [
    PeopleListState.ready,
    PeopleListState.archived,
    PeopleListState.matching_pii
  ];
  return stableListStates.includes(state);
};
