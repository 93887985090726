import React, { useEffect, useRef } from 'react';
import {
  Organization,
  useOrganizationContext,
  ORGANIZATION_ACTION_TYPES
} from '@contexts/OrganizationContext';
import { SearchableDropdown, SearchableDropdownOption } from '@components/SearchableDropdown';
import { useRedirectRules } from '@src/utils/useRedirectRules';
import { RedirectRuleName } from '@config/redirect-rules';

export const OrganizationSelectField: React.FC = () => {
  const [state, dispatch] = useOrganizationContext();
  const orgIdRef = useRef(state.organization?.id);
  const applyRedirectRule = useRedirectRules(RedirectRuleName.OrganizationChanging);

  const handleOrgSelect = (orgId: string) => {
    const nextOrg = state.memberOrganizations.find(
      (organization: Organization) => organization.id === orgId
    );
    if (nextOrg) {
      dispatch({
        type: ORGANIZATION_ACTION_TYPES.SET_ORGANIZATION,
        payload: {
          organization: nextOrg
        }
      });
      applyRedirectRule();
    }
  };

  useEffect(() => {
    if (state.organization?.id !== orgIdRef.current) {
      window.location.reload();
    }
  }, [state.organization, state.organization?.id]);

  if (!state?.memberOrganizations) return null;

  const options: SearchableDropdownOption[] = state?.memberOrganizations?.map(
    (organization: Organization) => ({
      value: organization.id,
      label: organization.name ?? organization.id
    })
  );

  const value: SearchableDropdownOption | undefined =
    state.organization?.id && state.organization.name
      ? {
          value: state.organization.id,
          label: state.organization.name ?? state.organization.id
        }
      : undefined;

  return (
    <div data-testid="organization-select" className="tw-mx-6 tw-mb-6 tw-mt-4 tw-w-72">
      <SearchableDropdown
        options={options}
        onChange={handleOrgSelect}
        value={value}
        name="organization-select"
      />
    </div>
  );
};
