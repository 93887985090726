import React, { useEffect, useCallback } from 'react';
import { Loading } from '@components/Loading';
import { useFetch } from 'use-http';
import { API_URL } from '@utils/env';
import { useSessionToken } from '@utils/getSessionToken';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { localStore } from '@utils/storage';
import { ORG_STORE_KEY } from '@config/store-keys';
import { ROOT } from '@config/routes';

const LOGOUT_URL = `${API_URL}/v1/logout`;

export const LogoutRoute: React.FC = () => {
  const authContext = useAuthenticator();
  const session = useSessionToken();

  const requestFunc = useCallback(
    async ({
      options
    }: {
      options: RequestInit;
      url?: string | undefined;
      path?: string | undefined;
      route?: string | undefined;
    }) => {
      if (options?.headers && session?.token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${session.token}`
        };
      }
      return options;
    },
    [session.token]
  );

  const { del: destroySession, abort } = useFetch(LOGOUT_URL, {
    interceptors: {
      request: requestFunc
    }
  });

  useEffect(() => {
    const doLogout = async () => {
      await destroySession();
      localStore.removeItem(ORG_STORE_KEY);
      authContext.signOut();
    };

    doLogout();

    return () => abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authContext.authStatus === 'unauthenticated') {
      window.location.href = ROOT.AUTH.LOGIN;
    }
  }, [authContext.authStatus]);
  return <Loading />;
};
