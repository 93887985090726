import { gql } from '@apollo/client';

export const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      errors {
        message
        path
      }

      userRole {
        id
        role {
          id
          name
        }
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;
