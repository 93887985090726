import { RedirectRules, RedirectRuleName } from '@config/redirect-rules';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

const { useCallback } = React;

export const useRedirectRules = (rule: RedirectRuleName) => {
  const navigate = useNavigate();
  const rules = RedirectRules[rule];
  const location = useLocation();

  const shouldRedirect = useCallback(() => {
    if (rules.redirectStrategy === 'redirectAlways') {
      return !rules.excludedRoutes?.some(path => location.pathname.includes(path));
    }

    if (rules.redirectStrategy === 'stayAlways') {
      return rules.excludedRoutes?.some(path => location.pathname.includes(path));
    }

    return false;
  }, [location.pathname, rules]);

  const applyRules = useCallback(() => {
    if (shouldRedirect()) {
      navigate(rules.targetRoute);
    }
  }, [shouldRedirect, navigate, rules.targetRoute]);

  return applyRules;
};
