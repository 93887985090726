export interface SemanticColorScheme {
  background: string;
  title: string;
  message: string;
  icon: string;
  buttonBackground: string;
  buttonHoverBackground: string;
  buttonText: string;
  buttonFocusRing: string;
  buttonFocusRingOffset: string;
  link: string;
  linkHover: string;
}

export type SemanticColorType = 'success' | 'info' | 'error' | 'warning';

const SUCCESS: SemanticColorScheme = {
  background: 'tw-bg-green-50',
  title: 'tw-text-green-800',
  message: 'tw-text-green-700',
  icon: 'tw-text-green-400',
  buttonBackground: 'tw-bg-green-50',
  buttonHoverBackground: 'hover:tw-bg-green-100',
  buttonText: 'tw-text-green-800',
  buttonFocusRing: 'focus:tw-ring-green-600',
  buttonFocusRingOffset: 'focus:tw-ring-offset-green-50',
  link: 'tw-text-green-700',
  linkHover: 'hover:tw-text-green-600'
};

const INFO: SemanticColorScheme = {
  background: 'tw-bg-blue-50',
  title: 'tw-text-blue-800',
  message: 'tw-text-blue-700',
  icon: 'tw-text-blue-400',
  buttonBackground: 'tw-bg-blue-50',
  buttonHoverBackground: 'hover:tw-bg-blue-100',
  buttonText: 'tw-text-blue-800',
  buttonFocusRing: 'focus:tw-ring-blue-600',
  buttonFocusRingOffset: 'focus:tw-ring-offset-blue-50',
  link: 'tw-text-blue-700',
  linkHover: 'hover:tw-text-blue-600'
};

const ERROR: SemanticColorScheme = {
  background: 'tw-bg-red-50',
  title: 'tw-text-red-800',
  message: 'tw-text-red-700',
  icon: 'tw-text-red-400',
  buttonBackground: 'tw-bg-red-50',
  buttonHoverBackground: 'hover:tw-bg-red-100',
  buttonText: 'tw-text-red-800',
  buttonFocusRing: 'focus:tw-ring-red-600',
  buttonFocusRingOffset: 'focus:tw-ring-offset-red-50',
  link: 'tw-text-red-700',
  linkHover: 'hover:tw-text-red-600'
};

const WARNING: SemanticColorScheme = {
  background: 'tw-bg-yellow-50',
  title: 'tw-text-yellow-800',
  message: 'tw-text-yellow-700',
  icon: 'tw-text-yellow-400',
  buttonBackground: 'tw-bg-yellow-50',
  buttonHoverBackground: 'hover:tw-bg-yellow-100',
  buttonText: 'tw-text-yellow-800',
  buttonFocusRing: 'focus:tw-ring-yellow-600',
  buttonFocusRingOffset: 'focus:tw-ring-offset-yellow-50',
  link: 'tw-text-yellow-700',
  linkHover: 'hover:tw-text-yellow-600'
};

export function getSemanticColors(type: SemanticColorType): SemanticColorScheme {
  const schemeColors: { [index: string]: SemanticColorScheme } = {
    success: SUCCESS,
    info: INFO,
    error: ERROR,
    warning: WARNING
  };

  return type in schemeColors ? schemeColors[type] : schemeColors['info'];
}
