import React from 'react';
import { Link } from 'react-router-dom';
import { Nav } from './Nav';
import { OrganizationSelectField } from '@domains/Organization/components/OrganizationSelectField';
import { SubNav } from './SubNav';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useOrganizationContext } from '@contexts/OrganizationContext';
import { isAuthenticated } from '@helpers/auth';
import { NRSLogo } from './NRSLogo';
import { ROOT } from '@config/routes';

export const Header: React.FC = () => {
  const { route } = useAuthenticator(context => [context.route]);
  const [state] = useOrganizationContext();

  if (!isAuthenticated(route)) {
    return null;
  }

  return (
    <header className="tw-fixed tw-top-0 tw-z-[1001] tw-w-full tw-bg-white tw-h-16 tw-shadow-sm tw-flex tw-items-center tw-justify-between">
      <div className="tw-flex-1 tw-flex tw-items-center">
        <Link to={ROOT.LISTS.BASE}>
          <div className="tw-w-20 tw-h-20 tw-mx-4 tw-flex">
            <NRSLogo />
          </div>
        </Link>
        {state.organization && <Nav />}
      </div>
      {state.organization ? (
        state.memberOrganizations.length > 1 ? (
          <OrganizationSelectField />
        ) : (
          <div className="tw-mx-4 tw-text-sm tw-text-gray-600">{state.organization.name}</div>
        )
      ) : null}
      <SubNav />
    </header>
  );
};
