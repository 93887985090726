import * as React from 'react';
import { useUpdateListUserPreferences } from '@data/lists/useUpdateListUserPreferences';
import { useSnackbar } from '@components/Snackbar';
import { useMutation } from '@apollo/client';
import { DELETE_FAVORITE } from '@data/lists/mutations/DeleteFavorite';
import { DeleteFavorite, DeleteFavoriteVariables } from '@gql';
import { useTranslation } from 'react-i18next';
import { StarIcon } from '@heroicons/react/24/solid';
import { StarIcon as StarOutline } from '@heroicons/react/24/outline';
import { CREATE_FAVORITE } from '@data/lists';
import { CreateFavorite, CreateFavoriteVariables } from '@gql';

type FavoriteListStarProps = {
  listId: string;
  isFavorite: boolean;
};

const { useCallback } = React;

export const FavoriteListStar: React.FC<FavoriteListStarProps> = ({ listId, isFavorite }) => {
  const { t } = useTranslation();
  const { open: openSnackbar } = useSnackbar();
  const [updateListUserPreferences] = useUpdateListUserPreferences(listId);
  const [deleteFavorite] = useMutation<DeleteFavorite, DeleteFavoriteVariables>(DELETE_FAVORITE, {
    variables: {
      input: {
        favoriteableId: listId
      }
    },
    onCompleted: () => updateListUserPreferences()
  });

  const [createFavorite] = useMutation<CreateFavorite, CreateFavoriteVariables>(CREATE_FAVORITE, {
    variables: {
      input: {
        favoriteableId: listId
      }
    },
    onCompleted: () => updateListUserPreferences()
  });

  const openDeleteFolderErrorSnackbar = useCallback(() => {
    openSnackbar({
      text: t('lists.pages.folders.deleteError'),
      type: 'error'
    });
  }, [openSnackbar, t]);

  const openAddListToFavoriteErrorSnackbar = useCallback(() => {
    openSnackbar({
      text: t('lists.pages.list.listCreateFavoriteError'),
      type: 'error'
    });
  }, [openSnackbar, t]);

  const handleAddToFavorites = useCallback(async () => {
    try {
      await createFavorite();
      openSnackbar({
        type: 'success',
        text: t('lists.pages.list.listCreateFavoriteSuccess')
      });
    } catch (err) {
      openAddListToFavoriteErrorSnackbar();
    }
  }, [createFavorite, openSnackbar, openAddListToFavoriteErrorSnackbar, t]);

  const handleRemoveFromFavorites = useCallback(async () => {
    try {
      await deleteFavorite();
      openSnackbar({
        type: 'success',
        text: t('lists.pages.list.listRemovedFromFavorites')
      });
    } catch (err) {
      openDeleteFolderErrorSnackbar();
    }
  }, [deleteFavorite, openDeleteFolderErrorSnackbar, openSnackbar, t]);

  return (
    <React.Fragment>
      {isFavorite ? (
        <div
          className="tw-mr-2 tw-cursor-pointer"
          data-testid="peopleListNode:favorite:isFavorite"
          onClick={() => handleRemoveFromFavorites()}
          title={t('lists.pages.list.removeFromFavorites')}
        >
          <StarIcon className="tw-w-6 tw-h-6 tw-text-yellow-300" />
        </div>
      ) : (
        <div
          className="tw-mr-2 tw-cursor-pointer"
          data-testid="peopleListNode:favorite:isNotFavorite"
          onClick={() => handleAddToFavorites()}
          title={t('lists.pages.list.favoriteList')}
        >
          <StarOutline className="tw-w-6 tw-h-6 tw-text-yellow-300" />
        </div>
      )}
    </React.Fragment>
  );
};
