import React from 'react';
import { ContainerLayoutTitle } from './ContainerLayoutTitle';
import { IntrinsicChildrenProps } from '@src/types';

export interface ContainerLayoutProps extends IntrinsicChildrenProps {
  title?: string;
}

export const ContainerLayout: React.FC<ContainerLayoutProps> = ({ children, title }) => (
  <div>
    {title && (
      <div className="tw-max-w-screen-lg tw-m-auto tw-pt-6 tw-pb-3">
        <ContainerLayoutTitle>{title}</ContainerLayoutTitle>
      </div>
    )}
    <div className="tw-pb-4 tw-pt-6 tw-px-4 tw-w-full">
      <div className="tw-container tw-m-auto tw-max-w-screen-lg">{children}</div>
    </div>
  </div>
);
