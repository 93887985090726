import { useContext, useCallback } from 'react';
import { OpenSnackbarArgs } from '.';
import { SnackbarContext } from './Snackbar';

type UseSnackBar = {
  open: (args: OpenSnackbarArgs) => void;
  close: () => void;
};

export const useSnackbar = (): UseSnackBar => {
  const context = useContext(SnackbarContext);

  if (context === undefined) {
    throw new Error('useSnackbar must be used within SnackbarProvider');
  }

  const { openSnackbar, closeSnackbar } = context;
  const open = useCallback(openSnackbar, [openSnackbar]);
  const close = useCallback(closeSnackbar, [closeSnackbar]);

  return {
    open,
    close
  };
};
