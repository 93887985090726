import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';

export const CLONE_LIST = gql`
  mutation ClonePeopleList($input: ClonePeopleListInput!) {
    clonePeopleList(input: $input) {
      peopleList {
        id
      }
      errors {
        ...ErrorFragment
      }
    }
  }

  ${ERROR_FRAGMENT}
`;
