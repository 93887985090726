import { gql } from '@apollo/client';

export const CREATE_PEOPLE_LIST = gql`
  mutation CreatePeopleList($input: CreatePeopleListInput!) {
    createPeopleList(input: $input) {
      peopleList {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;
