export enum StorageType {
  localStorage,
  sessionStorage
}

export enum StorageMethods {
  getItem,
  setItem,
  removeItem
}

class StorageService {
  storageType: StorageType;

  storage: Storage;

  constructor(storageType: StorageType) {
    this.storageType = storageType;
    this.storage = storageType === StorageType.sessionStorage ? sessionStorage : localStorage;
  }

  getItem(key: string): null;
  getItem<TData = any>(key: string): TData;
  getItem<TData = any>(key: string): TData | null {
    this.hasKey(key, StorageMethods.getItem);

    try {
      const serializedStore = this.storage.getItem(key);
      if (serializedStore === null) {
        return null;
      }

      return JSON.parse(serializedStore);
    } catch (err) {
      // Ignore errors
      return null;
    }
  }

  setItem<TData = any>(key: string, value: TData): null;
  setItem<TData = any>(key: string, value: TData): TData;
  setItem<TData = any>(key: string, value: TData): TData | null {
    this.hasKey(key, StorageMethods.setItem);

    this.storage.setItem(key, JSON.stringify(value));
    return this.getItem(key);
  }

  removeItem(key: string) {
    this.hasKey(key, StorageMethods.removeItem);

    if (key in this.storage) {
      this.storage.removeItem(key);
    }
  }

  clear() {
    this.storage.clear();
  }

  hasKey(key: string, methodName: StorageMethods): string {
    if (!key) {
      throw new Error(`${this.storageType} '${methodName}' requires a key.`);
    }

    return key;
  }
}

class LocalStorage extends StorageService {
  constructor() {
    super(StorageType.localStorage);
  }
}

class SessionStorage extends StorageService {
  constructor() {
    super(StorageType.sessionStorage);
  }
}

export const localStore = new LocalStorage();
export const sessionStore = new SessionStorage();
