import * as React from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import './modal.css';
import { IntrinsicChildrenProps } from '@src/types';
import { useEffect, useState } from 'react';

type WidthOptions = 'small' | 'medium' | 'large';

interface ModalProps extends IntrinsicChildrenProps {
  width?: WidthOptions; // Deprecated
  title?: string | React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  ['data-testid']?: string;
}

const { Fragment } = React;

const widthClassNameMap = {
  small: 'sm:tw-max-w-xs',
  medium: 'sm:tw-max-w-xl',
  large: 'sm:tw-max-w-5xl'
};

export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  title = null,
  onClose,
  width = 'medium',
  ...props
}) => {
  const [resetKey, setResetKey] = useState(0);

  const widthClassName = widthClassNameMap[width];
  useEffect(() => {
    if (!isOpen) {
      setResetKey(prevKey => prevKey + 1);
    }
  }, [isOpen]);
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="tw-relative tw-z-[1005]" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div
            data-testid="modal:shadow"
            className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"
          />
        </TransitionChild>
        <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <TransitionChild
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <DialogPanel
                className={`tw-relative tw-transform tw-overflow-visible tw-rounded-lg
                 tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all
                 sm:tw-my-8 sm:tw-w-full ${widthClassName} sm:tw-p-6`}
                data-testid={props['data-testid']}
                key={resetKey}
              >
                <div className="sm:tw-ml-4 sm:tw-mt-0 sm:tw-text-left">
                  {title ? (
                    <DialogTitle
                      as="h3"
                      className="tw-mb-6 tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900"
                    >
                      {title}
                    </DialogTitle>
                  ) : null}
                  <Fragment>{children}</Fragment>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
