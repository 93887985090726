import { gql } from '@apollo/client';
import { BUILD_DEFINITION_STEP_META, FIELD_META, PEOPLE_LIST_META } from '../PeopleListFragments';

export const UPDATE_PEOPLE_LIST = gql`
  mutation UpdatePeopleList($input: UpdatePeopleListInput!) {
    updatePeopleList(input: $input) {
      peopleList {
        ...PeopleListMeta
        buildDefinition {
          steps {
            ...BuildDefinitionStepMeta
            fields {
              ...FieldMeta
            }
          }
        }
      }
      errors {
        message
        path
      }
    }
  }

  ${PEOPLE_LIST_META}
  ${BUILD_DEFINITION_STEP_META}
  ${FIELD_META}
`;
