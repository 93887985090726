import { gql } from '@apollo/client';
import { USER_NAME_FRAGMENT } from '../../users/UserFragments';
import { ROLE_FRAGMENT } from '../../roles/RoleFragment';
import {
  API_KEY_FRAGMENT,
  ORGANIZATION_BASE_FIELDS
} from '@data/organizations/OrganizationFragments';

export const GET_MY_DETAILS = gql`
  query GetMyDetails {
    me {
      id
      ...UserNameFragment
      superadmin
      apiKeys {
        ...ApiKeyFragment
      }
      organizationInvites {
        id
        state
        organization {
          id
          name
        }
        role {
          id
          name
        }
      }
    }
    organizations {
      ...OrganizationBaseFields
    }
  }

  ${USER_NAME_FRAGMENT}
  ${ORGANIZATION_BASE_FIELDS}
  ${API_KEY_FRAGMENT}
`;

export const GET_MY_DETAILS_WITH_ROLE = gql`
  query GetMyDetailsWithRole {
    me {
      id
      ...UserNameFragment
      superadmin
      apiKeys {
        ...ApiKeyFragment
      }
      organizationInvites {
        id
        state
        organization {
          id
          name
        }
        role {
          id
          name
        }
      }
      role {
        ...RoleFragment
      }
    }
    organizations {
      ...OrganizationBaseFields
    }
  }

  ${USER_NAME_FRAGMENT}
  ${ROLE_FRAGMENT}
  ${ORGANIZATION_BASE_FIELDS}
  ${API_KEY_FRAGMENT}
`;

export const GET_MY_DETAILS_WITH_PRIVILEGES = gql`
  query GetMyDetailsWithPrivileges {
    me {
      id
      apiKeys {
        ...ApiKeyFragment
      }
      ...UserNameFragment
      email
      superadmin
      userPrivileges
      organizationInvites {
        id
        state
        organization {
          id
          name
        }
        role {
          id
          name
        }
      }
      role {
        ...RoleFragment
      }
    }
    organizations {
      ...OrganizationBaseFields
    }
  }

  ${USER_NAME_FRAGMENT}
  ${ORGANIZATION_BASE_FIELDS}
  ${ROLE_FRAGMENT}
  ${API_KEY_FRAGMENT}
`;
