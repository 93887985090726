import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { CogIcon, LogoutIcon, UserIcon } from './icons';
import { ROOT } from '../config/routes';
import { OrganizationIcon } from './icons/Organization';
import { useOrganizationContext } from '@contexts/OrganizationContext';
import { Notifications } from './Notifications';
import { ContextMenu, ContextMenuOption } from './ContextMenu';
import { PrivilegeEnforcementWrapper } from '@domains/Auth';
import { ORG_MANAGER } from '@config/privileges';
import { usePrivilegeMatch } from '@domains/Auth/usePrivilegeMatch';
import { IntrinsicChildrenProps } from '@src/types';

const SettingsMenuLink: React.FC<IntrinsicChildrenProps> = ({ children }) => (
  <div className="tw-flex tw-justify-items-center">{children}</div>
);

export const SubNav: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const [orgState] = useOrganizationContext();
  const { hasPermission } = usePrivilegeMatch([ORG_MANAGER]);

  const handleSignOutClick = useCallback(() => {
    navigate('/logout');
  }, [navigate]);

  const handleLinkClick = useCallback(
    (url: string) => {
      setIsSettingsMenuOpen(false);
      navigate(url);
    },
    [navigate]
  );

  const settingsMenuLinks: ContextMenuOption[] = useMemo(
    () =>
      [
        ...(orgState.organization && hasPermission
          ? [
              {
                id: t('header.subNav.organization'),
                label: (
                  <>
                    <PrivilegeEnforcementWrapper requiredPrivileges={[ORG_MANAGER]}>
                      <SettingsMenuLink>
                        <OrganizationIcon />
                        <span className="tw-ml-2" data-testid="subNavLink:organization">
                          {t('header.subNav.organization')}
                        </span>
                      </SettingsMenuLink>
                    </PrivilegeEnforcementWrapper>
                  </>
                ),
                onClick: () => handleLinkClick(ROOT.ORGANIZATION.BASE)
              }
            ]
          : []),
        {
          id: t('header.subNav.myAccount'),
          label: (
            <SettingsMenuLink>
              <UserIcon />
              <span className="tw-ml-2">{t('header.subNav.myAccount')}</span>
            </SettingsMenuLink>
          ),
          onClick: () => handleLinkClick(ROOT.PROFILE)
        },
        {
          id: 'logout',
          label: (
            <SettingsMenuLink>
              <LogoutIcon />
              <span className="tw-ml-2">{t('header.subNav.logout')}</span>
            </SettingsMenuLink>
          ),
          onClick: handleSignOutClick
        }
      ].flat(),
    [hasPermission, handleLinkClick, handleSignOutClick, orgState.organization, t]
  );

  return (
    <nav data-testid="subnav">
      <ul className="tw-flex tw-items-center">
        <li>
          <Notifications />
        </li>
        <li>
          <ContextMenu
            options={settingsMenuLinks}
            open={isSettingsMenuOpen}
            onClose={() => setIsSettingsMenuOpen(false)}
          >
            <button
              data-testid="subNavList:settings"
              className="tw-text-nrs-blue tw-px-3 tw-py-4 tw-outline-none focus:tw-outline-none hover:tw-text-nrs-blue-dark"
              title={t('header.subNav.settings')}
              onClick={() => setIsSettingsMenuOpen(prevState => !prevState)}
            >
              <CogIcon />
            </button>
          </ContextMenu>
        </li>
      </ul>
    </nav>
  );
};
