import { gql } from '@apollo/client';

export const USER_NAME_FRAGMENT = gql`
  fragment UserNameFragment on User {
    id
    firstName
    lastName
    email
  }
`;
