import React from 'react';
import { Card } from '@components/Card';
import { CardTitle } from '@components/CardTitle';
import { ContainerLayout } from '@components/ContainerLayout';
import { useTranslation } from 'react-i18next';

export const FourOhFour: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ContainerLayout>
      <Card>
        <div className="tw-flex tw-flex-col tw-justify-items-center">
          <div className="tw-w-5/6 tw-m-auto">
            <CardTitle className="tw-text-center tw-mb-8">{t('auth.404Title')}</CardTitle>
            <p className="tw-text-center tw-mb-2">{t('auth.404Message')}</p>
            <p className="tw-text-center tw-mb-2">{t('auth.404Message2')}</p>
          </div>
        </div>
      </Card>
    </ContainerLayout>
  );
};
