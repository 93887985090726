import React, { StrictMode, Suspense } from 'react';
import { Header } from '@components/Header';
import { Loading } from '@components/Loading';
import { Snackbar } from '@components/Snackbar';
import { LoginRoute, LogoutRoute, ProtectedRoute } from '@domains/Auth';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ROOT } from '@config/routes';
import { ApolloProvider } from '@apollo/client';
import { graphQLClient } from '@utils/graphql-client';
import { CognitoSecurity } from '@domains/Auth/CognitoSecurity';
import { OrganizationStore } from '@contexts/OrganizationContext';
import { CurrentUserStore } from '@contexts/CurrentUser';
import { configureAWS } from '@config/aws-amplify';
import { OrganizationDelegationRoute } from '@domains/Organization/OrganizationDelegationRoute';
import { OrganizationInvitesRoute } from '@domains/Organization/OrganizationInvitesRoute';
import { ContainerLayout } from '@components/ContainerLayout';
import { configureSentry } from '@config/sentry';
import ResetMfaConfirmRoute from '@domains/Auth/ResetMfaConfirmRoute';

export const App: React.FC = () => {
  configureSentry();
  configureAWS();

  return (
    <StrictMode>
      <BrowserRouter>
        <CognitoSecurity>
          <ApolloProvider client={graphQLClient}>
            <OrganizationStore>
              <CurrentUserStore>
                <Snackbar>
                  <Header />
                  <Suspense
                    fallback={
                      <ContainerLayout>
                        <div className="tw-pt-20">
                          <Loading />
                        </div>
                      </ContainerLayout>
                    }
                  >
                    <div className="tw-pt-20">
                      <Routes>
                        <Route element={<LoginRoute />} path={ROOT.AUTH.LOGIN} />
                        <Route element={<LogoutRoute />} path={ROOT.AUTH.LOGOUT} />
                        <Route
                          element={<ResetMfaConfirmRoute />}
                          path={ROOT.AUTH.MFA_RESET_CONFIRM}
                        />
                        <Route
                          element={<ProtectedRoute component={OrganizationDelegationRoute} />}
                          path="/*"
                        />
                        <Route
                          element={<ProtectedRoute component={OrganizationInvitesRoute} />}
                          path={ROOT.ORGANIZATION_INVITES}
                        />
                      </Routes>
                    </div>
                  </Suspense>
                </Snackbar>
              </CurrentUserStore>
            </OrganizationStore>
          </ApolloProvider>
        </CognitoSecurity>
      </BrowserRouter>
    </StrictMode>
  );
};
