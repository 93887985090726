import React from 'react';
import { classNames } from '@helpers/classNames';
import { IntrinsicChildrenProps } from '@src/types';

interface Title4Props extends IntrinsicChildrenProps {
  className?: string;
}

export const Title4: React.FC<Title4Props> = ({ children, className }) => (
  <h4 className={classNames('tw-text-gray-800', className)}>{children}</h4>
);
