import { gql } from '@apollo/client';
import { CREATOR_FRAGMENT } from '@data/users/CreatorFragment';
import { PAGE_INFO_FRAGMENT } from '../../fragments/PageInfoFragment';
import { PEOPLE_LIST_META, PEOPLE_LIST_SHARE_RULES } from '../PeopleListFragments';

export const GET_PEOPLE_LISTS = gql`
  query GetPeopleLists(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filters: PeopleListFilterInput!
  ) {
    peopleLists(after: $after, before: $before, first: $first, last: $last, filters: $filters) {
      edges {
        cursor
        node {
          ...PeopleListMeta
          creator {
            ...Creator
          }
          ...ShareRules
          nodeUserPreferences {
            favorite
          }
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${PAGE_INFO_FRAGMENT}
  ${CREATOR_FRAGMENT}
  ${PEOPLE_LIST_META}
  ${PEOPLE_LIST_SHARE_RULES}
`;
