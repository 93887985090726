import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PrivilegeEnforcementRouteWrapper, ProtectedRoute } from '@domains/Auth';
import { Card } from '@components/Card';
import { CardTitle } from '@components/CardTitle';
import { ContainerLayout } from '@components/ContainerLayout';
import { Loading } from '@components/Loading';
import { ROOT } from '@config/routes';
import { ORGANIZATION_ACTION_TYPES, useOrganizationContext } from '@contexts/OrganizationContext';
import { useGetOrganizations } from '@data/organizations';
import { OrganizationSelectView } from './components/OrganizationSelectView';
import { OrganizationInvites } from './components/OrganizationInvites';
import { FoldersRoute } from '@domains/Folders/FoldersRoute';
import { useTranslation } from 'react-i18next';
import HomeRoute from '@domains/Home/HomeRoute';
import { AutoModelerRoute } from '@domains/AutoModeler/AutoModelerRoute';
import { SUPER_ADMIN } from '@config/privileges';
import { GetOrganizations_organizations } from '@gql';

const ListsRoutes = React.lazy(() => import('@domains/Lists/ListsRoutes'));
const DataSourcesRoutes = React.lazy(() => import('@domains/DataSources/DataSourcesRoutes'));
const ProfileRoute = React.lazy(() => import('@domains/Profile/ProfileRoute'));
const OrganizationRoutes = React.lazy(() => import('@domains/Organization/OrganizationRoutes'));

export const OrganizationDelegationRoute: React.FC = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useOrganizationContext();
  const { data, loading } = useGetOrganizations();

  const handleOrgSelect = React.useCallback(
    (orgId: string) => {
      if (data?.organizations !== null && data?.organizations.length) {
        const selectedOrg = data?.organizations.find(
          (organization: GetOrganizations_organizations) => organization.id === orgId
        );

        if (selectedOrg) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { __typename, ...selectedOrgData } = selectedOrg;

          dispatch &&
            selectedOrg &&
            dispatch({
              type: ORGANIZATION_ACTION_TYPES.SET_ORGANIZATION,
              payload: {
                organization: selectedOrgData
              }
            });
        }
      }
    },
    [data, dispatch]
  );

  React.useEffect(() => {
    if (data?.organizations?.length === 1) {
      dispatch({
        type: ORGANIZATION_ACTION_TYPES.SET_ORGANIZATION,
        payload: {
          organization: data.organizations[0]
        }
      });
    }
  }, [data, dispatch]);

  if (loading) {
    return (
      <ContainerLayout>
        <div data-testid="orgDelegate:loading">
          <Loading />
        </div>
      </ContainerLayout>
    );
  }

  if (state.invites.length) {
    return (
      <ContainerLayout>
        <Card>
          <div data-testid="orgDelegate:orgInviteList">
            <OrganizationInvites invites={state.invites} />
          </div>
        </Card>
      </ContainerLayout>
    );
  }

  if (!state.organization && state.memberOrganizations.length) {
    return (
      <ContainerLayout>
        <div data-testid="orgDelegate:orgSelectList">
          <OrganizationSelectView
            organizations={state.memberOrganizations}
            onOrgSelect={handleOrgSelect}
          />
        </div>
      </ContainerLayout>
    );
  }

  if (state.organization) {
    return (
      <Routes>
        <Route element={<ProtectedRoute component={HomeRoute} />} path="/" />
        <Route element={<ProtectedRoute component={ListsRoutes} />} path={`${ROOT.LISTS.BASE}/*`} />
        <Route
          element={<ProtectedRoute component={DataSourcesRoutes} />}
          path={`${ROOT.DATA_SOURCES.BASE}/*`}
        />
        <Route element={<ProtectedRoute component={ProfileRoute} />} path={ROOT.PROFILE} />
        <Route
          element={<ProtectedRoute component={OrganizationRoutes} />}
          path={`${ROOT.ORGANIZATION.BASE}/*`}
        />
        <Route
          element={<ProtectedRoute component={FoldersRoute} />}
          path={`${ROOT.FOLDERS.BASE}/*`}
        />
        <Route
          element={
            <PrivilegeEnforcementRouteWrapper requiredPrivileges={[SUPER_ADMIN]}>
              <AutoModelerRoute />
            </PrivilegeEnforcementRouteWrapper>
          }
          path={ROOT.AUTO_MODELER.BASE}
        />
      </Routes>
    );
  }

  return (
    <ContainerLayout>
      <Card>
        <div data-testid="orgDelegate:inviteRequired" className="tw-text-center">
          <CardTitle>{t('home.notAnOrgMemberTitle')}</CardTitle>
          <p>{t('home.notAnOrgMemberMessage')}</p>
        </div>
      </Card>
    </ContainerLayout>
  );
};
