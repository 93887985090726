import { gql } from '@apollo/client';
import { ROLE_FRAGMENT } from '@data/roles/RoleFragment';
import { ERROR_FRAGMENT } from '@data/fragments/ErrorFragment';

export const INVITE_USER_TO_ORGANIZATION = gql`
  mutation InviteUserToOrganization($input: InviteUserToOrganizationInput!) {
    inviteUserToOrganization(input: $input) {
      errors {
        ...ErrorFragment
      }
      organizationInvite {
        id
        creator {
          firstName
          lastName
        }
        email
        role {
          ...RoleFragment
        }
        state
      }
    }
  }

  ${ROLE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;
