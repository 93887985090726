import React from 'react';
import { classNames } from '@helpers/classNames';
import { IntrinsicChildrenProps } from '@src/types';

interface ListCardProps extends IntrinsicChildrenProps {
  onClick?: (args?: any) => void;
  className?: string;
}

export const ListCard: React.FC<ListCardProps> = ({ children, onClick, className = '' }) => (
  <article
    onClick={onClick}
    className={classNames(
      onClick ? 'hover:tw-bg-gray-100' : '',
      className,
      'tw-flex tw-py-4 tw-mb-2 tw-rounded-lg tw-shadow-sm tw-border-gray-100 tw-border'
    )}
  >
    {children}
  </article>
);
