import i18n, { ModuleType } from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as changeCase from 'change-case';
import resources from '@config/translations.json';

const formatterPlugin = {
  name: 'inlineFormatter',
  type: 'postProcessor' as ModuleType,
  process: function (value: string, key: string, options: Record<string, string>, translator: any) {
    if (!options.inlineFormatter) {
      return value;
    }

    const casing = options.inlineFormatter;

    switch (casing) {
      case 'sentence':
        return changeCase.sentenceCase(value);
      case 'uppercase':
        return value.toUpperCase();
      case 'lowercase':
        return value.toLowerCase();
      case 'camelcase':
        return changeCase.camelCase(value);
      default:
        return value;
    }
  }
};

i18n
  .use(initReactI18next)
  .use(formatterPlugin)
  .init({
    resources,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'production' ? false : true,
    lng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

i18n.services.formatter?.add('lowercase', value => {
  return value.toLowerCase();
});

i18n.services.formatter?.add('uppercase', value => {
  return value.toUpperCase();
});

i18n.services.formatter?.add('sentence', value => {
  return changeCase.sentenceCase(value);
});

i18n.services.formatter?.add('camelcase', value => {
  return changeCase.camelCase(value);
});

export default i18n;
