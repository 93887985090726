import React, { InputHTMLAttributes } from 'react';
import { classNames } from '@helpers/classNames';

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement>;

export const TextField: React.FC<TextFieldProps> = ({ className, disabled, ...props }) => {
  const disabledClasses = disabled
    ? 'tw-text-gray-400 tw-cursor-not-allowed tw-bg-gray-100'
    : 'tw-text-gray-600';
  return (
    <input
      disabled={disabled}
      className={classNames(
        `focus:tw-outline-nrs-indigo tw-rounded-lg tw-border-gray-200 tw-border tw-py-2 tw-px-4`,
        disabledClasses,
        className
      )}
      {...props}
    />
  );
};
