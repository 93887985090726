import * as React from 'react';
import {
  FolderPlusIcon,
  FolderMinusIcon,
  ArrowUpOnSquareIcon,
  StarIcon,
  ArchiveBoxIcon
} from '@heroicons/react/24/outline';
import { StarIcon as StarIconFilled, ArchiveBoxArrowDownIcon } from '@heroicons/react/24/solid';
import { useSnackbar } from '@components/Snackbar';
import { ContextMenu, ContextMenuOption } from '@components/ContextMenu';
import { IconButton } from '@components/IconButton';
import { AssignFolderModal } from '../../Folders/AssignFolderModal';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { REMOVE_FROM_FOLDER } from '@data/folders';
import { CloneIcon, DotsVerticalIcon, EditIcon } from '@components/icons';
import { ShareModal } from '@components/Sharing/ShareModal';
import { CREATE_FAVORITE } from '@data/lists/mutations/CreateFavorite';
import { ARCHIVE_PEOPLE_LIST, UNARCHIVE_PEOPLE_LIST } from '@data/lists/mutations';
import { DELETE_FAVORITE } from '@data/lists/mutations/DeleteFavorite';
import { useUpdateListUserPreferences } from '@data/lists/useUpdateListUserPreferences';
import {
  CreateFavorite,
  CreateFavoriteVariables,
  ArchivePeopleList,
  ArchivePeopleListVariables,
  UnarchivePeopleList,
  UnarchivePeopleListVariables,
  DeleteFavorite,
  DeleteFavoriteVariables,
  BluebellShareLevel,
  ShareRules,
  RemoveFromFolder,
  RemoveFromFolderVariables
} from '@gql';

import { ROOT } from '@config/routes';
import { useNavigate } from 'react-router-dom';
import { CloneListModal } from './CloneListModal';
import { GET_PEOPLE_LISTS } from '@data/lists';

export type ShareObject = {
  id: string;
  name: string;
  orgShareLevel: BluebellShareLevel;
  shareRules: ShareRules['shareRules'];
};

export type ListCardOptionsProps = {
  listId: string;
  listName?: string;
  shouldRedirectAfterClone?: boolean;
  folderId?: string;
  onFolderDelete?: () => void;
  shareObject?: ShareObject;
  listRefetch?: () => void;
  isFavorite: boolean;
  isArchived: boolean;
  isReady: boolean;
  displayOptions: Array<DisplayOptions>;
  refetchListsAfterUnfavorite?: boolean;
};

export type DisplayOptions = 'edit' | 'share' | 'folder' | 'favorite' | 'archive' | 'clone';

const { useEffect, useCallback, useState, useMemo } = React;

export const ListCardOptions: React.FC<ListCardOptionsProps> = ({
  listId,
  listName,
  folderId = '',
  onFolderDelete,
  shareObject,
  listRefetch,
  isFavorite,
  isArchived,
  isReady,
  displayOptions,
  shouldRedirectAfterClone,
  refetchListsAfterUnfavorite
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isFolderAssignmentModalOpen, setFolderAssignmentModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isCloneListModalOpen, setIsCloneListModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { open: openSnackbar } = useSnackbar();
  const [updateListUserPreferences] = useUpdateListUserPreferences(listId);
  const [destroyFolderAssignment, destroyFolderAssignmentResult] = useMutation<
    RemoveFromFolder,
    RemoveFromFolderVariables
  >(REMOVE_FROM_FOLDER, {
    variables: {
      input: {
        folderId: folderId,
        folderableId: listId
      }
    }
  });

  const [createFavorite, { data: favoriteTagData }] = useMutation<
    CreateFavorite,
    CreateFavoriteVariables
  >(CREATE_FAVORITE, {
    variables: {
      input: {
        favoriteableId: listId
      }
    },
    onCompleted: () => updateListUserPreferences()
  });
  const refetchAfterUnfavorite = refetchListsAfterUnfavorite ? [GET_PEOPLE_LISTS] : undefined;
  const [deleteFavorite] = useMutation<DeleteFavorite, DeleteFavoriteVariables>(DELETE_FAVORITE, {
    refetchQueries: refetchAfterUnfavorite,
    variables: {
      input: {
        favoriteableId: listId
      }
    },
    onCompleted: () => updateListUserPreferences()
  });

  const [archivePeopleList, { data: archiveListData }] = useMutation<
    ArchivePeopleList,
    ArchivePeopleListVariables
  >(ARCHIVE_PEOPLE_LIST, {
    variables: { input: { peopleListId: listId } },
    onCompleted: () => {
      if (listRefetch) {
        listRefetch();
      }
    }
  });

  const [unarchivePeopleList] = useMutation<UnarchivePeopleList, UnarchivePeopleListVariables>(
    UNARCHIVE_PEOPLE_LIST,
    {
      variables: { input: { peopleListId: listId } },
      onCompleted: () => {
        if (listRefetch) {
          listRefetch();
        }
      }
    }
  );

  const openDeleteFolderErrorSnackbar = useCallback(() => {
    openSnackbar({
      text: t('lists.pages.folders.deleteError'),
      type: 'error'
    });
  }, [openSnackbar, t]);

  const openAddListToFavoriteErrorSnackbar = useCallback(() => {
    openSnackbar({
      text: t('lists.pages.list.listCreateFavoriteError'),
      type: 'error'
    });
  }, [openSnackbar, t]);

  const openArchivePeopleListErrorSnackbar = useCallback(() => {
    openSnackbar({
      text: t('lists.pages.list.archiveListError'),
      type: 'error'
    });
  }, [openSnackbar, t]);

  const openUnarchivePeopleListErrorSnackbar = useCallback(() => {
    openSnackbar({
      text: t('lists.pages.list.unarchiveListError'),
      type: 'error'
    });
  }, [openSnackbar, t]);

  const handleDestroyFolderAssignment = useCallback(async () => {
    try {
      await destroyFolderAssignment();
      onFolderDelete?.();
      openSnackbar({
        type: 'success',
        text: t('lists.pages.list.listRemovedFromFolder')
      });
    } catch (err) {
      openDeleteFolderErrorSnackbar();
    }
  }, [destroyFolderAssignment, onFolderDelete, openDeleteFolderErrorSnackbar, openSnackbar, t]);

  const handleCreateFavorite = useCallback(async () => {
    try {
      await createFavorite();
      openSnackbar({
        type: 'success',
        text: t('lists.pages.list.listCreateFavoriteSuccess')
      });
    } catch (err) {
      openAddListToFavoriteErrorSnackbar();
    }
  }, [createFavorite, openAddListToFavoriteErrorSnackbar, openSnackbar, t]);

  const handleDeleteFavorite = useCallback(async () => {
    try {
      await deleteFavorite();
      await updateListUserPreferences();
      openSnackbar({
        type: 'success',
        text: t('lists.pages.list.listRemovedFromFavorites')
      });
    } catch (err) {
      openSnackbar({
        type: 'error',
        text: 'common.general'
      });
    }
  }, [deleteFavorite, openSnackbar, t, updateListUserPreferences]);

  const handleArchivePeopleList = useCallback(async () => {
    try {
      await archivePeopleList();
      openSnackbar({
        type: 'success',
        text: t('lists.pages.list.archiveListSuccess')
      });
    } catch (err) {
      openArchivePeopleListErrorSnackbar();
    }
  }, [archivePeopleList, openArchivePeopleListErrorSnackbar, openSnackbar, t]);

  const handleUnarchivePeopleList = useCallback(async () => {
    try {
      await unarchivePeopleList();
      openSnackbar({
        type: 'success',
        text: t('lists.pages.list.unarchiveListSuccess')
      });
    } catch (err) {
      openUnarchivePeopleListErrorSnackbar();
    }
  }, [unarchivePeopleList, openUnarchivePeopleListErrorSnackbar, openSnackbar, t]);

  const listCardActions = useMemo((): ContextMenuOption[] => {
    const shareListAction: ContextMenuOption = {
      id: 'share',
      label: (
        <div className="tw-flex tw-items-center" data-testid="listCardOptions:shareList">
          <ArrowUpOnSquareIcon className="tw-w-6 tw-h-6 tw-mr-2" />
          {t('lists.pages.list.shareList')}
        </div>
      ),
      onClick: () => {
        setIsShareModalOpen(true);
        setIsMenuOpen(false);
      }
    };

    const cloneListAction: ContextMenuOption = {
      id: 'clone',
      label: (
        <div className="tw-flex tw-items-center" data-testid="listCardOptions:cloneList">
          <CloneIcon className="tw-w-6 tw-h-6 tw-mr-2" />
          {t('lists.pages.list.cloneList')}
        </div>
      ),
      onClick: () => {
        setIsCloneListModalOpen(true);
        setIsMenuOpen(false);
      }
    };

    const editListAction: ContextMenuOption = {
      id: 'edit',
      label: (
        <div className="tw-flex tw-items-center">
          <EditIcon />
          &nbsp;{t('lists.pages.list.editList')}
        </div>
      ),
      onClick: () => navigate(`${ROOT.LISTS.BASE}/${listId}/edit`)
    };

    let folderAction: ContextMenuOption;

    if (folderId) {
      folderAction = {
        id: 'folder',
        label: (
          <div className="tw-flex tw-items-center" data-testid="listCardOptions:removeFromFolder">
            <FolderMinusIcon className="tw-h-6 tw-w-6 tw-mr-2" />
            {t('folders.pages.modal.removeFromFolder')}
          </div>
        ),
        onClick: () => {
          handleDestroyFolderAssignment();
          setIsMenuOpen(false);
        }
      };
    } else {
      folderAction = {
        id: 'folder',
        label: (
          <div className="tw-flex tw-items-center" data-testid="listCardOptions:addFolder">
            <FolderPlusIcon className="tw-h-6 tw-w-6 tw-mr-2" />
            {t('folders.pages.modal.assignToFolder')}
          </div>
        ),
        onClick: () => {
          setFolderAssignmentModalOpen(true);
          setIsMenuOpen(false);
        }
      };
    }

    let favoriteAction: ContextMenuOption;

    if (isFavorite) {
      favoriteAction = {
        id: 'favorite',
        label: (
          <div className="tw-flex tw-items-center" data-testid="listCardOptions.favoriteList">
            <StarIcon className="tw-w-6 tw-h-6 tw-mr-2" />
            {t('lists.pages.list.removeFromFavorites')}
          </div>
        ),
        onClick: () => {
          handleDeleteFavorite();
          setIsMenuOpen(false);
        }
      };
    } else {
      favoriteAction = {
        id: 'favorite',
        label: (
          <div className="tw-flex tw-items-center" data-testid="listCardOptions.favoriteList">
            <StarIconFilled className="tw-w-6 tw-h-6 tw-mr-2" />
            {t('lists.pages.list.favoriteList')}
          </div>
        ),
        onClick: () => {
          handleCreateFavorite();
          setIsMenuOpen(false);
        }
      };
    }

    let archiveAction: ContextMenuOption;

    if (isArchived) {
      archiveAction = {
        id: 'archive',
        label: (
          <div className="tw-flex tw-items-center" data-testid="listCardOptions.archiveList">
            <ArchiveBoxIcon className="tw-w-6 tw-h-6 tw-mr-2" />
            {t('lists.pages.list.removeFromArchives')}
          </div>
        ),
        onClick: () => {
          handleUnarchivePeopleList();
          setIsMenuOpen(false);
        }
      };
    } else {
      archiveAction = {
        id: 'archive',
        label: (
          <div className="tw-flex tw-items-center" data-testid="listCardOptions.archiveList">
            <ArchiveBoxArrowDownIcon className="tw-w-6 tw-h-6 tw-mr-2" />
            {t('lists.pages.list.archiveList')}
          </div>
        ),
        onClick: () => {
          handleArchivePeopleList();
          setIsMenuOpen(false);
        },
        disabled: !isReady
      };
    }

    const options = [
      editListAction,
      favoriteAction,
      cloneListAction,
      shareListAction,
      folderAction,
      archiveAction
    ];
    return options.filter(option => displayOptions?.includes(option.id as DisplayOptions));
  }, [
    displayOptions,
    folderId,
    handleCreateFavorite,
    handleDeleteFavorite,
    handleDestroyFolderAssignment,
    handleArchivePeopleList,
    handleUnarchivePeopleList,
    isFavorite,
    isArchived,
    listId,
    navigate,
    t,
    isReady
  ]);

  useEffect(() => {
    if (destroyFolderAssignmentResult?.error) {
      openDeleteFolderErrorSnackbar();
    }
  }, [destroyFolderAssignmentResult, openDeleteFolderErrorSnackbar]);

  useEffect(() => {
    if (favoriteTagData?.createFavorite?.success) {
      openSnackbar({
        type: 'success',
        text: t('lists.pages.list.listCreateFavoriteSuccess')
      });
    }
  }, [favoriteTagData, openSnackbar, t]);

  useEffect(() => {
    if (archiveListData?.archivePeopleList?.success) {
      openSnackbar({
        type: 'success',
        text: t('lists.pages.list.archiveListSuccess')
      });
    }
  }, [archiveListData, openSnackbar, t]);

  return (
    <>
      <ContextMenu open={isMenuOpen} options={listCardActions} onClose={() => setIsMenuOpen(false)}>
        <IconButton onClick={() => setIsMenuOpen(true)} data-testid="listCardOptions:menuTrigger">
          <DotsVerticalIcon />
        </IconButton>
      </ContextMenu>

      <AssignFolderModal
        listId={listId}
        isOpen={isFolderAssignmentModalOpen}
        onClose={() => setFolderAssignmentModalOpen(false)}
      />

      <ShareModal
        isOpen={isShareModalOpen}
        shareObject={shareObject}
        refetch={listRefetch}
        onClose={() => setIsShareModalOpen(false)}
      />

      <CloneListModal
        isOpen={isCloneListModalOpen}
        onClose={() => setIsCloneListModalOpen(false)}
        targetListName={listName || ''}
        peopleListId={listId}
        shouldRedirectAfterClone={shouldRedirectAfterClone}
      />
    </>
  );
};
