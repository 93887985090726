import React from 'react';
import { CircleCheckIcon, XCircleIcon } from './icons';

export type NotificationsMenuItemProps = {
  label: string;
  onAccept: (args: any) => void;
  onDecline: (args: any) => void;
};

export const NotificationsMenuItem: React.FC<NotificationsMenuItemProps> = ({
  label,
  onAccept,
  onDecline
}) => (
  <div className="tw-flex tw-justify-between tw-flex-1">
    <div>{label}</div>
    <div className="tw-flex tw-justify-between tw-w-14 tw-text-gray-300">
      <span
        className="hover:tw-text-green-500"
        onClick={onAccept}
        data-testid="notificationsMenuItem:accept"
      >
        <CircleCheckIcon />
      </span>
      <span
        className="hover:tw-text-red-600"
        onClick={onDecline}
        data-testid="notificationsMenuItem:decline"
      >
        <XCircleIcon />
      </span>
    </div>
  </div>
);
