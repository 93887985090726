import React from 'react';
import { Icon, IconProps } from './Icon';

export const GreaterThanIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="currentColor"
      viewBox="0 0 20 20"
      stroke="none"
    >
      <path d="M5.06,2.43a2.16,2.16,0,0,1,3.23-.15l6.61,6a2.17,2.17,0,0,1,.15,3.23,2.16,2.16,0,0,1-3.23.14l-6.62-6A2.15,2.15,0,0,1,5.06,2.43Z" />
      <path d="M5.1,14.35l6.61-6a2.16,2.16,0,0,1,3.23.14,2.17,2.17,0,0,1-.14,3.23l-6.62,6A2.16,2.16,0,0,1,5,17.57,2.15,2.15,0,0,1,5.1,14.35Z" />
    </svg>
  </Icon>
);
