import React from 'react';
import { Icon, IconProps } from './Icon';

export const BreadCrumbDivider: React.FC<IconProps> = props => (
  <Icon {...props}>
    <svg
      className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-600"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
    </svg>
  </Icon>
);
