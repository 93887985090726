import * as React from 'react';
import { IntrinsicChildrenProps } from '@src/types';
import { InformationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { classNames } from '@helpers/classNames';
import { Transition } from '@headlessui/react';

type InfoContainerProps = IntrinsicChildrenProps & {
  xPos?: 'left' | 'right';
  yPos?: 'top' | 'bottom';
};

const { useState } = React;

export const InfoContainer: React.FC<InfoContainerProps> = ({
  children,
  xPos = 'left',
  yPos = 'bottom'
}) => {
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

  return (
    <div
      className="tw-relative"
      data-testid="infoContainer"
      onMouseEnter={() => setShouldShowTooltip(true)}
      onMouseLeave={() => setShouldShowTooltip(false)}
    >
      <Transition
        show={shouldShowTooltip}
        enter="tw-transition-opacity tw-duration-150"
        enterFrom="tw-opacity-0"
        enterTo="tw-opacity-100"
        leave="tw-transition-opacity tw-duration-150"
        leaveFrom="tw-opacity-100"
        leaveTo="tw-opacity-0"
      >
        <div
          className={classNames(
            'tw-absolute tw-bg-white tw-rounded tw-w-48 tw-h-32 tw-shadow tw-p-2 tw-text-xs tw-text-gray-500',
            xPos === 'left' ? '-tw-left-2 tw-pl-8' : '-tw-right-2 tw-pr-8',
            yPos === 'bottom' ? '-tw-bottom-2 tw-pb-8' : '-tw-top-2 tw-pt-8'
          )}
          data-testid="infoContainer:position"
        >
          {children}
        </div>
      </Transition>
      <div className="tw-w-5 tw-h-5 tw-text-nrs-blue hover:tw-text-nrs-blue-dark tw-relative">
        {shouldShowTooltip ? <XCircleIcon /> : <InformationCircleIcon />}
      </div>
    </div>
  );
};
