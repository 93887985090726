import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import { useWindowSize } from '@src/utils/useWindowSize';
import { BluebellPrivilege } from '@gql';
import { IntrinsicChildrenProps } from '@src/types';
import { classNames } from '@helpers/classNames';

export type ContextMenuOption = {
  id: string;
  label: any;
  onClick?: (...args: any) => void;
  requiresOrganization?: boolean;
  requiresPrivileges?: BluebellPrivilege[];
  disabled?: boolean;
};

export interface ContextMenuProps extends IntrinsicChildrenProps {
  open: boolean;
  onClose: (...args: any) => void;
  options: ContextMenuOption[];
  title?: any;
  noOptionsTitle?: any;
}

const ContextMenuItem = ({ label, onClick, disabled = false }: ContextMenuOption) => {
  const disabledClasses = disabled
    ? 'tw-text-gray-400 tw-cursor-not-allowed'
    : 'hover:tw-bg-gray-700 tw-cursor-pointer';

  return (
    <div
      className={classNames('tw-p-2 tw--mx-2 tw-rounded', disabledClasses)}
      onClick={disabled ? undefined : onClick}
      data-testid="contextMenu:option"
    >
      {label}
    </div>
  );
};

type CoordinatesStyleState = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

const ESC = 'Escape';
const TOP_BUFFER = 10;

export const ContextMenu: React.FC<ContextMenuProps> = ({
  children,
  options,
  open,
  onClose,
  title,
  noOptionsTitle
}) => {
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [screenWidth] = useWindowSize();
  const [coordinates, setCoordinates] = useState<CoordinatesStyleState>({
    top: -99999,
    left: -99999
  });

  const onEscapeUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === ESC) {
        onClose();
      }
    },
    [onClose]
  );

  const detectOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        anchorRef.current &&
        !anchorRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    },
    [menuRef, onClose]
  );

  useLayoutEffect(() => {
    if (!open) {
      setCoordinates({
        top: -99999,
        left: -99999
      });
    } else if (anchorRef.current && menuRef.current) {
      const coords = anchorRef.current.getBoundingClientRect();

      const dimensions = {
        width: anchorRef.current.offsetWidth,
        height: anchorRef.current.offsetHeight
      };

      const nextCoords: CoordinatesStyleState = {
        top: dimensions.height + TOP_BUFFER
      };

      if (coords.left + menuRef.current.offsetWidth > screenWidth) {
        setCoordinates({
          ...nextCoords,
          right: 0
        });
      } else {
        setCoordinates({
          ...nextCoords,
          left: 0
        });
      }
    }
  }, [screenWidth, open]);

  useEffect(() => {
    if (open) {
      document.addEventListener('keyup', onEscapeUp);
      document.addEventListener('mouseup', detectOutsideClick);
    } else {
      document.removeEventListener('keyup', onEscapeUp);
      document.removeEventListener('mouseup', detectOutsideClick);
    }

    return () => {
      document.removeEventListener('keyup', onEscapeUp);
      document.removeEventListener('mouseup', detectOutsideClick);
    };
  }, [detectOutsideClick, onEscapeUp, open]);

  return (
    <div className="tw-relative">
      <div ref={anchorRef} data-testid="contextMenu:anchor">
        {children}
      </div>
      <div
        ref={menuRef}
        className="tw-absolute tw-z-50"
        style={coordinates}
        data-testid="contextMenu:popover"
      >
        <div className="tw-min-w-[20rem] tw-px-4 tw-flex tw-flex-col tw-py-2 tw-rounded tw-shadow-lg tw-text-white tw-bg-gray-800">
          {title || noOptionsTitle ? (
            <div className="tw-text-lg tw-font-bold tw-mb-2" data-testid="contextMenu:noOptions">
              {options.length ? title : noOptionsTitle}
            </div>
          ) : null}
          {options.map((option: ContextMenuOption) => (
            <ContextMenuItem {...option} key={option.id} />
          ))}
        </div>
      </div>
    </div>
  );
};
