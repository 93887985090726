import { Amplify } from 'aws-amplify';
import { AWS_AMP_USER_POOL_ID, AWS_AMP_USER_POOL_CLIENT_ID } from '@utils/env';

export function configureAWS() {
  if (AWS_AMP_USER_POOL_ID === undefined) {
    throw new Error('AWS_AMP_USER_POOL_ID must be set.');
  }

  if (AWS_AMP_USER_POOL_CLIENT_ID === undefined) {
    throw new Error('AWS_AMP_USER_POOL_CLIENT_ID must be set.');
  }

  Amplify.configure({
    Auth: {
      Cognito: {
        //region: AWS_AMP_REGION,
        userPoolId: AWS_AMP_USER_POOL_ID ?? '',
        userPoolClientId: AWS_AMP_USER_POOL_CLIENT_ID
      }
    }
  });
}
