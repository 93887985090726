import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CardTitle } from '@components/CardTitle';
import { Card } from '@components/Card';
import { Filter } from './Filter';
import VegaLite from 'react-vega/lib/VegaLite';
import { Config, TopLevelSpec } from 'vega-lite';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@src/../tailwind.config.js';

const { useState } = React;

const subgroups = [
  'Gender',
  'Age',
  'secondarySubgroup/Ethnicity',
  'Income',
  'Religion',
  'Party affiliation',
  'Urbanicity',
  'Is a homeowner',
  'Is a charitable donor',
  'Is a political donor'
];

const fullConfig = resolveConfig(tailwindConfig);

export const SubgroupsContainer: React.FC = () => {
  const { t } = useTranslation();
  const [geoLevel, setGeoLevel] = useState<string>('national');
  const [activeFilters, setActiveFilters] = useState<Array<string | null>>(
    new Array(subgroups.length).fill(null)
  );

  const handleOnChange = (val: Array<string | null>) => {
    setActiveFilters(val);
  };

  return (
    <Card className="tw-bg-gray-50">
      <CardTitle>
        {t('automodeler.pages.modeler.subgroupTitle', {
          metric: t('common.support')
        })}
      </CardTitle>
      <div className="tw-flex">
        <div className="tw-rounded-lg tw-shadow tw-bg-white tw-flex-1 tw-p-10">
          <VegaLite spec={specLite} actions={false} />
        </div>
        <div className="tw-my-20">
          <Filter
            onChangeGeoLevel={setGeoLevel}
            geoLevel={geoLevel}
            filterOptions={subgroups}
            filterTitle={t('automodeler.filters.pick1-2')}
            maxSelections={2}
            onChangeFilter={handleOnChange}
            selectedFilters={activeFilters}
          />
        </div>
      </div>
    </Card>
  );
};

const primarySubgroups = ['18-34', '35-49', '50-64', '65+', 'Average'];

const data = [
  {
    primarySubgroup: '18-34',
    score: 80,
    secondarySubgroup: 'Asian'
  },
  {
    primarySubgroup: '35-49',
    score: 82,
    secondarySubgroup: 'Asian'
  },
  {
    primarySubgroup: '50-64',
    score: 85,
    secondarySubgroup: 'Asian'
  },
  {
    primarySubgroup: '65+',
    score: 95,
    secondarySubgroup: 'Asian'
  },
  {
    primarySubgroup: 'Average',
    score: 84,
    secondarySubgroup: 'Asian'
  },
  {
    primarySubgroup: '18-34',
    score: 31,
    secondarySubgroup: 'White'
  },
  {
    primarySubgroup: '35-49',
    score: 48,
    secondarySubgroup: 'White'
  },
  {
    primarySubgroup: '50-64',
    score: 64,
    secondarySubgroup: 'White'
  },
  {
    primarySubgroup: '65+',
    score: 76,
    secondarySubgroup: 'White'
  },
  {
    primarySubgroup: 'Average',
    score: 53,
    secondarySubgroup: 'White'
  },
  {
    primarySubgroup: '18-34',
    score: 57,
    secondarySubgroup: 'Hispanic'
  },
  {
    primarySubgroup: '35-49',
    score: 62,
    secondarySubgroup: 'Hispanic'
  },
  {
    primarySubgroup: '50-64',
    score: 65,
    secondarySubgroup: 'Hispanic'
  },
  {
    primarySubgroup: '65+',
    score: 83,
    secondarySubgroup: 'Hispanic'
  },
  {
    primarySubgroup: 'Average',
    score: 69,
    secondarySubgroup: 'Hispanic'
  },
  {
    primarySubgroup: '18-34',
    score: 36,
    secondarySubgroup: 'African'
  },
  {
    primarySubgroup: '35-49',
    score: 41,
    secondarySubgroup: 'African'
  },
  {
    primarySubgroup: '50-64',
    score: 61,
    secondarySubgroup: 'African'
  },
  {
    primarySubgroup: '65+',
    score: 75,
    secondarySubgroup: 'African'
  },
  {
    primarySubgroup: 'Average',
    score: 51,
    secondarySubgroup: 'African'
  },
  {
    primarySubgroup: '18-34',
    score: 49,
    secondarySubgroup: 'Native'
  },
  {
    primarySubgroup: '35-49',
    score: 50,
    secondarySubgroup: 'Native'
  },
  {
    primarySubgroup: '50-64',
    score: 63,
    secondarySubgroup: 'Native'
  },
  {
    primarySubgroup: '65+',
    score: 72,
    secondarySubgroup: 'Native'
  },
  {
    primarySubgroup: 'Average',
    score: 58,
    secondarySubgroup: 'Native'
  }
];

const config: Config = {
  header: {
    titleFont: 'Noto Sans'
  },
  legend: {
    labelColor: fullConfig.theme.colors['nrs-gray'].light,
    labelFont: 'Noto Sans',
    labelFontSize: 12,
    labelOffset: 8,
    offset: 24,
    orient: 'top',
    titleColor: fullConfig.theme.colors['nrs-blue'].dark,
    titleFont: 'Noto Sans',
    titleFontSize: 12,
    titleFontWeight: 400
  },
  style: {
    label: {
      align: 'center',
      baseline: 'bottom',
      dx: 3,
      dy: 10
    }
  },
  axis: {
    labelAlign: 'center',
    labelAngle: 0,
    labelBaseline: 'middle',
    labelPadding: 10,
    labelColor: fullConfig.theme.colors.gray[500],
    labelBound: true,
    labelFont: 'Noto Sans',
    titleColor: fullConfig.theme.colors.gray[500]
  },
  axisBottom: {
    position: 0.5
  }
};

const specLite: TopLevelSpec = {
  $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
  usermeta: { embedOptions: { renderer: 'svg' } },
  description:
    "A ranged dot plot that uses 'layer' to convey changing life expectancy for the five most populous countries (between 1955 and 2000).",
  data: { name: 'Subgroups by grouping', values: data },
  width: 500,
  height: 600,
  config: config,
  encoding: {
    y: {
      field: 'score',
      type: 'quantitative',
      title: 'Score',
      scale: {
        type: 'linear',
        domain: [0, 100]
      },
      axis: {
        tickCount: 10,
        tickColor: 'transparent',
        domainColor: fullConfig.theme.colors.gray[200],
        domainWidth: 2
      }
    },
    x: {
      field: 'secondarySubgroup',
      type: 'nominal',
      title: 'Race/Ethnicity',
      axis: null
    }
  },
  layer: [
    {
      mark: 'line',
      encoding: {
        detail: {
          field: 'secondarySubgroup',
          type: 'nominal'
        },
        stroke: {
          value: fullConfig.theme.colors.gray[200],
          title: 'Race/Ethnicity'
        },
        strokeWidth: {
          value: 12
        }
      }
    },
    {
      mark: {
        type: 'text',
        dx: 0,
        dy: -10,
        xOffset: 0,
        yOffset: -10,
        align: 'center',
        font: 'Noto sans',
        fontSize: 12
      },
      encoding: {
        text: {
          field: 'secondarySubgroup',
          condition: {
            test: "datum['primarySubgroup'] !== '65+'"
          }
        }
      }
    },
    {
      mark: {
        type: 'point',
        tooltip: true,
        filled: true
      },
      encoding: {
        color: {
          field: 'primarySubgroup',
          type: 'ordinal',
          scale: {
            domain: primarySubgroups,
            range: [
              fullConfig.theme.colors['nrs-indigo'].DEFAULT,
              fullConfig.theme.colors['nrs-periwinkle'].light,
              fullConfig.theme.colors['nrs-salmon'].DEFAULT,
              fullConfig.theme.colors['nrs-green'].light,
              fullConfig.theme.colors['nrs-gray'].dark // Average
            ]
          },
          title: 'Age Group'
        },
        size: { value: 120 },
        opacity: { value: 1 }
      }
    }
  ]
};
