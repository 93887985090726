import * as React from 'react';
import { Title4 } from '@components/Title4';
import { useTranslation } from 'react-i18next';
import { Button, ButtonKind } from '@components/Button';
import { IntrinsicChildrenProps } from '@src/types';

type QuickLinksProps = {
  onClick: (route: string) => void;
};

type QuickLinksButtonProps = IntrinsicChildrenProps & {
  onClick: () => void;
};

interface IQuickLink {
  slug: string;
  label: string;
  onClick: (route: string) => void;
}

const QuickLinkButton: React.FC<QuickLinksButtonProps> = ({ onClick, children }) => (
  <Button onClick={onClick} className="tw-w-full tw-h-full" kind={ButtonKind.link}>
    {children}
  </Button>
);

const { useMemo } = React;

export const QuickLinks: React.FC<QuickLinksProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const quickLinks: IQuickLink[] = useMemo(
    () => [
      {
        slug: 'map-view',
        label: t('automodeler.pages.modeler.quickLinks-labels.mapView'),
        onClick
      },
      {
        slug: 'over-time',
        label: t('automodeler.pages.modeler.quickLinks-labels.overTime'),
        onClick
      },
      {
        slug: 'subgroups',
        label: t('automodeler.pages.modeler.quickLinks-labels.subgroups'),
        onClick
      },
      {
        slug: 'universe',
        label: t('automodeler.pages.modeler.quickLinks-labels.universe'),
        onClick
      },
      {
        slug: 'our-model',
        label: t('automodeler.pages.modeler.quickLinks-labels.ourModel'),
        onClick
      }
    ],
    [onClick, t]
  );

  return (
    <div className="tw-h-full tw-bg-gray-200 tw-rounded-lg tw-shadow tw-w-full tw-py-6 tw-px-6 tw-mb-4 tw-border tw-border-gray-300">
      <div className="tw-grid tw-grid-cols-3 tw-grid-rows-2 tw-gap-4 tw-h-full">
        <div className="tw-flex tw-items-center tw-justify-center">
          <Title4 className="tw-leading-4">
            {t('automodeler.pages.modeler.quickLinks', {
              postProcess: 'inlineFormatter',
              inlineFormatter: 'sentence'
            })}
          </Title4>
        </div>
        {quickLinks.map((link: IQuickLink) => (
          <div key={link.slug}>
            <QuickLinkButton onClick={() => link.onClick(link.slug)}>{link.label}</QuickLinkButton>
          </div>
        ))}
      </div>
    </div>
  );
};
