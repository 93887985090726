export const ROOT = {
  HOME: '/',
  AUTH: {
    LOGIN: '/login',
    LOGOUT: '/logout',
    MFA_RESET_CONFIRM: '/mfa-reset/:requestId'
  },
  AUTO_MODELER: {
    BASE: '/auto-modeler'
  },
  LISTS: {
    BASE: '/lists',
    NEW: '/new',
    DETAIL: ':peopleListId',
    EDIT: ':peopleListId/edit',
    PURCHASE: ':peopleListId/purchase',
    BUILD: '/build',
    DATA_SOURCE_SELECTION: '/data-sources',
    ARCHIVED: '/archived',
    CROSSTABS: {
      NEW: ':peopleListId/crosstabs/new'
    }
  },
  DATA_SOURCES: {
    BASE: '/data-sources',
    DETAIL: ':dataSourceId'
  },
  HELP: '/help',
  PROFILE: '/profile',
  ORGANIZATION: {
    BASE: '/organization',
    USERS: 'users',
    USER_ROLES: 'roles',
    SETTINGS: 'settings'
  },
  ORGANIZATION_INVITES: '/organization-invites/:inviteId/:inviteAction?',
  FOLDERS: {
    BASE: '/folders'
  }
};
